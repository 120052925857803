import {serviceAPI} from "./service"
import {
    BANK_ACC_TRANSACTION_DTLS_NOT_RECONCILED_BEFORE_URL,
    LIST_RECONCILED_TRANSACTION_DETAILS,
    LIST_RECONCILIATIONS,
    RECONCILIATIONS_START_DATE,
    SAVE_RECONCILIATION,
    TOTAL_RECONCILIATIONS
} from "../constants/url.constants"
import {isNullUndefined, isValidDate} from "../util/util"
import {handleErrorResponse} from "../actions/message"


export const loadReconciliationsList = (name, reconciledBy, pageNo) => {
    let url = LIST_RECONCILIATIONS + name + "&reconciledBy=" + reconciledBy + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalReconciliations = (name, reconciledBy) => {
    let url = TOTAL_RECONCILIATIONS + name + "&reconciledBy=" + reconciledBy
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getReconciliationStartDate = (accountId) => {
    let url = RECONCILIATIONS_START_DATE + accountId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getBankAccountTransactionDetailsNotReconciledBefore = (accountId, startDate, statementDate) => {
    let startDateString = ''
    let statementDateString = ''
    if (!isNullUndefined(startDate) && isValidDate(startDate)) {
        let date = new Date(startDate)
        startDateString = date.toISOString()
    }
    if (!isNullUndefined(statementDate) && isValidDate(statementDate)) {
        let date = new Date(statementDate)
        statementDateString = date.toISOString()
    }

    let url = BANK_ACC_TRANSACTION_DTLS_NOT_RECONCILED_BEFORE_URL + accountId + '&startDate=' + startDateString + '&statementDate=' + statementDateString
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveReconsiliationUrl = (data) => {
    return serviceAPI
        .post(SAVE_RECONCILIATION, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listReconciledTransactionDetails = (reconciliationId, pageNo) => {
    let url = LIST_RECONCILED_TRANSACTION_DETAILS + reconciliationId + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

