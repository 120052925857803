import {
  Alert,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ModalAcceptButton,
  TopBarContainedBrandButton,
} from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";

/**
 *
 * REQUIREMENTS PROPS
 *
 * handleLeaseChargeItemAdd
 * handleLeaseChargeClose
 *
 * chargeItemsTotal
 * innerTask
 * leaseChargesTableRows
 * saveEditUnit
 * selectedLease
 * saveLeaseCharges
 * selectedUnitId
 * showSelectUnit
 * swapUnitsTableRows
 * unitOptions
 *
 *
 *
 */
export default function LeaseUnitSwapModal(props) {
  return (
    <ModalContainerModule
      size="md"
      accept={
        props.innerTask === "Lease Units"
          ? props.saveEditUnit
          : props.innerTask === "Lease Charges"
          ? props.saveLeaseCharges
          : () => {}
      }
      cancel={
        props.innerTask === "Lease Charges"
          ? props.handleLeaseChargeClose
          : props.innerTask === "Lease Units"
          ? props.handleUnitSwapClose
          : () => {}
      }
      openModal={props.task === "Unit Swap"}
      modalTitle={`Add/Swap/Delete Lease Units On Lease ${props.selectedLease && props.selectedLease.billingCode} for ${props.selectedLease && props.selectedLease.tenant && props.selectedLease.tenant.name}`}
      acceptButtonText={
        props.innerTask === "Lease Units"
          ? "Save Unit Changes"
          : props.innerTask === "Lease Charges"
          ? "Save Lease Charges"
          : ""
      }
    >
      <ResponsiveRow sx={{ padding: "0 10px", width: "100%", height: "auto" }}>
        <br />
        <Alert severity="info">
          <strong>
            Note: This dialog let's you add, swap or delete units on an ACTIVE
            lease.
          </strong>{" "}
          <br /> <br />
          You swap a unit by deleting one unit and adding another. Please note
          that for units that have a utility meter attached you can add an
          initial reading when adding the unit or add a last reading when
          deleting the unit. To add a unit click on the add button and select
          from the list of available units in the dropdown. Then add the unit's
          lease charges e.g service charge and click on 'Save Lease Charges'. To
          delete a unit click on the red button at the end of the row for the
          particular unit. Click on 'Save Unit Changes' when you are done to
          save the changes made on the lease units.
        </Alert>

        <br />
        {props && props.innerTask === "Lease Units" && (
          <>
            <ResponsiveRow sx={{ width: "100%", padding: 0 }}>
              <Typography
                sx={{ marginBottom: "5px", marginTop: "20px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Lease Units
              </Typography>
              <ResponsiveRow
                sx={{
                  width: { xs: "100%", lg: "90%" },
                  padding: 0,
                  justifyContent: { xs: "center", lg: "flex-end" },
                  flexWrap: "nowrap",
                }}
                item
              >
                <ResponsiveRow
                  sx={{
                    width: { xs: "100%", lg: "90%" },
                    padding: 0,
                  }}
                  item
                >
                  <Grid item sx={{ width: "100%" }}>
                    {props.showSelectUnit && (
                      <Select
                        sx={{ width: "15%", float: "right" }}
                        id={"unit"}
                        value={props.selectedUnitId}
                        onChange={(event) => props.handleSwapUnitChange(event)}
                      >
                        {props.unitOptions && props.unitOptions.length > 0 ? (
                          props.unitOptions.map(function (unit, i) {
                            return (
                              <MenuItem key={i} value={unit.value}>
                                {unit.label}
                              </MenuItem>
                            );
                          }, this)
                        ) : (
                          <MenuItem sx={{ width: "100%" }}>
                            No Results Found
                          </MenuItem>
                        )}
                      </Select>
                    )}
                  </Grid>
                </ResponsiveRow>

                <TopBarContainedBrandButton
                  onClick={() => props.setShowSelectUnit(true)}
                  sx={{ width: { xs: "100%", lg: "150px" } }}
                >
                  Add Unit{" "}
                </TopBarContainedBrandButton>
              </ResponsiveRow>
            </ResponsiveRow>
            <TableContainer>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                    <TableCell sx={{ width: "15%" }}>Tenant</TableCell>
                    <TableCell sx={{ width: "20%" }}>Periodic Charge</TableCell>
                    <TableCell sx={{ width: "20%" }}>Meter Name</TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                      }}
                    >
                      Last Meter Reading
                    </TableCell>

                    <TableCell sx={{ width: "10%" }}>Delete Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.swapUnitsTableRows}</TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {props.innerTask === "Lease Charges" && (
          <Paper
            variant=""
            sx={{ height: "auto", margin: "auto", padding: "10px" }}
          >
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              <ResponsiveRow container>
                <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
                  Lease Charges
                </Grid>
              </ResponsiveRow>
              <ResponsiveRow
                item
                sx={{
                  justifyContent: { xs: "center", lg: "flex-end" },
                }}
              >
                <ModalAcceptButton
                  // type="submit"
                  onClick={() => props.handleLeaseChargeItemAdd()}
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                >
                  Add Row
                </ModalAcceptButton>
              </ResponsiveRow>
            </Typography>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                    <TableCell sx={{ width: "15%" }}>Charge Type</TableCell>
                    <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                    <TableCell sx={{ width: "20%" }}>Start Date</TableCell>
                    <TableCell sx={{ width: "10%" }}>Taxed?|Note</TableCell>
                    <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.leaseChargesTableRows}</TableBody>
                <ResponsiveRow container>
                  Total: {props.chargeItemsTotal}
                </ResponsiveRow>
              </Table>
            </TableContainer>
          </Paper>
        )}
        <br />
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
