/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Checkbox,
    Alert,
    AlertTitle,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { getSentMessages, sendMessage } from "../../services/messages.service";
import { useSelector } from "react-redux";
import { formatCurrency, isEmptyArray, isEmptyString, isNullUndefined, titleCase } from "../../util/util";
import { ModalAcceptButton } from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import { issueResponseMessage } from "../../actions/message";

export default function MessageCompose(props) {
    const [selectedOwners, setSelectedOwners] = useState([]); // State to store selected owners
    const [selectedTenants, setSelectedTenants] = useState([]); // State to store selected tenants
    const [subject, setSubject] = useState(""); // State to store the subject of the message
    const [message, setMessageBody] = useState(""); // State to store the body of the message
    const [sendSms, setSendSms] = useState(false); // State to toggle SMS sending
    const [formCompleted, setFormCompleted] = useState(false); // State to check if the form is completed
    const [pageNo] = useState(0); // State to store the current page number (default to 0)
    const appMessage = useSelector((state) => state.message); // Access the message state from Redux store
    const [sendToTenants, setSendToTenants] = useState(true); // State to toggle sending message to tenants
    const [sendToOwners, setSendToOwners] = useState(false); // State to toggle sending message to owners
    const [selectAllTenants, setSelectAllTenants] = useState(false); // State to select all tenants
    const [selectAllOwners, setSelectAllOwners] = useState(false); // State to select all owners

    useEffect(() => {
        if (props.newMessage) {
            setSubject(props.message.subject); // Set subject if it's a new message
        }

        if (props.isHoaFacility) {
            setSendSms(true); // Enable SMS sending for HOA facilities
            setSendToTenants(true); // Enable sending message to tenants for HOA facilities
        }

        return () => {
            setSubject(""); // Clean up subject when component unmounts
        };
    }, [props]);

    useEffect(() => {
        const isFormCompleted = () => {
            if (props.newMessage) {
                return (!isEmptyArray(selectedTenants) || !isEmptyArray(selectedOwners)) && !isEmptyString(subject) && !isEmptyString(message);
                // Check if the form is completed for new messages
            }
            return message !== ""; // Check if the message body is not empty for existing messages
        };

        setFormCompleted(isFormCompleted()); // Set form completed status

        return () => {
            setFormCompleted(false); // Clean up form completed status when component unmounts
        };
    }, [selectedTenants, selectedOwners, subject, message, props.newMessage]);

    useEffect(() => {
        if (props.owners && props.owners.length !== selectedOwners.length) {
            setSelectAllOwners(false); // Uncheck "Select All Owners" if owners list changes
        } else {
            setSelectAllOwners(true); // Check "Select All Owners" if all owners are selected
        }
        if (props.recipients && props.recipients.length !== selectedTenants.length) {
            setSelectAllTenants(false); // Uncheck "Select All Tenants" if tenants list changes
        } else {
            setSelectAllTenants(true); // Check "Select All Tenants" if all tenants are selected
        }
    }, [props.owners, props.recipients, selectedOwners.length, selectedTenants.length]);

    const handleRecipientsChange = (event) => {
        setSelectedTenants(event.target.value); // Handle change in tenant selection
    };

    const handleSelectedOwnerChange = (event) => {
        setSelectedOwners(event.target.value); // Handle change in owner selection
    };

    const handleSelectAllOwnersChange = (event) => {
        setSelectAllOwners(event.target.checked); // Handle "Select All Owners" checkbox change
        setSelectedOwners(event.target.checked ? props.owners.map((item) => item.value) : []); // Select or deselect all owners
    };

    const handleSelectAllTenantsChange = (event) => {
        setSelectAllTenants(event.target.checked); // Handle "Select All Tenants" checkbox change
        setSelectedTenants(event.target.checked ? props.recipients.map((item) => item.value) : []); // Select or deselect all tenants
    };

    const handleForm = () => {
        const to = [...selectedOwners, ...selectedTenants]; // Combine selected owners and tenants into one array

        const data = {
            to, // Recipients
            sendSms, // SMS sending status
            subject, // Message subject
            message, // Message body
        };

        sendMessage(data).then((response) => {
            if (response?.type === 'success') {
                issueResponseMessage(response); // Issue response message on success

                setSubject(""); // Reset subject
                setMessageBody(""); // Reset message body
                setSendSms(false); // Reset SMS sending status
                setSelectedTenants([]); // Reset selected tenants
                setSelectedOwners([]); // Reset selected owners

                getSentMessages(pageNo).then((outbox) => {
                    props.setOutBox(outbox.messages); // Update outbox messages
                });

                props.unsetView(); // Unset the current view
            } else {
                issueResponseMessage(response); //Issue response message on failure
            }
        });
    };

    const handleSendMessageToOwnersChanged = (event) => {
        const checked = event.target.checked;
        setSendToOwners(checked); // Toggle sending message to owners
        if (!checked) {
            setSelectedOwners([]); // Clear selected owners if unchecked
        }
    };

    const handleSendMessageToTenantsChanged = (event) => {
        const checked = event.target.checked;
        setSendToTenants(checked); // Toggle sending message to tenants
        setSendSms(checked); // Toggle SMS sending status
        if (!checked) {
            setSelectedTenants([]); // Clear selected tenants if unchecked
            setSendSms(false); // Disable SMS sending if unchecked
        }
    };

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                margin: "auto",
                padding: "20px 30px",
                width: "100%",
                maxHeight: "70vh",
                overflowY: "hidden",
                overflowX: "hidden",
                backgroundColor: "#fff",
                borderRadius: "10px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "auto",
                }}
            >
                <Typography
                    textAlign="start"
                    sx={{
                        color: "#037171",
                        fontSize: "1.25rem",
                        fontWeight: 800,
                        marginBottom: "10px",
                        width: "100%",
                        height: "auto",
                    }}
                >
                    {props.newMessage ? "Compose new message" : `Reply to ${props.message.subject}`}
                    {/* Display appropriate title based on new or reply message */}
                </Typography>

                <IconButton
                    onClick={props.unsetView}
                    sx={{
                        color: "#037171",
                        fontSize: "5rem",
                        textAlign: "center",
                        fontWeight: 800,
                    }}
                >
                    <DeleteForever />
                </IconButton>
                {/* Button to unset the view, possibly close the message compose modal */}
            </ResponsiveRow>
            {appMessage.display && (
                <Alert severity={appMessage.type}>
                    <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                    {appMessage.message}
                    {/* Display alert message if there is one */}
                </Alert>
            )}
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
                component="form"
            >
                {props.isHoaFacility && props.newMessage && (
                    <FormGroup row={true}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sendToTenants}
                                    onChange={handleSendMessageToTenantsChanged}
                                />
                            }
                            label="Send Message to tenants"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sendToOwners}
                                    onChange={handleSendMessageToOwnersChanged}
                                />
                            }
                            label="Send Message to Owners"
                        />
                    </FormGroup>
                )}

                {sendToTenants && (
                    <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                        <InputLabel htmlFor="message_recipient">
                            {props.isHoaFacility ? "To Tenants" : "Message Recipient"}{selectedTenants.length > 0 ? ` (${selectedTenants.length} selected)` : null}
                            {/* Input label for message recipient */}
                        </InputLabel>
                        {props.newMessage ? (
                            <>
                                <Select
                                    id="message_recipient"
                                    fullWidth
                                    multiple
                                    size="small"
                                    sx={{ height: "100%", padding: "5px 10px" }}
                                    aria-describedby="my-helper-text"
                                    label={`${props.isHoaFacility ? "To Tenants" : "Message Recipient"} ${selectedTenants.length > 0 ? ` (${selectedTenants.length} selected)` : null}`}
                                    value={selectedTenants}
                                    onChange={handleRecipientsChange}
                                    defaultValue={[]}
                                >
                                    {props.recipients?.length > 0 ? (
                                        props.recipients.map((recipient) => (
                                            <MenuItem key={recipient.value} value={recipient.value}>
                                                {recipient.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                                    )}
                                </Select>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectAllTenants}
                                            onChange={handleSelectAllTenantsChange}
                                        />
                                    }
                                    label="Select All"
                                />
                            </>
                        ) : (
                            <OutlinedInput
                                id="message_recipient"
                                fullWidth
                                size="small"
                                sx={{
                                    backgroundColor: "#efefef",
                                    height: "100%",
                                    padding: "5px 10px",
                                }}
                                aria-describedby="my-helper-text"
                                label="Message Recipient"
                                value={props.message.senderName}
                                disabled
                            />
                        )}
                    </FormControl>
                )}

                {props.isHoaFacility && sendToOwners && (
                    <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                        <InputLabel htmlFor="message_recipient">
                            To Owners{selectedOwners.length > 0 ? ` (${selectedOwners.length} selected)` : null}
                        </InputLabel>
                        {props.newMessage ? (
                            <>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="message_recipient"
                                    fullWidth
                                    multiple
                                    size="small"
                                    sx={{ height: "100%", padding: "5px 10px" }}
                                    aria-describedby="my-helper-text"
                                    label={`To Owners ${selectedOwners.length > 0 ? ` (${selectedOwners.length} selected)` : null}`}
                                    value={selectedOwners}
                                    onChange={handleSelectedOwnerChange}
                                    defaultValue={[]}
                                >
                                    {props.owners?.length > 0 ? (
                                        props.owners.map((recipient) => (
                                            <MenuItem key={recipient.value} value={recipient.value}>
                                                {recipient.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                                    )}
                                </Select>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectAllOwners}
                                            onChange={handleSelectAllOwnersChange}
                                        />
                                    }
                                    label="Select All Owners"
                                />
                            </>
                        ) : (
                            <OutlinedInput
                                id="message_recipient"
                                fullWidth
                                size="small"
                                sx={{
                                    backgroundColor: "#efefef",
                                    height: "100%",
                                    padding: "5px 10px",
                                }}
                                aria-describedby="my-helper-text"
                                label="Message Recipient"
                                value={props.message.senderName}
                                disabled
                            />
                        )}
                    </FormControl>
                )}
                <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                    <InputLabel htmlFor="message_subject">Message Subject</InputLabel>
                    <OutlinedInput
                        id="message_subject"
                        fullWidth
                        size="small"
                        sx={{
                            height: "100%",
                            padding: "5px 10px",
                        }}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        aria-describedby="my-helper-text"
                        label="Message Subject"
                        disabled={!props.newMessage}
                    />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                    <InputLabel htmlFor="message">Message Body</InputLabel>
                    <OutlinedInput
                        id="message"
                        fullWidth
                        size="small"
                        sx={{ height: "100%", padding: "5px 10px" }}
                        value={message}
                        onChange={(e) => setMessageBody(e.target.value)}
                        aria-describedby="my-helper-text"
                        multiline
                        minRows="8"
                        label="Message Body"
                    />
                </FormControl>

                {props.newMessage && (
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            margin: "5px auto",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        {sendSms && (
                            <Alert severity="info" sx={{ width: "100%" }}>
                                Sending SMSs to recipients will incur additional cost. Estimated additional cost: KSH{" "}
                                {formatCurrency((selectedOwners.length + selectedTenants.length) * 2.0)}
                            </Alert>
                        )}
                        <FormControl
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <Checkbox
                                value={"Send SMS to recipients?"}
                                checked={sendSms}
                                onChange={(event) => setSendSms(event.target.checked)}
                            />
                            Send SMS to recipients?
                        </FormControl>
                    </ResponsiveRow>
                )}
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "flex-end",
                }}
            >
                <ModalAcceptButton
                    sx={{
                        width: { xs: "100%", lg: "200px" },
                        height: "60px",
                    }}
                    disabled={!formCompleted}
                    onClick={handleForm}
                >
                    Send Message
                </ModalAcceptButton>
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
