import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { isEmptyString } from "../../../../util/util";
import dayjs from "dayjs";

export default function CreateReconciliationModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleBankAccountChanged
   * handleCloseReconciliationClicked
   * handleProceedClicked
   *
   * setBegBalance
   * setEndBalance
   * setSelectedReconciliation
   * setStartDate
   * setStatementDate
   *
   * addChargeOrInterestItem
   * begBalance
   * chargeInterestItems
   * displayBankTransaction
   * endBalance
   * isNewReconciliation
   * orgBankAccountsList
   * reconciliationForm
   * selectedBankAcc
   * selectedReconciliation
   * showItemApplyToOptions
   * startDate
   * statementDate
   *
   *
   */

  return (
    <ModalContainerModule
      size="sm"
      accept={props.handleProceedClicked}
      cancel={props.handleCloseReconciliationClicked}
      openModal={props.reconciliationForm}
      modalTitle=" Reconciliation"
      acceptButtonText="Proceed/Continue"
    >
      <ResponsiveRow container sx={{ height: "auto" }}>
        <Alert
          id="reconciliationForm"
          severity="info"
          icon={false}
          sx={{ width: "100%", marginBottom: "15px" }}
        >
          Select an account to reconcile and then enter the ending balance from
          your account statement.
        </Alert>
        <br />

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Select Account</InputLabel>
          <Select
            label="Select Account"
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"bankAccount"}
            value={props.selectedBankAcc}
            onChange={(event) => {
              props.handleBankAccountChanged(event);
            }}
          >
            {props.orgBankAccountsList &&
            props.orgBankAccountsList.length > 0 ? (
              props.orgBankAccountsList.map(function (idType, i) {
                return (
                  <MenuItem key={i} value={idType.value}>
                    {idType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Start Date"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"startDate"}
              value={
                props.selectedReconciliation
                  ? dayjs(props.selectedReconciliation.startDate)
                  : dayjs(props.startDate)
              }
              disabled={!props.isNewReconciliation}
              onChange={(newDate) => {
                let date = new Date(newDate);
                props.setStartDate(date);
                let tmp = props.selectedReconciliation;
                tmp.startDate = date;
                props.setSelectedReconciliation(tmp);
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Statement Date"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"statementDate"}
              value={
                props.selectedReconciliation
                  ? dayjs(props.selectedReconciliation.statementDate)
                  : dayjs(props.statementDate)
              }
              onChange={(newDate) => {
                let date = new Date(newDate);
                props.setStatementDate(date);
                let tmp = props.selectedReconciliation;
                tmp.statementDate = date;
                props.setSelectedReconciliation(tmp);
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Beginning Balance</InputLabel>
          <OutlinedInput
            label="Beggining Balance"
            placeholder={"Beginning Balance (KSH)"}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"begBalance"}
            onChange={(event) => {
              let value = event.target.value;
              props.setBegBalance(value);
              let obj = props.selectedReconciliation;
              obj.begBalance = value;
              props.setSelectedReconciliation(obj);
            }}
            value={
              props.selectedReconciliation
                ? props.selectedReconciliation.begBalance
                : props.begBalance
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Ending Balance</InputLabel>
          <OutlinedInput
            label="Ending Balance"
            placeholder={"Ending Balance (KSH)"}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"endBalance"}
            onChange={(event) => {
              let value = event.target.value;
              props.setEndBalance(value);
              let obj = props.selectedReconciliation;
              obj.endBalance = value;
              props.setSelectedReconciliation(obj);
            }}
            value={
              props.selectedReconciliation
                ? props.selectedReconciliation.endBalance
                : props.endBalance
            }
          />
        </FormControl>

        <Typography
          sx={{ width: "100%", margin: "20px auto" }}
          id="reconciliation"
          variant="h5"
          component="h6"
        >
          Add any bank service charge or interest earned
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20%" }}>Type</TableCell>
                {props.showItemApplyToOptions &&
                  !isEmptyString(props.selectedBankAcc) && (
                    <TableCell sx={{ width: "20%" }}>Expense Account</TableCell>
                  )}
                <TableCell sx={{ width: "20%" }}>Date</TableCell>
                <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                <TableCell sx={{ width: "5%" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.chargeInterestItems}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
          sx={{
            width: "100%",
            justifyContent: { xs: "center", lg: "flex-end" },
            marginTop: "20px",
          }}
        >
          {!props.displayBankTransaction && (
            <ModalAcceptButton
              variant="contained"
              type="submit"
              onClick={() => props.addChargeOrInterestItem()}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Add Row
            </ModalAcceptButton>
          )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
