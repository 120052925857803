/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { getOwnershipFinancials } from "../services/ownership.service"
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../util/util"
import { getLeaseFinancials } from "../services/lease.service"
import { getMeterBillingFinancials } from "../services/meterbilling.service"
import { getClientFinancials } from "../services/client.service"
import { editReadings, getMeterFinancials } from "../services/meters.service"
import Delete from "@mui/icons-material/Delete"
import {
  printCharge,
  printClientCharge,
  printClientCredit,
  printClientInvoice,
  printClientReceipt,
  printCredit,
  printInvoice, printPaymentVoucher,
  printReceipt,
} from "../services/print.service"
import { Edit } from "@mui/icons-material"
import ModalContainerModule from "./modules/modalContainer"
import { DetailsContainer } from "../constants/component.constants"
import { issueResponseMessage, issueWarnMessage } from "../actions/message"
import { ResponsiveRow } from "../constants/layout.constants"
import { useSelector } from "react-redux"
import {getCustomerFinancials} from "../services/customers.service";

/**
 * This is a shared TransactionHistory component to render history for invoices, charges, receipts and credits to:
 * - Meter Billing view Page
 * - Lease view Page
 * - Ownership view Page
 * - Customer view Page
 * - Client view Page
 * - This also apply to their respective terminated view pages.
 *
 * Required props are:
 * - transactionMode: This is actual page you are implementing this from. Supported modes are Lease, Ownership, Meter Billing, Customer and Client
 * - contract: This is the data for either selected item of 'Lease, Ownership, Meter Billing, Customer or Client'
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 *  @description If status is voided the row will have different colour.
 */
export default function TransactionHistory(props) {
  const [pageNo, setPageNo] = useState(0)
  const [financials, setFinancials] = useState([])
  const [currentBalance, setCurrentBalance] = useState(0)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [isBulkWaterMeter, setIsBulkWaterMeter] = useState(false)
  const [editMeterReadingModal, setEditMeterReadingModal] = useState(false)
  const [currentReading, setCurrentReading] = useState("")
  const [readingId, setReadingId] = useState("")
  const [newReading, setNewReading] = useState("")
  const user = useSelector((state) => state.auth.user)

  useEffect(() => {
    if (
      !isNullUndefined(props.contract) &&
      !isNullUndefined(props.contract.id)
    ) {
      if (props.transactionMode === "Ownership") {
        getOwnershipFinancials(props.contract.id, pageNo).then((response) => {
          if (response.items.length > 0) {
            setShowLoadMore(true)
            let result = financials.concat(response.items).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.referenceNumber === value.referenceNumber
                    ))
            )
            setFinancials(result)
          } else {
            setShowLoadMore(false)
          }
          setCurrentBalance(response.titleBalance)
        })
      }

      if (props.transactionMode === "Lease") {
        getLeaseFinancials(props.contract.id, pageNo).then((response) => {
          if (response.items.length > 0) {
            setShowLoadMore(true)
            let result = financials.concat(response.items).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.referenceNumber === value.referenceNumber
                    ))
            )
            setFinancials(result)
          } else {
            setShowLoadMore(false)
          }
          setCurrentBalance(response.leaseBalance)
        })
      }

      if (props.transactionMode === "Meter Billing") {
        getMeterBillingFinancials(props.contract.id, pageNo, false).then(
          (response) => {
            if (response.items.length > 0) {
              setShowLoadMore(true)
              let result = financials.concat(response.items).filter((value, index, self) =>
                      index === self.findIndex((t) => (
                          t.id === value.id && t.referenceNumber === value.referenceNumber
                      ))
              )
              setFinancials(result)
            } else {
              setShowLoadMore(false)
            }
            setCurrentBalance(response.meterBillingBalance)
          }
        )
      }

      if (props.transactionMode === "Customer") {
        getMeterBillingFinancials(props.contract.billingId, pageNo, true).then(
          (response) => {
            if (response.items.length > 0) {
              setShowLoadMore(true)
              let result = financials.concat(response.items).filter((value, index, self) =>
                      index === self.findIndex((t) => (
                          t.id === value.id && t.referenceNumber === value.referenceNumber
                      ))
              )
              setFinancials(result)
            } else {
              setShowLoadMore(false)
            }
            setCurrentBalance(props.contract.billingBalance)
          }
        )
      }

      if (props.transactionMode === "Client Customer") {
        getCustomerFinancials(props.contract.id, pageNo).then(
            (response) => {
              if (response.items.length > 0) {
                setShowLoadMore(true)
                let result = financials.concat(response.items).filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id && t.referenceNumber === value.referenceNumber
                        ))
                )
                setFinancials(result)
              } else {
                setShowLoadMore(false)
              }
              setCurrentBalance(response.customerBalance)
            }
        )
      }

      if (props.transactionMode === "Client") {
        getClientFinancials(props.contract.id, pageNo).then((response) => {
          if (response.items.length > 0) {
            setShowLoadMore(true)
            let result = financials.concat(response.items).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.referenceNumber === value.referenceNumber
                    ))
            )
            setFinancials(result)
          } else {
            setShowLoadMore(false)
          }
          setCurrentBalance(response.clientBalance)
        })
      }

      if (props.transactionMode === "Meters") {
        getMeterFinancials(props.contract.id, pageNo).then((response) => {
          if (response.items.length > 0) {
            setShowLoadMore(true)
            setShowLoadMore(true)
            let result = financials.concat(response.items).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.referenceNumber === value.referenceNumber
                    ))
            )
            setFinancials(result)
            getMeterType(result)
          } else {
            setShowLoadMore(false)
          }
          setCurrentBalance(response.clientBalance)
        })
      }
    }
  }, [pageNo, props])

  function loadMore() {
    let page = pageNo
    page += 1
    setPageNo(page)
  }

  function handlePrintDocument(type, id) {
    if (type === "Invoice") {
      if (props.transactionMode === "Client") {
        //print client invoice
        printClientInvoice(id)
      } else {
        //print invoice
        printInvoice(id)
      }
    }
    if (type === "Receipt") {
      if (props.transactionMode === "Client") {
        //print client Receipt
        printClientReceipt(id)
      } else {
        //print Receipt
        printReceipt(id)
      }
    }
    if (type === "Credit") {
      if (props.transactionMode === "Client") {
        //print client Credit
        printClientCredit(id)
      } else {
        //print Credit
        printCredit(id)
      }
    }
    if (type === "Charge") {
      if (props.transactionMode === "Client") {
        //print client Charge
        printClientCharge(id)
      } else {
        //print Charge
        printCharge(id)
      }
    }
    if (type === "Payment Voucher") {
        printPaymentVoucher(id)
    }
  }

  const getMeterType = (financials) => {
    // financials &&
    //   financials.length > 0 &&
      financials.forEach((financial, i) => {
        financial && financial.meterType === "BLK"
          ? setIsBulkWaterMeter(true)
          : setIsBulkWaterMeter(false)
      })
  }

  const showEditReadingModal = (value) => {
    setEditMeterReadingModal(true)
    setCurrentReading(value.closingReading)
    setReadingId(value.closingReadingId)
  }

  const closeEditReadingModal = () => {
    setEditMeterReadingModal(false)
    setCurrentReading("")
    setNewReading("")
    setReadingId("")
  }

  const submitReading = () => {
    if (isEmptyString(newReading) || isNullUndefined(newReading)) {
      issueWarnMessage("Please enter a valid value!")
      return false
    }

    if (tryParseFloat(newReading) === tryParseFloat(currentReading)) {
      issueWarnMessage("No change in amount!")
      return false
    }

    let sendData = {
      amount: newReading,
      readingId: readingId,
    }

    editReadings(sendData).then((response) => {
      issueResponseMessage(response)
      closeEditReadingModal()
      getMeterFinancials(props.contract.id, pageNo) //Reload meter transaction items
        .then((response) => {
          if (response.items.length > 0) {
            setShowLoadMore(true)
            let result = response.items.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.referenceNumber === value.referenceNumber
                    ))
            )
            setFinancials(result)
            getMeterType(response.items)
          } else {
            setShowLoadMore(false)
          }
          setCurrentBalance(response.clientBalance)
        })
    })
  }

  return (
    <>
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          height: props.height ? props.height : "100%",
          width: { xs: "100%", lg: props.width ? props.width : "100%" },
          margin: "auto",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            textAlign: "start",
          }}
        >
          Transaction History{" "}
        </Typography>
        <ResponsiveRow
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height:"auto"
          }}
          id="modal-modal-title"
          // variant="h6"
          // component="h6"
        >
          <Typography variant="h6" color="primary.dark" textAlign="start">
            {props.transactionMode} Balance: KSH{" "}
            {formatCurrency(currentBalance)}
          </Typography>
          <Typography
            variant="h6"
            color="primary.dark"
            textAlign="start"
            sx={{ float: "right", marginRight: "25px" }}
          >
            {financials.length} Transactions
          </Typography>
        </ResponsiveRow>
        <TableContainer style={{ maxHeight: 350 }}>
          {props.transactionMode !== "Meters" && (
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "12%" }}>Date</TableCell>
                  <TableCell sx={{ width: "10%" }}>Type</TableCell>
                  <TableCell sx={{ width: "12%" }}>Ref Number</TableCell>
                  <TableCell sx={{ width: "35%" }}>Details</TableCell>
                  <TableCell sx={{ width: "13%" }}>Amount Due</TableCell>
                  <TableCell sx={{ width: "13%" }}>Amount Paid</TableCell>
                  {isNullUndefined(props.hideVoid) && (
                    <TableCell sx={{ width: "5%" }} />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {financials.map(function (financial, i) {
                    const isInvoice = financial && financial.type === "Invoice"
                    const isReceipt = financial && financial.type === "Receipt"
                    const isCredit = financial && financial.type === "Credit"
                    const isCharge = financial && financial.type === "Charge"
                    const isPaymentVoucher = financial && financial.type === "Payment Voucher"
                    const isVoided = financial && financial.status === "VOID"
                    return (
                      <TableRow
                        key={i}
                        sx={isVoided ? { backgroundColor: "#ffeeed" } : {}}
                      >
                        <TableCell>{formatDate(financial.date)}</TableCell>
                        <TableCell>{financial.type}</TableCell>
                        <TableCell>
                          {isInvoice && (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument("Invoice", financial.id)
                              }
                            >
                              {props.transactionMode === "Client"
                                ? financial.refNumber
                                : financial.referenceNumber}
                            </Button>
                          )}
                          {isReceipt && (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument("Receipt", financial.id)
                              }
                            >
                              {props.transactionMode === "Client"
                                ? financial.refNumber
                                : financial.referenceNumber}
                            </Button>
                          )}
                          {isCredit && (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument("Credit", financial.id)
                              }
                            >
                              {props.transactionMode === "Client"
                                ? financial.refNumber
                                : financial.referenceNumber}
                            </Button>
                          )}
                          {isCharge && (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument("Charge", financial.id)
                              }
                            >
                              {props.transactionMode === "Client"
                                ? financial.refNumber
                                : financial.referenceNumber}
                            </Button>
                          )}

                          {isPaymentVoucher && (
                              <Button
                                  variant="text"
                                  onClick={() =>
                                      handlePrintDocument("Payment Voucher", financial.id)
                                  }
                              >
                                {financial.referenceNumber}
                              </Button>
                          )}

                          {!isInvoice &&
                            !isReceipt &&
                            !isCredit &&
                            !isCharge &&
                              !isPaymentVoucher &&
                            financial.referenceNumber}
                        </TableCell>
                        <TableCell>{financial.details}</TableCell>
                        <TableCell>{financial.increase}</TableCell>
                        <TableCell>{financial.decrease}</TableCell>
                        {isNullUndefined(props.hideVoid) && (
                          <TableCell>
                            <IconButton
                              disabled={
                                financial.status === "VOID" ||
                                user.proxyRole === "CS" ||
                                user.proxyRole === "REC"
                              }
                              onClick={() => props.handleVoidClick(financial)}
                              sx={{
                                color: "red",
                                fontSize: "5rem",
                                textAlign: "center",
                                fontWeight: 800,
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  }, this)}
              </TableBody>
            </Table>
          )}
          {props.transactionMode === "Meters" && (
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "15%" }}>Name</TableCell>
                  <TableCell sx={{ width: "12%" }}>Reference No.</TableCell>
                  {isBulkWaterMeter && (
                    <TableCell sx={{ width: "10%" }}>Opening Reading</TableCell>
                  )}
                  {isBulkWaterMeter && (
                    <TableCell sx={{ width: "10%" }}>Closing Reading</TableCell>
                  )}
                  <TableCell sx={{ width: "20%" }}>Time</TableCell>
                  <TableCell sx={{ width: "12%" }}>Quantity (Litres)</TableCell>
                  <TableCell sx={{ width: "14%" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {financials.map(function (financial, i) {
                    return (
                      <TableRow key={i}>
                        <TableCell>{financial.name}</TableCell>
                        <TableCell>
                          {financial.transactionType === "Invoice" ? (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument(
                                  "Invoice",
                                  financial.invoiceOrReceiptId
                                )
                              }
                            >
                              {financial.referenceNo}
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              onClick={() =>
                                handlePrintDocument(
                                  "Receipt",
                                  financial.invoiceOrReceiptId
                                )
                              }
                            >
                              {financial.referenceNo}
                            </Button>
                          )}
                        </TableCell>
                        {isBulkWaterMeter && (
                          <TableCell>{financial.openingReading}</TableCell>
                        )}
                        {isBulkWaterMeter && (
                          <TableCell>
                            {financial.closingReading}
                            {financial.transactionType === "Invoice" && (
                              <IconButton
                                onClick={() => showEditReadingModal(financial)}
                              >
                                <Edit />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                        <TableCell>
                          {formatDateTime(financial.entryDate)}
                        </TableCell>
                        <TableCell>{financial.quantity}</TableCell>
                        <TableCell>{financial.amount}</TableCell>
                      </TableRow>
                    )
                  }, this)}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {showLoadMore && <br />}
        {showLoadMore && (
          <Button
            variant="contained"
            type="submit"
            onClick={loadMore}
            sx={{ width: "100%", backgroundColor: "#037171" }}
          >
            Load More
          </Button>
        )}
      </ResponsiveRow>

      {editMeterReadingModal && (
        <ModalContainerModule
          size="small"
          accept={() => {
            submitReading()
          }}
          cancel={() => closeEditReadingModal()}
          openModal={editMeterReadingModal}
          modalTitle={"Edit Meter Reading"}
          acceptButtonText="Update"
        >
          <DetailsContainer sx={{ width: "100%" }} container>
            <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
              <InputLabel>Current reading: {currentReading}</InputLabel>
              <OutlinedInput
                label={`Current reading ${currentReading}`}
                id="editMeterReading"
                placeholder={currentReading}
                value={newReading}
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
                onChange={(event) => {
                  const value = event.target.value
                  setNewReading(value)
                }}
                multiline
                rows={4}
                inputProps={
                  <TextField sx={{ width: { xs: "100%", md: "90%" } }} />
                }
              />
            </FormControl>
          </DetailsContainer>
        </ModalContainerModule>
      )}
    </>
  )
}
