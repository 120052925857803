import { TabContext, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useCallback, useState } from "react";
import CreditNotes from "./creditNotes";
import DebitNotes from "./debitNotes";
import Receipts from "./receipts";
import Invoices from "./invoices";

const documentsTabs = ["Invoices", "Receipts", "Debit Notes", "Credit Notes"];

export default function Documents() {
  const [currentTab, setCurrentTab] = useState("Invoices");

  function handleTabChange(newTab) {
    setCurrentTab(newTab);
  }

  const populateTabs = useCallback(
    function () {
      switch (currentTab) {
        case "Invoices":
          return <Invoices />;

        case "Receipts":
          return <Receipts />;

        case "Debit Notes":
          return <DebitNotes />;

        case "Credit Notes":
          return <CreditNotes />;

        default:
          return <Invoices />;
      }
    },
    [currentTab]
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",

        height: "100%",
        width: "100%",

        // backgroundColor: "#fff",
        borderRadius: "20px",

        // boxShadow: "0 4px 5px 0 rgba(0,0,0,0.3)",
        padding: "20px",
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",

            width: "100%",

            overflowX: "auto",
          }}
        >
          <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            sx={{ marginTop: "20px" }}
          >
            {documentsTabs.map((tab) => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  id={tab}
                  value={tab}
                  aria-controls={`tabPanel-${currentTab}`}
                  onClick={() => handleTabChange(tab)}
                />
              );
            })}
          </Tabs>
        </Box>
        {documentsTabs.map((tab) => {
          return (
            <TabPanel
              key={tab}
              value={tab}
              index={tab}
              sx={{ width: "100%", padding: 0, margin: "5px 0 0 0" }}
            >
              {populateTabs(tab)}
            </TabPanel>
          );
        })}
      </TabContext>
    </Grid>
  );
}
