import {
	Alert,
	AlertTitle,
	Divider,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import {
	ModalAcceptButton,
	ModalCancelButton,
} from '../../constants/component.constants';
import {
	ModalContainer,
	ModalInnerContainer,
	ResponsiveRow,
} from '../../constants/layout.constants';
import { titleCase } from '../../util/util';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Print } from '@mui/icons-material';

/**
 * REQUIRED PROPS
 *    size='' //modal size presets (xs, sm, md)
 *    accept={} //accept function
 *    cancel={} //cancel function
 *    openModal={} //open modal function
 *    modalTitle='' //modal title
 *    acceptButtonText='' // special accept button text
 *    acceptDisabled={}
 *    acceptDisabled2={}
 *    cancelDisabled={}
 *    cancelDisabled2={}
 */

export default function ModalContainerModule(props) {
	const [buttonLoading, setButtonLoading] = useState(false);
	const [rejectButtonLoading, setRejectButtonLoading] = useState(false);
	const [buttonLoading2, setButtonLoading2] = useState(false);
	const [modalSize, setModalSize] = useState(['80vh', '90vw']);

	const appMessage = useSelector((state) => state.message);

	useEffect(() => {
		switch (props.size) {
			case 'xs':
				setModalSize([
					{ height: 'auto', width: '30vw' },
					{ height: 'auto', width: '30vw' },
				]);
				break;
			case 'small':
				setModalSize([
					{ height: 'auto', width: '50vw' },
					{ height: 'auto', width: '50vw' },
				]);
				break;
			case 'sm':
				setModalSize([
					{ height: '60vh', width: '50vw' },
					{ height: '60vh', width: '50vw' },
				]);
				break;
			case 'md':
				setModalSize([
					{ height: '70vh', width: '70vw' },
					{ height: '70vh', width: '70vw' },
				]);
				break;
			case 'lg':
				setModalSize([
					{ height: '80vh', width: '90vw' },
					{ height: '70vh', width: '90vw' },
				]);
				break;
			case 'subscriptionAddProperty':
				setModalSize([
					{ height: '80vh', width: '90vw' },
					{ height: '70vh', width: '90vw' },
				]);
				break;
			case 'specialAddMeter':
				setModalSize([
					{ height: '45vh', width: '50vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialEditMeter':
				setModalSize([
					{ height: '55vh', width: '60vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialReceiptCharge':
				setModalSize([
					{ height: '50vh', width: '60vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialWorkOrderCreateBill':
				setModalSize([
					{ height: '80vh', width: '90vw' },
					{ height: '85vh', width: '90vw' },
				]);
				break;
			case 'specialWordOrderAddVendor':
				setModalSize([
					{ height: '60vh', width: '90vw' },
					{ height: '70vh', width: '90vw' },
				]);
				break;
			case 'specialWorkOrderAddCharge':
				setModalSize([
					{ height: '60vh', width: '90vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
			case 'specialPaymentsViewPayment':
				setModalSize([
					{ height: 'auto', width: '90vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
			case 'specialCreateChargeModal':
				setModalSize([
					{ height: '55vh', width: '60vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
			case 'specialCreateBill':
				setModalSize([
					{ height: '55vh', width: '60vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
			case 'specialMakePaymentModal':
				setModalSize([
					{ height: '60vh', width: '70vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
			case 'viewPendingPaymentInfo':
				setModalSize([
					{ height: 'auto', width: '60vw' },
					{ height: '60vh', width: '60vw' },
				]);
				break;
			case 'specialGenerateReport':
				setModalSize([
					{ height: '45vh', width: '50vw' },
					{ height: 'auto', width: '90vw' },
				]);
				break;
			case 'specialAddNewUser':
				setModalSize([
					{ height: '50vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialAddProperUnit':
				setModalSize([
					{ height: '50vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialTerminatedAddNote':
				setModalSize([
					{ height: '42vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialAddMeterBilling':
				setModalSize([
					{ height: '45vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialSuspendMeterBillingModal':
				setModalSize([
					{ height: '50vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialDownloadMeterBilling':
				setModalSize([
					{ height: '45vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;
			case 'specialComplexInspections':
				setModalSize([
					{ height: '65vh', width: '90vw' },
					{ height: '60vh', width: '90vw' },
				]);
				break;

			case 'specialReverseJournalEntry':
				setModalSize([
					{ height: 'auto', width: '50vw' },
					{ height: 'auto', width: '90vw' },
				]);
				break;

			case 'specialAssetDelete':
				setModalSize([
					{ height: 'auto', width: '40vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;
			case 'deleteProperty':
				setModalSize([
					{ height: 'auto', width: '40vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialViewOrganizationNotification':
				setModalSize([
					{ height: 'auto', width: '30vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialViewWorkOrderNotes':
				setModalSize([
					{ height: '30vw', width: '60vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialEditPropertyUnit':
				setModalSize([
					{ height: 'auto', width: '70vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialAddLeaseSwapUnitModal':
				setModalSize([
					{ height: '40vh', width: '50vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialApproveQuotationModal':
				setModalSize([
					{ height: '50vh', width: '60vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialPersonOrgSearchModal':
				setModalSize([
					{ height: '57vh', width: '50vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialAddVendorContact':
				setModalSize([
					{ height: '55vh', width: '50vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialAddVendorDetails':
				setModalSize([
					{ height: '50vh', width: '70vw' },
					{ height: '70vh', width: '85vw' },
				]);
				break;

			case 'specialReplaceVendorContact':
				setModalSize([
					{ height: '60vh', width: '50vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialEditUser':
				setModalSize([
					{ height: '60vh', width: '80vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialTransferDetails':
				setModalSize([
					{ height: '45vh', width: '70vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialTransferFilter':
				setModalSize([
					{ height: '40vh', width: '50vw' },
					{ height: 'auto', width: '80vw' },
				]);
				break;

			case 'specialLeaseCreditNoteSize':
				setModalSize([
					{ height: '65vh', width: '80vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialConfirmationModal':
				setModalSize([
					{ height: 'auto', width: '40vw' },
					{ height: 'auto', width: '80vw' },
				]);
				break;

			case 'specialViewReceiptSize':
				setModalSize([
					{ height: '40vh', width: '60vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialAddNoteSize':
				setModalSize([
					{ height: 'auto', width: '40vw' },
					{ height: 'auto', width: '80vw' },
				]);
				break;

			case 'specialInspectionDetailsSize':
				setModalSize([
					{ height: '55vh', width: '50vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialContractContactModal':
				setModalSize([
					{ height: '60vh', width: '80vw' },
					{ height: '60vh', width: '80vw' },
				]);
				break;

			case 'specialAddAccountsModal':
				setModalSize([
					{ height: '45vh', width: '60vw' },
					{ height: '65vh', width: '80vw' },
				]);
				break;

			case 'specialAddMeterReadingModal':
				setModalSize([
					{ height: 'auto', width: '50vw' },
					{ height: '65vh', width: '80vw' },
				]);
				break;

			default:
				setModalSize([
					{ height: '80vh', width: '90vw' },
					{ height: '80vh', width: '90vw' },
				]);
				break;
		}
	}, [props.size]);

	function handleAccept() {
		props.accept();
		setButtonLoading(true);
	}

	function handleReject() {
		props.reject();
		setRejectButtonLoading(true);
	}

	function handleAccept2() {
		props.accept2();
		setButtonLoading2(true);
	}

	function handleCancel() {
		props.cancel();
	}

	useEffect(() => {
		if (buttonLoading === true && appMessage && appMessage.display === true) {
			setButtonLoading(false);
		} else if (
			rejectButtonLoading === true &&
			appMessage &&
			appMessage.display === true
		) {
			setRejectButtonLoading(false);
		} else if (
			buttonLoading2 === true &&
			appMessage &&
			appMessage.display === true
		) {
			setButtonLoading2(false);
		}
	}, [buttonLoading, buttonLoading2, rejectButtonLoading, appMessage]);

	useEffect(() => {
		return () => {
			setButtonLoading(false);
			setRejectButtonLoading(false);
			setButtonLoading2(false);
		};
	}, [props]);

	return (
		<ModalContainer
			open={props.openModal}
			onClose={handleCancel}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<ModalInnerContainer
				sx={{
					height: { xs: modalSize[1].height, lg: modalSize[0].height },
					width: { xs: modalSize[1].width, lg: modalSize[0].width },
					overflow: 'hidden',
				}}
			>
				<ResponsiveRow
					sx={{
						width: '100%',
						height: '100%',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						padding: 0,
						margin: 0,
					}}
				>
					<Grid container direction="row">
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
								width: '100%',
								height: 'auto',
							}}
						>
							<Typography
								id="modalTitle"
								sx={{
									fontSize: { xs: '1.2rem', lg: '1.5rem' },
									fontWeight: 800,
								}}
							>
								{props.modalTitle}
							</Typography>
						</Grid>

						{props.printAction && (
							<Grid
								item
								xs={6}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'flex-end',
									width: '100%',
									height: 'auto',
								}}
							>
								<Typography
									id="modal-print"
									sx={{
										mt: 0,
										color: '#037171',
										fontSize: '1.2rem',
										fontWeight: 500,
									}}
								>
									Print
									<IconButton
										aria-label="Print"
										title={'Print'}
										onClick={props.printAction}
									>
										<Print />
									</IconButton>
								</Typography>
							</Grid>
						)}
					</Grid>
					<Divider />
					<br />
					{appMessage.display && (
						<Alert severity={appMessage.type}>
							<AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
							{appMessage.message}
						</Alert>
					)}
					<ResponsiveRow
						sx={{
							height: '100%',
							width: '100%',
							overflowY: 'auto',
							overflowX: 'hidden',
							padding: 0,
							margin: '0 0 10px 0',
							alignItems: 'center',
						}}
					>
						{props.children}
					</ResponsiveRow>
					<ResponsiveRow
						sx={{
							justifyContent: { xs: 'center', lg: 'flex-end' },
							alignItems: 'center',
							height: 'auto',
							padding: '10px auto',
						}}
					>
						{props.accept && (
							<ModalAcceptButton
								onClick={handleAccept}
								sx={{
									width: { xs: '100%', lg: '200px' },
									height: '50px',
								}}
								loading={buttonLoading}
								disabled={props.acceptDisabled && props.acceptDisabled === true}
							>
								{buttonLoading ? '' : props.acceptButtonText}
							</ModalAcceptButton>
						)}
						{props.reject && (
							<ModalCancelButton
								onClick={handleReject}
								sx={{
									width: { xs: '100%', lg: '200px' },
									height: '50px',
								}}
								loading={rejectButtonLoading}
								disabled={props.acceptDisabled && props.acceptDisabled === true}
							>
								{rejectButtonLoading ? '' : props.rejectButtonText}
							</ModalCancelButton>
						)}
						{props && props.accept2 && (
							<ModalAcceptButton
								onClick={handleAccept2}
								sx={{
									width: { xs: '100%', lg: '200px' },
									height: '50px',
								}}
								loading={buttonLoading2}
								disabled={
									props.acceptDisabled2 && props.acceptDisabled2 === true
								}
							>
								{buttonLoading2 ? '' : props.acceptButtonText2}
							</ModalAcceptButton>
						)}

						{props.cancel2 && (
							<ModalCancelButton
								onClick={props.handleCancel2}
								sx={{
									width: { xs: '100%', lg: '200px' },
									height: '50px',
								}}
								disabled={
									props.cancelDisabled2 && props.cancelDisabled2 === true
								}
							>
								{props.cancel2Text}
							</ModalCancelButton>
						)}
						{props.cancel && (
							<ModalCancelButton
								onClick={handleCancel}
								sx={{
									width: { xs: '100%', lg: '200px' },
									height: '50px',
								}}
								disabled={
									props.canceltDisabled && props.cancelDisabled === true
								}
							>
								{props.cancelText ? props.cancelText : 'Cancel'}
							</ModalCancelButton>
						)}
					</ResponsiveRow>
				</ResponsiveRow>
			</ModalInnerContainer>
		</ModalContainer>
	);
}
