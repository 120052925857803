import {Alert, FormControl, IconButton, InputLabel, OutlinedInput, TextField,} from "@mui/material"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants"
import {formatDate, isEmptyString} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"
import { downloadUtilityImage } from "../../../../services/meters.service"
import { Photo } from "@mui/icons-material"
import { FileUpload } from "../../../inputs/fileUpload"
import { ResponsiveRow } from "../../../../constants/layout.constants"


export default function EditMeterReadingsModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * setEditReading
     * setEditedMeterReadingAmount
     * setselectedReading
     *
     * editMeterReading
     * editedMeterReadingAmount
     * editReading
     * nextReading
     * previousReading
     * selectedMeter
     * selectedReading
     *
     */

    return (
        <ModalContainerModule
            size="specialAddMeterReadingModal"
            accept={props.editMeterReading}
            cancel={() => {
                props.setEditReading(false)
                props.setselectedReading(null)
            }}
            openModal={props.editReading}
            modalTitle={`Meter Readings for ${
                props.selectedMeter && props.selectedMeter.name
            }`}
            acceptButtonText="Edit Reading"
        >
            <DetailsContainer sx={{width: "100%", height: "auto"}} container>
                <DetailsColumn
                    sx={{width: {xs: "100%", lg: "50%"}, flexWrap: "wrap"}}
                >
                    <DetailsRow sx={{width: "100%"}}>
                        <DetailsLabel>Date: </DetailsLabel>
                        <DetailsText>
                            {props.selectedReading && formatDate(props.selectedReading.date)}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow sx={{width: "100%"}}>
                        <DetailsLabel>Previous Reading:</DetailsLabel>
                        <DetailsText>
                            {props.selectedMeter && props.selectedMeter.lastMeterReading
                                ? props.selectedMeter.lastMeterReading
                                : "No previous readings"}
                        </DetailsText>
                    </DetailsRow>
                    {!isEmptyString(props.nextReading) && (
                        <DetailsRow sx={{width: "100%"}}>
                            <DetailsLabel>Next Reading value:</DetailsLabel>
                            <DetailsText>{props.nextReading.amount}</DetailsText>
                        </DetailsRow>
                    )}
                    <DetailsRow>
                        <DetailsLabel>Read By:</DetailsLabel>
                        <DetailsText>
                            {props.selectedReading && props.selectedReading.readByName}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Image:</DetailsLabel>
                        <DetailsText>
                            {/* {props.selectedReading && props.selectedReading.image} */}
                            {props.selectedReading?.imageFile ? (
                                <IconButton
                                    onClick={() => downloadUtilityImage(props.selectedReading?.imageFile)}>
                                    <Photo
                                        sx={{
                                            fontSize: "1.75rem",
                                            margin: "auto",
                                            width: "50%",
                                        }}
                                    />
                                </IconButton>
                            ) : (
                                "No image file"
                            )}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <FormControl sx={{width: {xs: "100%", lg: "50%"}}}>
                    <InputLabel>
                        New reading (Currently{" "}
                        {props.selectedReading && props.selectedReading.amount})
                    </InputLabel>
                    <OutlinedInput
                        label={`New reading (Currently ${
                            props.selectedReading && props.selectedReading.amount
                        })`}
                        id="editMeterReading"
                        value={props.editedMeterReadingAmount}
                        sx={{
                            width: {xs: "100%", md: "90%"},
                        }}
                        onChange={(event) => {
                            const value = event.target.value
                            props.setEditedMeterReadingAmount(value)
                        }}
                        multiline
                        rows={4}
                        inputProps={<TextField sx={{width: {xs: "100%", md: "90%"}}}/>}
                    />
                </FormControl>
            </DetailsContainer>
            <br/>
            <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
                {props.selectedReading && props.selectedReading.imageFile && (
                    <Alert
                        severity="info"
                        sx={{ marginTop: "1%", marginBottom: "1%" }}
                    >
                        Uploading a new Image replaces the current one.
                    </Alert>
                )}
                <FileUpload
                    id={"bulkFileInput"}
                    bulkFileprogress={props.fileProgress}
                    onChange={(e) => props.setFile(e.target.files[0])}
                    accept={".png, .jpg, .jpeg"}
                />
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
