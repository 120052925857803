/* eslint-disable react-hooks/exhaustive-deps */
//invoices page
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel, CreditCard, Delete, Edit, Print } from "@mui/icons-material";
import {
  downloadInvoices,
  exportInvoices,
  getTotalInvoices,
  listClients,
  listInvoiceStatus,
  listTenants,
  loadInvoiceLease,
  loadInvoiceOwnership,
  loadInvoices,
  saveInvoice,
  saveInvoicePayment,
} from "../services/invoices.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { useSelector } from "react-redux";
import {
  allUnitOptions,
  getActiveComplexForOrgOptions,
  getAssetOptions,
  getFacilityOptions,
  getIncomeAccounts,
  getVATAccount,
} from "../services/list.service";
import {
  issueInfoMessage,
  issueResponseMessage,
  issueWarnMessage,
} from "../actions/message";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CreditNote from "../components/modals/creditNote";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import { printInvoice } from "../services/print.service";
import CreateInvoiceModal from "../components/modals/accounting/invoice/createInvoiceModal";
import {
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import {
  adjustForTimezone,
  getRefNumber,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
} from "../util/util";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import dayjs from "dayjs";
import ReceivePayment from "../components/modals/receivePayment";

export default function Invoices() {
  const user = useSelector((state) => state.auth.user);
  const [task, setTask] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [invoiceType, setInvoiceType] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoicee, setInvoicee] = useState("");
  const [invoiceStatuses, setInvoiceStatuses] = useState([]);
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("");
  const [invoiceFromDate, setInvoiceFromDate] = useState("");
  const [invoiceToDate, setInvoiceToDate] = useState("");
  const [dueDateFrom, setDueDateFrom] = useState("");
  const [dueDateTo, setDueDateTo] = useState("");
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [invoicesList, setInvoicesList] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [filterInvoices, setFilterInvoices] = useState(true);
  const [showAddInvoiceForm, setShowAddInvoiceForm] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [selectedTenant, setSelectedTenant] = useState("");
  const [memo, setMemo] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [client, setClient] = useState(false);
  const [tenant, setTenant] = useState(false);
  const [attachToUnit, setAttachToUnit] = useState(false);
  const [attachToAsset, setAttachToAsset] = useState(false);
  const [attachToComplex, setAttachToComplex] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [vatAccount, setVATAccount] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [downloadInvoicesModal, setDownloadInvoicesModal] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [creditNote, setCreditNote] = useState(null);
  const [isVoiding, setIsVoiding] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [creditMode, setCreditMode] = useState("");
  const [contractId, setContractId] = useState(null);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [loading, setLoading] = useState(true);
  const [listOfAssets, setListOfAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [listOfComplexes, setListOfComplexes] = useState([]);
  const [selectedComplex, setSelectedComplex] = useState(null);
  const [file, setFile] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [rowsPerPage] = useState(300);

  useEffect(() => {
    setLoading(true);
    setShowAll(false);
    listInvoiceStatus().then((data) => setInvoiceStatuses(data));
    getFacilityOptions().then((facilityOptions) =>
      setFacilityOptions(facilityOptions)
    );
    listClients().then((data) => setClientList(data));
    listTenants(user.proxyFacility).then((data) => setTenantList(data));
    getIncomeAccounts().then((accOptions) => setAccountsList(accOptions));
    getVATAccount().then((vatAccount) => setVATAccount(vatAccount));
    getAssetOptions().then((data) => setListOfAssets(data));
    getActiveComplexForOrgOptions().then((data) => setListOfComplexes(data));
  }, [user.proxyFacility]);

  useEffect(() => {
    if (!showAll) {
      setLoading(true);
      let searchObject = {
        invoicee: invoicee,
        invoiceNumber: invoiceNumber,
        selectedInvoiceStatus: selectedInvoiceStatus,
        invoiceFromDate: "",
        invoiceToDate: "",
        dueDateFrom: "",
        dueDateTo: "",
        invoiceType: invoiceType,
        pageNo: paginationModel.page,
        rowsPerPage: paginationModel.pageSize,
      };
      if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
        searchObject.invoiceFromDate = invoiceFromDate;
      }
      if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
        searchObject.invoiceToDate = invoiceToDate;
      }
      if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
        searchObject.dueDateFrom = dueDateFrom;
      }
      if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
        searchObject.dueDateTo = dueDateTo;
      }
      getTotalInvoices(searchObject).then((response) => {
        setTotalInvoices(response.totalInvoices);
      });
      loadInvoices(searchObject)
        .then((data) => {
          setInvoicesList(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    let facilityId = null;
    if (user.proxyFacility != null) {
      facilityId = user.proxyFacility;
    }
    allUnitOptions(facilityId).then((unitOptions) =>
      setUnitOptions(unitOptions)
    );
  }, [selectedInvoice]);

  function handleSearchClicked() {
    setLoading(true);
    setShowAll(false);
    let searchObject = {
      invoicee: invoicee,
      invoiceNumber: invoiceNumber,
      selectedInvoiceStatus: selectedInvoiceStatus,
      invoiceFromDate: "",
      invoiceToDate: "",
      dueDateFrom: "",
      dueDateTo: "",
      invoiceType: invoiceType,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
      searchObject.invoiceFromDate = invoiceFromDate;
    }
    if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
      searchObject.invoiceToDate = invoiceToDate;
    }
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = dueDateFrom;
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = dueDateTo;
    }
    getTotalInvoices(searchObject).then((response) => {
      setTotalInvoices(response.totalInvoices);
    });
    loadInvoices(searchObject)
      .then((data) => setInvoicesList(data))
      .finally(() => {
        setLoading(false);
        setTask("Filter");
      });
  }

  function handleShowAllClicked() {
    setInvoicesList([]);
    setShowAll(true);
    setLoading(true);
    let searchObject = {
      invoicee: invoicee,
      invoiceNumber: invoiceNumber,
      selectedInvoiceStatus: selectedInvoiceStatus,
      invoiceFromDate: "",
      invoiceToDate: "",
      dueDateFrom: "",
      dueDateTo: "",
      invoiceType: invoiceType,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
      searchObject.invoiceFromDate = invoiceFromDate;
    }
    if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
      searchObject.invoiceToDate = invoiceToDate;
    }
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = dueDateFrom;
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = dueDateTo;
    }
    getTotalInvoices(searchObject).then((response) => {
      setTask("Filter");
      setTotalInvoices(response.totalInvoices);
      let invoices = new Set();
      let promises = [];
      let size = Math.ceil(response.totalInvoices / rowsPerPage);
      for (let i = 0; i < size; i++) {
        searchObject.pageNo = i;
        searchObject.rowsPerPage = rowsPerPage;
        promises.push(
          loadInvoices(searchObject).then((data) => {
            new Set(data).forEach(invoices.add, invoices);
            setInvoicesList([...invoices]);
          })
        );
      }

      Promise.all(promises).then(() => {
        setInvoicesList([...invoices]);
        setLoading(false);
      });
    });
  }

  function uploadFileChanged(event) {
    let file = event.target.files[0];
    setFile(file);
  }

  function downloadSelectedInvoices() {
    if (isNullUndefined(selectionModel) || selectionModel.size < 1) {
      issueWarnMessage(
        "Could not determine the download items. Please refresh the page and try again."
      );
      return;
    }

    let sendData = {
      invoiceIds: selectionModel,
    };
    downloadInvoices(sendData).then((response) => {
      issueResponseMessage(response);
      handleCloseDownloadInvoicesModal();
    });
  }

  function handleCloseDownloadInvoicesModal() {
    setDownloadInvoicesModal(false);
    setSelectionModel([]);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
    setTask("Filter");
  }

  function handleExportInvoices() {
    if (
      isNullUndefined(invoicesList) ||
      !isArray(invoicesList) ||
      isEmptyArray(invoicesList)
    ) {
      issueWarnMessage(
        "Could not determine the items to be exported. Please refresh the page and try again."
      );
      return;
    }
    let invoiceFromDateValue = "";
    let invoiceToDateValue = "";
    let dueDateFromValue = "";
    let dueDateToValue = "";
    if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
      let date = new Date(invoiceFromDate);
      invoiceFromDateValue = date.toISOString();
    }

    if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
      let date = new Date(invoiceToDate);
      invoiceToDateValue = date.toISOString();
    }

    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      let date = new Date(dueDateFrom);
      dueDateFromValue = date.toISOString();
    }

    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      let date = new Date(dueDateTo);
      dueDateToValue = date.toISOString();
    }

    let sendData = {
      invoiceNumber: invoiceNumber,
      invoicee: invoicee,
      invoiceStatus: selectedInvoiceStatus,
      invoiceFromDate: invoiceFromDateValue,
      invoiceToDate: invoiceToDateValue,
      dueDateFrom: dueDateFromValue,
      dueDateTo: dueDateToValue,
      invoiceType: invoiceType,
    };
    exportInvoices(sendData).then(() => {
      issueInfoMessage("File successfully exported");
      setTask("");
      setTask("Filter");
    });
  }

  function handleDownloadInvoices() {
    setDownloadInvoicesModal(true);
    let title =
      selectionModel.length > 1
        ? "Download " + selectionModel.length + " Invoices"
        : "Download Invoice";
    let body =
      "Note: Processing the selected invoices may take some time." +
      " Therefore, the exported data will be emailed to you when it is finished being packaged." +
      " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
    setTitleText(title);
    setSeverityText("info");
    setBodyText(body);
  }

  function positiveExportDocsAction() {
    downloadSelectedInvoices();
  }

  function handleClearClicked() {
    setTask("");
    setInvoicee("");
    setInvoiceNumber("");
    setSelectedInvoiceStatus("");
    setInvoiceFromDate("");
    setInvoiceToDate("");
    setDueDateFrom("");
    setDueDateTo("");
    setSelectionModel([]);
    setInvoiceType("");
    setShowAll(false);
  }

  function handleAddInvoice() {
    setTask("Create");
    setShowAddInvoiceForm(true);
    setFilterInvoices(false);
    let facilityId = null;
    if (user.proxyFacility != null) {
      setSelectedFacility(user.proxyFacility);
      facilityId = user.proxyFacility;
    }
    let refNumber = getRefNumber();
    let data = {
      id: null,
      referenceNumber: refNumber,
      date: null,
      clientId: null,
      tenantId: null,
      dueDate: null,
      description: "",
      facilityId: facilityId,
      unitId: null,
      transactionItems: [],
      imageFiles: [],
      otherDocuments: [],
    };
    setSelectedInvoice(data);
  }

  function handleEditInvoice(selectedInvoice) {
    setTask("Edit");
    setShowAddInvoiceForm(true);
    setSelectedInvoice(selectedInvoice);
    setDate(selectedInvoice.date);
    setDueDate(selectedInvoice.dueDate);
    setSelectedFacility(selectedInvoice.facilityId);
    setMemo(selectedInvoice.description);
    setSelectedTenant(selectedInvoice.tenantId);
    setSelectedClient(selectedInvoice.clientId);
    setSelectedUnit(selectedInvoice.unitId);
    setSelectedAsset(selectedInvoice.assetId);
    setSelectedComplex(selectedInvoice.complexId);

    if (
      !isEmptyString(selectedInvoice.clientId) ||
      !isNullUndefined(selectedInvoice.clientId)
    ) {
      setClient(true);
      setTenant(false);
      setAttachToUnit(false);
      setAttachToAsset(false);
      setAttachToComplex(false);
      setRadioValue("Client");
      let obj = selectedInvoice;
      obj.tenantId = null;
      setSelectedInvoice(obj);
    }
    if (
      !isEmptyString(selectedInvoice.tenantId) ||
      !isNullUndefined(selectedInvoice.tenantId)
    ) {
      setTenant(true);
      setClient(false);
      setAttachToUnit(false);
      setAttachToAsset(false);
      setAttachToComplex(false);
      setRadioValue("Tenant");
      let obj = selectedInvoice;
      obj.clientId = null;
      obj.unitId = "";
      setSelectedInvoice(obj);
    }

    if (
      !isEmptyString(selectedInvoice.unitId) ||
      !isNullUndefined(selectedInvoice.unitId)
    ) {
      setAttachToUnit(true);
      setTenant(false);
      setClient(false);
      setAttachToAsset(false);
      setAttachToComplex(false);
      setRadioValue("Unit");
      let obj = selectedInvoice;
      obj.clientId = null;
      obj.tenantId = null;
      setSelectedInvoice(obj);
    }

    if (
      !isEmptyString(selectedInvoice.complexId) ||
      !isNullUndefined(selectedInvoice.complexId)
    ) {
      setAttachToComplex(true);
      setRadioValue("Complex");
      setTenant(false);
      setClient(false);
      setAttachToUnit(false);
      setAttachToAsset(false);
    }

    if (
      !isEmptyString(selectedInvoice.assetId) ||
      !isNullUndefined(selectedInvoice.assetId)
    ) {
      setAttachToAsset(true);
      setRadioValue("Asset");
      setTenant(false);
      setClient(false);
      setAttachToUnit(false);
      setAttachToComplex(false);
    }

    setInvoiceItems(selectedInvoice.transactionItems);
  }

  function handleVoidInvoice(item) {
    let data = {
      referenceNumber: item.referenceNumber,
      type: "Invoice",
      id: item.id,
    };
    // console.log('Invoice is: ', data)
    setSelectedInvoice(data);
    if (user.services.includes("HOA")) {
      //HOA
      loadInvoiceOwnership(item.id).then((data) => {
        // console.log("invoiceOwnership", data)
        // setSelectedOwnership(data.selectedTitle)
        if (!isNullUndefined(data.selectedTitle)) {
          // getOwnershipCreditItem(item, data.selectedTitle)
          setIsVoiding(true);
          setShowCreditModal(true);
          setCreditMode("Ownership");
          setContractId(data.selectedTitle.id);
        }
      });
    } else if (user.services.includes("BLWTR")) {
      // Bulk water
      //TODO: Implement this if needed
    } else if (user.services.includes("MAINT")) {
      if (item && !isNullUndefined(item.personId)) {
        setCustomerId(item.personId + "_P");
      } else {
        if (item && !isNullUndefined(item.organizationId)) {
          setCustomerId(item.organizationId + "_O");
        }
      }
      setIsVoiding(true);
      setShowCreditModal(true);
    } else {
      //Residential
      loadInvoiceLease(item.id).then((data) => {
        // console.log("data.selectedLease: ", data)
        // setSelectedLease(data.selectedLease)
        if (!isNullUndefined(data.selectedLease)) {
          // getLeaseCreditItem(item, data.selectedLease)
          setIsVoiding(true);
          setShowCreditModal(true);
          setCreditMode("Lease");
          setContractId(data.selectedLease.id);
        }
      });
    }
  }

  const handleCreditModalClose = () => {
    setCreditNote(null);
    setShowCreditModal(false);
    let searchObject = {
      invoicee: invoicee,
      invoiceNumber: invoiceNumber,
      selectedInvoiceStatus: selectedInvoiceStatus,
      invoiceFromDate: "",
      invoiceToDate: "",
      dueDateFrom: "",
      dueDateTo: "",
      invoiceType: invoiceType,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
      searchObject.invoiceFromDate = invoiceFromDate;
    }
    if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
      searchObject.invoiceToDate = invoiceToDate;
    }
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = dueDateFrom;
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = dueDateTo;
    }
    getTotalInvoices(searchObject).then((response) => {
      setTotalInvoices(response.totalInvoices);
    });
    loadInvoices(searchObject).then((data) => setInvoicesList(data));
  };

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      if (value === "Client") {
        setClient(true);
        setTenant(false);
        setAttachToUnit(false);
        setAttachToAsset(false);
        setAttachToComplex(false);
      }
      if (value === "Tenant") {
        setTenant(true);
        setClient(false);
        setAttachToUnit(false);
        setAttachToAsset(false);
        setAttachToComplex(false);
      }
      if (value === "Unit") {
        setTenant(false);
        setClient(false);
        setAttachToUnit(true);
        setAttachToAsset(false);
        setAttachToComplex(false);
      }
      if (value === "Asset") {
        setTenant(false);
        setClient(false);
        setAttachToUnit(false);
        setAttachToAsset(true);
        setAttachToComplex(false);
      }
      if (value === "Complex") {
        setTenant(false);
        setClient(false);
        setAttachToUnit(false);
        setAttachToAsset(false);
        setAttachToComplex(true);
      }
    }
  }

  function handleAddInvoiceItems() {
    let item = {
      id: null,
      creditAccountId: "",
      notes: "",
      amount: 0,
      applyVAT: false,
      displayVATRadio: true,
    };
    // setInvoiceItems(item)
    setInvoiceItems((invoiceItems) => [...invoiceItems, item]);
  }

  function creditAccountChanged(event, index) {
    let value = event.target.value;
    let itemsArray = [...invoiceItems]; // copying the old items array
    itemsArray[index].creditAccountId = value;
    setInvoiceItems(itemsArray);
  }

  function descriptionChanged(event, index) {
    let value = event.target.value;
    let itemsArray = [...invoiceItems]; // copying the old items array
    itemsArray[index].notes = value;
    setInvoiceItems(itemsArray);
  }

  function amountChanged(event, index) {
    let value = event.target.value;
    let itemsArray = [...invoiceItems]; // copying the old items array
    itemsArray[index].amount = value;
    setInvoiceItems(itemsArray);
  }

  function findAndRemoveChargeVAT(detailItem) {
    let vatAmount = getVATAmount(detailItem.amount);
    for (let i = 0; i < invoiceItems.length; i++) {
      if (
        invoiceItems[i].amount === vatAmount &&
        invoiceItems[i].notes.includes(detailItem.notes)
      ) {
        handleRemoveInvoiceItem(i);
      }
    }
  }

  function handleRemoveInvoiceItem(index) {
    let itemsArray = [...invoiceItems]; // copying the old items array
    // if(itemsArray.length > 1){
    itemsArray.splice(index, 1);
    findAndRemoveChargeVAT(invoiceItems[index]);
    setInvoiceItems(itemsArray);
    // }else{
    if (itemsArray.length === 0) {
      setInvoiceItems((invoiceItems) => [
        ...invoiceItems,
        {
          id: null,
          creditAccountId: "",
          notes: "",
          amount: 0,
          applyVAT: false,
          displayVATRadio: true,
        },
      ]);
    }
  }

  function getVATAmount(amount) {
    if (isNaN(amount)) {
      issueWarnMessage("Only numeric amounts are allowed for VAT calculation");
    } else {
      return (amount * 0.16).toFixed(2);
    }
  }

  function handleApplyVatToggle(event, index) {
    let itemsArray = [...invoiceItems]; // copying the old items array
    let detailItem = itemsArray[index];
    if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
      issueWarnMessage("You must enter an amount before applying VAT");
    } else {
      if (event.target.checked) {
        let vatNotes =
          !isEmptyString(detailItem.notes) || !isNullUndefined(detailItem.notes)
            ? "VAT (16%) -" + detailItem.notes
            : "";
        let vatAmount = getVATAmount(detailItem.amount);
        detailItem.applyVAT = true;
        setInvoiceItems(itemsArray);
        setInvoiceItems((items) => [
          ...items,
          {
            id: null,
            creditAccountId: vatAccount.id,
            notes: vatNotes,
            amount: vatAmount,
            applyVAT: false,
            displayVATRadio: false,
          },
        ]);
      } else {
        detailItem.applyVAT = false;
        setInvoiceItems(itemsArray);
        findAndRemoveChargeVAT(detailItem);
      }
    }
  }

  let invoiceItemsInputs = "";
  // eslint-disable-next-line no-lone-blocks
  {
    invoiceItems &&
      invoiceItems.length > 0 &&
      (invoiceItemsInputs = invoiceItems.map(function (transactionItem, i) {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"creditAccount" + i}
                disabled={!transactionItem.displayVATRadio}
                value={transactionItem.creditAccountId}
                onChange={(event) => creditAccountChanged(event, i)}
              >
                {accountsList && accountsList.length > 0 ? (
                  accountsList.map(function (item, i) {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"description" + i}
                name={"description" + i}
                isrequired={true}
                disabled={!transactionItem.displayVATRadio}
                value={transactionItem.notes}
                onChange={(event) => descriptionChanged(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"amount" + i}
                name={"amount" + i}
                isrequired={true}
                disabled={!transactionItem.displayVATRadio}
                value={transactionItem.amount}
                onChange={(event) => amountChanged(event, i)}
              />
            </TableCell>
            <TableCell>
              {transactionItem.displayVATRadio && (
                <Checkbox
                  checked={transactionItem.applyVAT}
                  onChange={(event) => handleApplyVatToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </TableCell>
            <TableCell>
              {transactionItem.displayVATRadio && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={(event) => handleRemoveInvoiceItem(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      }, this));
  }

  function handleCancelClicked() {
    setSelectedInvoice(null);
    setTask("");
    setShowAddInvoiceForm(false);
    setDate("");
    setDueDate("");
    setInvoiceFromDate("");
    setInvoiceToDate("");
    setDueDateFrom("");
    setDueDateTo("");
    setSelectedFacility("");
    setMemo("");
    setSelectedUnit("");
    setSelectedClient("");
    setSelectedTenant("");
    setSelectedAsset(null);
    setSelectedComplex(null);
    setFilterInvoices(true);
    setInvoiceItems([]);
    setTenant(false);
    setClient(false);
    setAttachToUnit(false);
    setAttachToAsset(false);
    setAttachToComplex(false);
    setRadioValue("");
  }

  function validateForm(sendData) {
    if (isNullUndefined(sendData)) {
      issueWarnMessage(
        "Something went wrong with the form. Please refresh the page or contact support"
      );
      return false;
    }
    if (isNullUndefined(sendData.date)) {
      issueWarnMessage("Select a valid invoicing date");
      return false;
    }
    if (isNullUndefined(sendData.dueDate)) {
      issueWarnMessage("Select a valid invoice due date");
      return false;
    }
    if (isEmptyString(sendData.facilityId)) {
      issueWarnMessage("Please select a facility");
      return false;
    }
    if (isEmptyString(sendData.description)) {
      issueWarnMessage("Please add a description for the Invoice");
      return false;
    }
    if (client && isEmptyString(sendData.clientId)) {
      issueWarnMessage("Please select the client to issue the Invoice to");
      return false;
    }
    // if ((this.state.applyTo && this.state.applyTo == 'Client') && isNullUndefined(sendData.unitId)) {
    //     return this.showMessage("warn", "Please select a Unit to issue the invoice")
    // }
    if (tenant && isEmptyString(sendData.tenantId)) {
      issueWarnMessage("Please select the tenant to issue the Invoice to");
      return false;
    }
    if (attachToUnit && isEmptyString(sendData.unitId)) {
      issueWarnMessage("Please select a property unit to issue the Invoice to");
      return false;
    }

    if (attachToAsset && isEmptyString(sendData.assetId)) {
      issueWarnMessage("Please select an asset to issue the Invoice to");
      return false;
    }

    if (attachToComplex && isEmptyString(sendData.complexId)) {
      issueWarnMessage("Please select a complex to issue the Invoice to");
      return false;
    }

    if (
      isEmptyArray(sendData.transactionItems) ||
      sendData.transactionItems.length <= 0
    ) {
      issueWarnMessage("Invoice Items must be added to generate the invoice.");
      return false;
    } else {
      sendData.transactionItems.forEach((item, index) => {
        let i = index + 1;
        if (item.amount < 0) {
          issueWarnMessage("The value entered for amount" + i + " is invalid");
          return false;
        }
        if (isEmptyString(item.notes)) {
          issueWarnMessage("Please enter description for the invoice item" + i);
          return false;
        }
        if (isEmptyString(item.creditAccountId)) {
          issueWarnMessage(
            "Please select the account to be credited for invoice item" + i
          );
          return false;
        }
      });
    }

    return true;
  }

  function saveInvoiceClicked() {
    let obj = selectedInvoice;
    obj.transactionItems = invoiceItems;
    setSelectedInvoice(obj);
    let formData = new FormData();
    formData.append("invoice", JSON.stringify(selectedInvoice));
    if (task === "Create" && !isNullUndefined(file)) {
      formData.append("file", file);
    }

    if (validateForm(selectedInvoice)) {
      saveInvoice(formData).then((response) => {
        let searchObject = {
          invoicee: invoicee,
          invoiceNumber: invoiceNumber,
          selectedInvoiceStatus: selectedInvoiceStatus,
          invoiceFromDate: "",
          invoiceToDate: "",
          dueDateFrom: "",
          dueDateTo: "",
          invoiceType: invoiceType,
          pageNo: paginationModel.page,
          rowsPerPage: paginationModel.pageSize,
        };
        if (!isNullUndefined(invoiceFromDate) && isValidDate(invoiceFromDate)) {
          searchObject.invoiceFromDate = invoiceFromDate;
        }
        if (!isNullUndefined(invoiceToDate) && isValidDate(invoiceToDate)) {
          searchObject.invoiceToDate = invoiceToDate;
        }
        if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
          searchObject.dueDateFrom = dueDateFrom;
        }
        if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
          searchObject.dueDateTo = dueDateTo;
        }
        getTotalInvoices(searchObject).then((response) => {
          setTotalInvoices(response.totalInvoices);
        });
        loadInvoices(searchObject).then((data) => setInvoicesList(data));
        issueResponseMessage(response);
        handleCancelClicked();
        handleClearClicked();
      });
    }
  }

  const columns = [
    {
      headerName: "Invoice No",
      field: "referenceNumber",
      minWidth: 130,
      flex: 1.3,
    },
    { headerName: "Date", field: "ukDate", minWidth: 160, flex: 1.6 },
    { headerName: "Due Date", field: "ukDueDate", minWidth: 160, flex: 1.6 },
    { headerName: "Invoicee", field: "invoicee", minWidth: 200, flex: 2 },
    {
      headerName: "Description",
      field: "description",
      minWidth: 275,
      flex: 275,
    },
    { headerName: "Status", field: "status", minWidth: 100, flex: 1 },
    { headerName: "Amount", field: "amount", minWidth: 130, flex: 1.3 },
    {
      headerName: "Outstanding",
      field: "outstandingBalance",
      minWidth: 130,
      flex: 1.3,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Print />}
            onClick={() => {
              printInvoice(params.id).then((response) => {
                if (!isNullUndefined(response)) {
                  issueResponseMessage(response);
                }
              });
            }}
            label="Print Invoice"
            showInMenu
          />
        );
        if (
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "BLDMG" ||
          user.proxyRole === "CS" ||
          user.proxyRole === "ACC"
        ) {
          if (params.row?.statusCd !== "VOID") {
            //Don't allow Editing or Voiding of an already VOIDED invoice
            if (user.services.includes("MAINT")) {
              arr.push(
                <GridActionsCellItem
                  icon={<Edit />}
                  onClick={() => handleEditInvoice(params.row)}
                  label="Edit Invoice"
                  showInMenu
                />
              );
            }
            arr.push(
              <GridActionsCellItem
                icon={<Delete />}
                onClick={() => handleVoidInvoice(params.row)}
                label="Void Invoice"
                showInMenu
              />
            );
          }

          if (user.services.includes("MAINT")) {
            const status = ["PAR", "UNPD"];
            if (status.includes(params.row?.statusCd)) {
              arr.push(
                <GridActionsCellItem
                  icon={<CreditCard />}
                  onClick={() => handlePaymentClick(params.row)}
                  label="Receive Payment"
                  showInMenu
                />
              );
            }
          }
        }

        return arr;
      },
    },
  ];

  const invoiceTypeOptions = [
    { label: "Utility Charge Invoice", value: "UTL" },
    { label: "Service Charge Invoice", value: "SERCH" },
    { label: "Capital Fund Invoice", value: "CAPF" },
  ];

  function handlePaymentClick(invoice) {
    // console.log("SelectedINV", invoice)
    setSelectedInvoice(invoice);
    setPaymentModal(true);
  }

  const handlePaymentClose = () => setPaymentModal(false);

  const savePayment = (data) => {
    data.invoiceId = selectedInvoice && selectedInvoice.id;
    // console.log("sendData", data)
    saveInvoicePayment(data).then((response) => {
      issueResponseMessage(response);
      handlePaymentClose();
      handleSearchClicked();
    });
  };

  return (
    // <Permit roles="BLDIR,ACC" noPermittedServiceMessage={true}>

    <ResponsiveRow
      sx={{
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        padding: 0,
      }}
    >
      {showCreditModal && (
        <CreditNote
          open={showCreditModal}
          onClose={handleCreditModalClose}
          creditNote={creditNote}
          voiditem={selectedInvoice}
          ownershipid={creditMode === "Ownership" ? contractId : null}
          leaseid={creditMode === "Lease" ? contractId : null}
          isVoiding={isVoiding}
          customerId={customerId}
        />
      )}
      {filterInvoices && !showAddInvoiceForm && (
        <TopBarContainer
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
            backgroundColor: "white",
            width: { xs: "100%", lg: "90%" },
            minHeight: "80px",
            margin: "20px auto 20px auto",
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              width: { xs: "100%", lg: "25%" },
              marginBottom: "0px",
              textAlign: { xs: "center", lg: "start" },
            }}
          >
            Invoices
          </Typography>
          <ResponsiveRow
            sx={{
              flexWrap: "nowrap",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "flex-end",
              width: { xs: "100%", lg: "75%" },
            }}
          >
            <Permit services="MAINT">
              <TopBarContainedBrandButton
                onClick={handleAddInvoice}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                }}
                disabled={
                  !["BLDIR", "BLDMG", "CS", "ACC"].includes(user.proxyRole)
                }
              >
                Add Invoice
              </TopBarContainedBrandButton>
            </Permit>
            {task === "Filter" && (
              <>
                <TopBarContainedBrandButton
                  disabled={selectionModel.length < 1}
                  onClick={handleDownloadInvoices}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                    height: "50px",
                  }}
                >
                  {selectionModel.length > 1
                    ? "Download " + selectionModel.length + " Invoices"
                    : "Download Invoice"}
                </TopBarContainedBrandButton>
                <TopBarContainedBrandButton
                  disabled={invoicesList.length < 1}
                  onClick={handleExportInvoices}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                    height: "50px",
                  }}
                >
                  Export Invoices
                </TopBarContainedBrandButton>
                <TopBarContainedCancelButton
                  onClick={handleClearClicked}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                    height: "50px",
                  }}
                >
                  Clear Search
                </TopBarContainedCancelButton>
              </>
            )}
          </ResponsiveRow>
        </TopBarContainer>
      )}

      {task !== "Filter" && (
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "80%" },
            height: "auto",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "4px 4px 12px #aaa",
            padding: "30px 20px",
          }}
          container
        >
          <Typography
            variant="h6"
            color="#037171"
            sx={{
              width: "100%",
              marginBottom: "20px",
              textAlign: "start",
              padding: "0 10px",
            }}
          >
            Invoices Search Form
          </Typography>
          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <OutlinedInput
              label="Invoice Number"
              placeholder={"Invoice Number"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"invoiceNumber"}
              onChange={(event) => {
                const value = event.target.value;
                setInvoiceNumber(value);
              }}
              value={!isEmptyString(invoiceNumber) ? invoiceNumber : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <OutlinedInput
              placeholder={"Invoicee"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"invoicee"}
              onChange={(event) => {
                const value = event.target.value;
                setInvoicee(value);
              }}
              value={!isEmptyString(invoicee) ? invoicee : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Invoice Status</InputLabel>
            <Select
              label="Invoice Status"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"invoiceStatus"}
              value={selectedInvoiceStatus}
              onChange={(event) => {
                let value = event.target.value;
                setSelectedInvoiceStatus(value);
              }}
            >
              {invoiceStatuses && invoiceStatuses.length > 0 ? (
                invoiceStatuses.map(function (invoiceStatus, i) {
                  return (
                    <MenuItem
                      key={invoiceStatus.value}
                      value={invoiceStatus.value}
                    >
                      {invoiceStatus.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Invoice Date From"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"invoiceFromDate"}
                value={
                  isEmptyString(invoiceFromDate) ? null : dayjs(invoiceFromDate)
                }
                isrequired={false}
                onChange={(newDate) => {
                  let date = adjustForTimezone(new Date(newDate));
                  setInvoiceFromDate(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Invoice Date To"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"invoiceToDate"}
                value={
                  isEmptyString(invoiceToDate) ? null : dayjs(invoiceToDate)
                }
                isrequired={false}
                onChange={(newDate) => {
                  let date = adjustForTimezone(new Date(newDate));
                  setInvoiceToDate(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Due Date From"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"dueDateFrom"}
                value={isEmptyString(dueDateFrom) ? null : dayjs(dueDateFrom)}
                disabled={false}
                isrequired={false}
                onChange={(newDate) => {
                  let date = adjustForTimezone(new Date(newDate));
                  setDueDateFrom(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Due Date To"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"dueDateTo"}
                value={isEmptyString(dueDateTo) ? null : dayjs(dueDateTo)}
                isrequired={false}
                onChange={(newDate) => {
                  let date = adjustForTimezone(new Date(newDate));
                  setDueDateTo(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <Permit services="HOA">
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Invoice Type</InputLabel>
              <Select
                label="Invoice Type"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"invoiceType"}
                value={invoiceType}
                onChange={(event) => {
                  let value = event.target.value;
                  setInvoiceType(value);
                }}
              >
                {invoiceTypeOptions && invoiceTypeOptions.length > 0 ? (
                  invoiceTypeOptions.map(function (invoiceType, i) {
                    return (
                      <MenuItem
                        key={invoiceType.value}
                        value={invoiceType.value}
                      >
                        {invoiceType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Permit>

          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              onClick={handleShowAllClicked}
              loading={loading}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Show All Invoices
            </ModalAcceptButton>
            <ModalAcceptButton
              onClick={handleSearchClicked}
              loading={loading}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Search
            </ModalAcceptButton>
            <ModalCancelButton
              onClick={handleClearClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Clear
            </ModalCancelButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}
      {showAddInvoiceForm && (task === "Create" || task === "Edit") && (
        <CreateInvoiceModal
          handleAddInvoiceItems={handleAddInvoiceItems}
          handleCancelClicked={handleCancelClicked}
          saveInvoiceClicked={saveInvoiceClicked}
          showAddInvoiceForm={showAddInvoiceForm}
          setDate={setDate}
          setDueDate={setDueDate}
          setMemo={setMemo}
          setSelectedClient={setSelectedClient}
          setSelectedFacility={setSelectedFacility}
          setSelectedInvoice={setSelectedInvoice}
          setSelectedTenant={setSelectedTenant}
          setSelectedUnit={setSelectedUnit}
          client={client}
          clientList={clientList}
          date={date}
          dueDate={dueDate}
          facilityOptions={facilityOptions}
          invoiceItemsInputs={invoiceItemsInputs}
          memo={memo}
          onRadioChange={onRadioChange}
          radioValue={radioValue}
          selectedClient={selectedClient}
          selectedInvoice={selectedInvoice}
          selectedFacility={selectedFacility}
          selectedTenant={selectedTenant}
          selectedUnit={selectedUnit}
          task={task}
          tenant={tenant}
          tenantList={tenantList}
          unitOptions={unitOptions}
          attachToUnit={attachToUnit}
          assetsList={listOfAssets}
          attachToAsset={attachToAsset}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          complexesList={listOfComplexes}
          setSelectedComplex={setSelectedComplex}
          selectedComplex={selectedComplex}
          attachToComplex={attachToComplex}
          file={file}
          uploadFileChanged={uploadFileChanged}
        />
      )}
      {task === "Filter" && (
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={invoicesList}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          loading={loading}
          height="60vh"
          totalRows={totalInvoices}
          currentSelectionModel={selectionModel}
          handleSelectedRows={setSelectionModel}
        />
      )}
      {/*Download/Export Document via Email... Shared Modal*/}
      {downloadInvoicesModal && (
        <ExportDocumentsModal
          showExportDocumentsModal={downloadInvoicesModal}
          handleExportDocumentsClose={handleCloseDownloadInvoicesModal}
          title={titleText}
          severity={severityText}
          body={bodyText}
          handleExportDocuments={positiveExportDocsAction}
          handleCancel={handleCloseDownloadInvoicesModal}
        />
      )}

      <ReceivePayment
        showPaymentModal={paymentModal}
        handlePaymentClose={handlePaymentClose}
        paymentMode={"maintenanceClient"}
        selectedInvoice={selectedInvoice}
        savePayment={savePayment}
      />
    </ResponsiveRow>
    // </Permit>
  );
}
