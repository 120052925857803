//this shall be the chart of accounts page
import { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import { loadAccountsList, saveAccount } from "../services/accounts.service";
import { isEmptyString, isNullUndefined } from "../util/util";
import {
  getAllAccountCategories,
  getAllAccountSubCategories,
} from "../services/list.service";
import {
  MainContainer,
  TopBarContainedBrandButton,
} from "../constants/component.constants";
import AddAccountsModal from "../components/modals/accounting/chart of accounts/addAccountsModal";
import Permit from "../util/permit";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Accounts() {
  const [name, setName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [category] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [accountsList, setAccountsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accountForm, setAccountForm] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountCategoryList, setAccountCategoryList] = useState([]);
  const [accountSubCategoryList, setAccountSubCategoryList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllAccountCategories().then((data) => setAccountCategoryList(data));
    loadAccountsList(name)
      .then((data) => setAccountsList(data))
      .finally(() => {
        setLoading(false);
      });
  }, [name, category, subCategory]);

  const columns = [
    { headerName: "Account Name", field: "name", width: 400 },
    { headerName: "Code", field: "code", minWidth: 350, flex: 0.875 },
    {
      headerName: "Category",
      field: "accountCategory",
      minWidth: 360,
      flex: 0.9,
      valueFormatter: (row) => {
        return row.value.name;
      },
    },
    {
      headerName: "Sub-Category",
      field: "accountSubCategory",
      minWidth: 360,
      flex: 0.9,
      valueFormatter: (row) => {
        return row.value.name;
      },
    },
  ];

  function handleAccountCategoryChanged(event) {
    let value = event.target.value;
    setSelectedCategory(value);
    let obj = selectedAccount;
    obj.accountCategoryCd = value;
    setSelectedAccount(obj);

    getAllAccountSubCategories(value).then((data) =>
      setAccountSubCategoryList(data)
    );
  }

  function handleAddAccountClicked() {
    let data = {
      id: null,
      name: "",
      accountCategoryCd: "",
      accountSubCategoryCd: "",
    };
    setSelectedAccount(data);
    setAccountForm(true);
  }

  function handleCloseAccForm() {
    setAccountForm(false);
    setSelectedAccount(null);
    setSelectedCategory("");
    setSubCategory("");
    setAccountName("");
    setAccountSubCategoryList([]);
  }

  function validateFormData(sendData) {
    if (isNullUndefined(sendData.name) || isEmptyString(sendData.name)) {
      issueWarnMessage("Please enter the account name");
      return false;
    }
    if (
      isNullUndefined(sendData.accountCategoryCd) ||
      isEmptyString(sendData.accountCategoryCd)
    ) {
      issueWarnMessage("Please select an account category");
      return false;
    }
    if (
      isNullUndefined(sendData.accountSubCategoryCd) ||
      isEmptyString(sendData.accountSubCategoryCd)
    ) {
      issueWarnMessage("Please select an account sub category");
      return false;
    }

    return true;
  }

  function handleSaveAccountClicked() {
    if (validateFormData(selectedAccount)) {
      saveAccount(selectedAccount).then((response) => {
        issueResponseMessage(response);
        handleCloseAccForm();
      });
    }
  }

  return (
    <Permit
      roles="BLDIR,ACC"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role is not permitted to view this page. <br /> Please
            contact your system admin if you feel this is an error.
          </>
        ),
      }}
    >
      <MainContainer>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: { lg: "nowrap", md: "wrap" },
            justifyContent: "space-between",
            width: { xs: "100%", md: "80%" },
            backgroundColor: "white",
            padding: { md: "1vh", xs: "1vh" },
            borderRadius: "1vh",
            margin: "20px auto",
            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xl: "row", xs: "column" },
              justifyContent: { md: "space-between", xs: "center" },
              width: { lg: "40%", md: "50%", sm: "100%" },
            }}
            component="form"
          >
            <FormControl
              fullWidth
              sx={{
                margin: { md: "auto 20px", xs: "10px auto " },
                width: "100%",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }} id="mobileMoneyLabel">
                Search Account Name
              </InputLabel>
              <OutlinedInput
                id="accountNameSearch"
                value={name}
                label={`Search Account Name`}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  let value = event.target.value;
                  setName(value);
                }}
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xl: "row", xs: "column" },
              justifyContent: { md: "space-between", xs: "center" },
              width: { md: "20%", xs: "100%" },
              padding: "10px",
            }}
            component="form"
          >
            <TopBarContainedBrandButton
              variant="contained"
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
              // type="submit"
              onClick={handleAddAccountClicked}
            >
              Add Account
            </TopBarContainedBrandButton>
          </Box>
        </Grid>
        {/*
        <StyledDataGrid
          autoHeight
          rows={accountsList}
          columns={columns}
          pageSize={12}
          loading={loading}
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyOverlay,
          }}
        /> */}

        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={accountsList}
          serverPagination={false}
          loading={loading}
          height="60vh"
          totalRows={accountsList.length}
        />

        <AddAccountsModal
          handleSaveAccountClicked={handleSaveAccountClicked}
          handleCloseAccForm={handleCloseAccForm}
          handleAccountCategoryChanged={handleAccountCategoryChanged}
          accountForm={accountForm}
          setAccountName={setAccountName}
          setSelectedAccount={setSelectedAccount}
          setSubCategory={setSubCategory}
          accountCategoryList={accountCategoryList}
          accountName={accountName}
          accountSubCategoryList={accountSubCategoryList}
          selectedAccount={selectedAccount}
          selectedCategory={selectedCategory}
          subCategory={subCategory}
        />
      </MainContainer>
    </Permit>
  );
}
