import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { personOrgSearch } from "../../services/shared.service";
import { isEmptyArray, isNullUndefined } from "../../util/util";
import { getIdTypes } from "../../services/list.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";

/**
 *Required props are:
 - showPersonOrgModal: True or false to show or hide modal respectively.
 - handlePersonOrgClose: Function to close the modal ie set showTerminateModal to false.
 - handlePersonOrgSelect: Function that handles when searched for user is found and selected.
 - showAddNewUserForm: The state or task that displays form to add a user in the event they are not found upon search.
 - showAddNewOrgForm: The state or task that displays form to add an organisation in the event it is not found upon search.
 * Optional props are:
 - contractType : The contract type is optional and if present can cause special behaviour
 */

export default function PersonOrgSearch(props) {
  const [idTypeSearch, setIdType] = useState("");
  const [idNoSearch, setIdNo] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [individual, setIndividual] = useState(false);
  const [company, setCompany] = useState(false);
  const [noFoundUser, setNoFoundUser] = useState(false);
  const [foundUser, setFoundUser] = useState([]);
  const [idTypeOptions, setIdTypeOptions] = useState([]);

  const [formStage, setFormStage] = useState(1);

  useEffect(() => {
    getIdTypes()
      .then((idTypes) => setIdTypeOptions(idTypes))
      .catch((error) => {
        console.log("getIdTypes Error: ", error);
      });

    return () => {
      setFormStage(1);
    };
  }, []);

  function onTextChange(searchText) {
    const id = searchText.target.id;
    const value = searchText.target.value;
    if (id === "idNoSearch") {
      setIdNo(value);
    }
    if (id === "companySearch") {
      setCompanySearch(value);
    }
  }

  function handleIdTypeChange(event) {
    let value = event.target.value;
    setIdType(value);
  }

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      if (value === "Individual") {
        setIndividual(true);
        setCompany(false);
        setSearchBy(value);
      }
      if (value === "Company") {
        setCompany(true);
        setIndividual(false);
        setSearchBy(value);
      }
    }
  }

  function searchPersonOrg() {
    setFormStage(2);
    let data = {
      searchType: searchBy,
      idType: idTypeSearch,
      idNumber: idNoSearch,
      contractType: isNullUndefined(props.contractType)
        ? null
        : props.contractType,
      organizationName: companySearch,
    };
    personOrgSearch(data)
      .then((response) => {
        setFoundUser(response);
        setNoFoundUser(false);
        if (isEmptyArray(response)) {
          setNoFoundUser(true);
          setFoundUser([]);
          setSearchBy("");
        }
      })
      .catch((error) => {
        console.log("PersonOrg Error: ", error);
      });
  }

  function showAddUserForm() {
    if (individual) {
      props.showAddNewUserForm(idNoSearch, idTypeSearch);
    } else {
      props.showAddNewOrgForm(companySearch);
    }

    setSearchBy("");
    setIndividual(false);
    setCompany(false);
    setNoFoundUser(false);
    setIdNo("");
    setIdType("");
    setCompanySearch("");
    setFormStage(1);
    // props.handlePersonOrgClose?.()
  }

  function selectPersonOrg(result) {
    let obj = result;

    if (individual === true) {
      obj.type = "Individual";
    } else {
      obj.type = "Company";
    }

    setIndividual(false);
    setCompany(false);
    setIdType("");
    setIdNo("");
    setCompanySearch("");
    setFoundUser([]);
    setNoFoundUser(false);
    setFormStage(1);
    props.handlePersonOrgSelect(obj);
  }

  function modalClose() {
    setIndividual(false);
    setCompany(false);
    setIdType("");
    setIdNo("");
    setCompanySearch("");
    setFoundUser([]);
    setNoFoundUser(false);
    setFormStage(1);
    props.handlePersonOrgClose?.();
  }

  useEffect(() => {
    return () => {
      setFormStage(1);
    };
  }, []);

  return (
    <ModalContainerModule
      size="specialPersonOrgSearchModal"
      openModal={props.showPersonOrgModal}
      modalTitle="Individual/Company Search"
      cancel={modalClose}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          width: "100%",
          height: "100%",

          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: 0,
        }}
      >
        {formStage === 1 ? (
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              flexWrap: "nowrap",

              width: "100%",
              height: "100%",

              justifyContent: "flex-start",
              alignItems: "flex-start",

              overflowY: "auto",
              padding: 0,
              marginBottom: "0px",
            }}
          >
            <Alert
              sx={{
                width: "100%",
                height: "auto",
                padding: "10px 20px",
              }}
              severity="info"
            >
              <AlertTitle color="info">
                Please select a user type above.
              </AlertTitle>
              <Typography variant="body2" sx={{ width: "100%" }}>
                Select <strong>Individual</strong> to search for individuals
                by ID type and number and <strong> Company</strong> to
                find companies based on their name
              </Typography>
            </Alert>
            <ResponsiveRow
              component="div"
              className="ui toggle checkbox"
              sx={{
                width: "100%",
                height: "auto",

                flexWrap: "nowrap",
                padding: "10px 20px",
                marginBottom: 0,
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="use-radio-group"
              >
                <FormControlLabel
                  name="radio"
                  control={<Radio value="Individual" onClick={onRadioChange} />}
                  label="Individual"
                />
                <FormControlLabel
                  name="radio"
                  control={<Radio value="Company" onChange={onRadioChange} />}
                  label="Company"
                />
              </RadioGroup>
            </ResponsiveRow>

            <ResponsiveRow
              sx={{
                width: "100%",
                height: individual && company ? "100%" : "100%",

                padding: 0,
                marginBottom: "10px",

                justifyContent: "flex-start",
                alignItems: "flex-start",

                flexWrap: "nowrap",
              }}
            >
              {individual ? (
                <>
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>ID Number</InputLabel>
                    <OutlinedInput
                      label="ID Number"
                      sx={{ width: { xs: "100%", md: "90%" } }}
                      id={"idNoSearch"}
                      value={idNoSearch}
                      onChange={(searchText) => onTextChange(searchText)}
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>ID Type</InputLabel>
                    <Select
                      label="ID Type"
                      sx={{ width: { xs: "100%", md: "90%" } }}
                      id={"idTypeSearch"}
                      value={idTypeSearch}
                      onChange={(event) => handleIdTypeChange(event)}
                    >
                      {idTypeOptions && idTypeOptions.length > 0 ? (
                        idTypeOptions.map(function (idType) {
                          return (
                            <MenuItem key={idType.value} value={idType.value}>
                              {idType.label}
                            </MenuItem>
                          );
                        }, this)
                      ) : (
                        <MenuItem sx={{ width: "100%" }}>
                          No Results Found
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </>
              ) : company ? (
                <FormControl sx={{ width: { xs: "100%", lg: "75%" } }}>
                  <InputLabel>Company Name</InputLabel>
                  <OutlinedInput
                    label="Company Name"
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"companySearch"}
                    value={companySearch}
                    onChange={(searchText) => onTextChange(searchText)}
                  />
                </FormControl>
              ) : null}
            </ResponsiveRow>
            <ResponsiveRow
              sx={{
                // position: "absolute",
                // bottom: 0,
                // left: 0,

                justifyContent: "flex-end",

                alignItems: "flex-end",

                width: "100%",
                height: "auto",

                marginBottom: "10px",
              }}
            >
              <ModalAcceptButton
                onClick={searchPersonOrg}
                disabled={
                  !(
                    (idTypeSearch !== "" && idNoSearch !== "") ||
                    companySearch !== ""
                  )
                }
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                }}
              >
                Search
              </ModalAcceptButton>
              {/* <ModalCancelButton
                onClick={modalClose}
                sx={{ width: { xs: "100%", lg: "200px" } }}
              >
                Cancel
              </ModalCancelButton> */}
            </ResponsiveRow>
          </ResponsiveRow>
        ) : (
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",

              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary.dark"
              textAlign="start"
              sx={{ width: "100%", marginBottom: "10px" }}
            >
              Search Results ({foundUser.length})
            </Typography>
            {foundUser && foundUser.length > 0 && (
              <Typography
                variant="body2"
                textAlign="start"
                sx={{ width: "100%" }}
              >
                Tap to select
              </Typography>
            )}
            <ResponsiveRow
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                flexWrap: { xs: "nowrap", lg: "wrap" },

                width: "100%",
                height: "auto",

                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {foundUser &&
                foundUser.length > 0 &&
                foundUser.map(function (customer, i) {
                  return (
                    <ResponsiveRow
                      key={customer.id}
                      item
                      onClick={() => selectPersonOrg(customer)}
                      sx={{
                        width: "45%",
                        height: "auto",

                        borderRadius: "10px",
                        backgroundColor: "#efefef",

                        "&:hover": {
                          backgroundColor: "#dfdfdf",
                        },
                        margin: "5px",
                        padding: "10px",
                      }}
                    >
                      {customer.name}
                    </ResponsiveRow>
                  );
                })}
            </ResponsiveRow>
            {noFoundUser && (
              <ResponsiveRow
                sx={{
                  flexDirection: "column",
                  flexWrap: "nowrap",

                  width: "100%",
                  height: "auto",

                  justifyContent: "flex-start",
                  alignItems: "flex-start",

                  margin: 0,
                  padding: 0,
                }}
              >
                <Alert
                  sx={{
                    width: "100%",
                    height: "auto",

                    overflow: "hidden",
                    margin: 0,
                  }}
                  severity="error"
                >
                  <AlertTitle color="error">
                    No {individual ? "Individuals" : "Companies"} found
                  </AlertTitle>
                  <Typography sx={{ width: "100%" }}>
                    There were no {individual ? "individuals" : "companies"}{" "}
                    found with that {individual ? "ID number" : "Company Name"}.
                  </Typography>
                  {(props.contractType && props.contractType === "subscription")?(
                      <Typography sx={{ width: "100%" }}>
                        The {individual ? "individual " : "company "} you are searching for may already be a subscriber.
                      </Typography>
                  ):null}
                  <Typography sx={{ width: "100%" }}>
                    Would you like to create a new{" "}
                    {individual ? "Individual" : "Company"}?
                  </Typography>
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      justifyContent: "flex-end",
                      height: "auto",
                    }}
                  >
                    <ModalAcceptButton
                      onClick={showAddUserForm}
                      sx={{ width: { xs: "100%", lg: "100px" } }}
                    >
                      Yes
                    </ModalAcceptButton>
                    <ModalCancelButton
                      onClick={() => setFormStage(1)}
                      sx={{ width: { xs: "100%", lg: "100px" } }}
                    >
                      No
                    </ModalCancelButton>
                  </ResponsiveRow>
                </Alert>
              </ResponsiveRow>
            )}
          </ResponsiveRow>
        )}
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
