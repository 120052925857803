import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {
  getAllUnitOptions,
  getAssetOptions,
  getFacilityOptions,
  getVATAccount,
  getVendorExpenseAccountOptions,
  getVendorOptions, getWithholdingTaxAccount,
} from "../../services/list.service";
import {
  adjustForTimezone, formatCurrency,
  isEmptyString,
  isNullUndefined,
  titleCase,
  tryParseFloat,
} from "../../util/util";
import { ModalAcceptButton } from "../../constants/component.constants";
import Delete from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";
import { issueWarnMessage } from "../../actions/message";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function CreateBill(props) {
  const appMessage = useSelector((state) => state.message);
  const user = useSelector((state) => state.auth.user);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [vendorExpenseAccountOptions, setVendorExpenseAccountOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [memo, setMemo] = useState("")
  const [vendorRefNo, setVendorRefNo] = useState("")
  const [isAsset, setIsAsset] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [asset, setAsset] = useState("");
  const [completedForm, setCompletedForm] = useState(false);
  const [selectedBill, setSelectedBill] = useState(props.selectedBill);
  const [billItems, setBillItems] = useState([]);
  const [billItemTableRows, setBillItemTableRows] = useState("");
  const [billItemTotal, setBillItemTotal] = useState(0);
  const VATAccountId = useRef("");
  const withholdingTaxAccountId = useRef("");

  useEffect(() => {
  if (user.proxyFacility != null) {
    setPropertyId(user.proxyFacility)
  }
  }, [
    user.proxyFacility
  ]);

  const handleSave = () => {
    let tmp = selectedBill;
    if (!isNullUndefined(propertyId) && !isEmptyString(propertyId)) {
      tmp.facility.id = propertyId;
    }
    if (!isNullUndefined(asset) && !isEmptyString(asset)) {
      tmp.asset.id = asset;
    }
    if (!isNullUndefined(unitId) && !isEmptyString(unitId)) {
      tmp.unit.id = unitId;
    }
    tmp.vendorId = vendorId;
    tmp.description = memo;
    tmp.vendorRefNo = vendorRefNo;
    tmp.dueDate = dueDate;
    tmp.billItems = billItems;
    setSelectedBill(tmp);
    saveBill();
  };

  const saveBill = () => {
    props.saveBill(selectedBill);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const handleItemRemove = (index, billItems, vendorExpenseAccountOptions) => {
    let tmp = billItems;
    let detailItem = tmp[index];
    if (tmp[index].applyVAT) {
      handleVATItemRemove(detailItem, billItems, vendorExpenseAccountOptions);
      let tmp1 = billItems;
      tmp1.splice(index, 1);
      setBillItems(tmp1);
    } else {
      tmp.splice(index, 1);
      setBillItems(tmp);
    }
    populateBillItems(tmp,vendorExpenseAccountOptions);
  };

  const handleVATItemRemove = (detailItem, billItems, vendorExpenseAccountOptions) => {
    let vatAmount = getVATAmount(detailItem.amount);
    let items = billItems;
    let tmp = billItems;
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].amount === vatAmount &&
        items[i].notes.includes(detailItem.notes)
      ) {
        tmp.splice(i, 1);
        setBillItems(tmp);
      }
    }
    populateBillItems(tmp, vendorExpenseAccountOptions);
  };

  const handleVendorChange = (vendorId) => {
    setVendorId(vendorId);
    setBillItems([]); // Since we have changed the vendor, first Remove previous items for other vendor. To Avoid mix up because a vendor has  expense acc they are linked to.
  };

  const onBillItemTextChange = (event, index, billItems, vendorExpenseAccountOptions) => {
    const id = event.target.id;
    let value = event.target.value;
    let tmp = billItems;
    if (id === "amount" + index) {
      value = tryParseFloat(event.target.value, 0);
      tmp[index].amount = value;
      setBillItems(tmp);
      populateBillItems(tmp, vendorExpenseAccountOptions);
    }

    if (id === "notes" + index) {
      tmp[index].notes = value;
      setBillItems(tmp);
      populateBillItems(tmp, vendorExpenseAccountOptions);
    }
  };

  const handleVendorAccountChanged = (event, index, billItems, vendorExpenseAccountOptions) => {
    let tmp = billItems;
    let value = event.target.value
    tmp[index].accountId = value
    if(withholdingTaxAccountId.current === value){
      let amount = tryParseFloat(tmp[index].amount, 0);
      tmp[index].isWithholdingTax = true
      tmp[index].amount = amount
      tmp[index].notes = "Withholding Tax for Bill #" + selectedBill.referenceNumber
    }
    setBillItems(tmp);
    populateBillItems(tmp, vendorExpenseAccountOptions);
  };

  const handleBillItemAdd = () => {
    if (isNullUndefined(vendorId) || isEmptyString(vendorId)) {
      issueWarnMessage("You must select a vendor before adding bill items");
    } else {
      if (billItems.filter((x) => x.accountId === withholdingTaxAccountId.current).length > 1) {
        issueWarnMessage("There should be only one withholding tax line item.")
      }else{
        let tmp = billItems;
        tmp.push({
          id: null,
          accountId: "",
          notes: "",
          amount: "",
          applyVAT: false,
          isTax: false,
        });
        setBillItems(tmp);
        populateBillItems(tmp, vendorExpenseAccountOptions);
      }
    }
  };

  const handleBillTaxableToggle = (event, index, billItems, vendorExpenseAccountOptions) => {
    let tmp = billItems;
    let detailItem = tmp[index];
    if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
      issueWarnMessage("You must enter an amount before applying VAT");
    } else {
      if (event.target.checked) {
        let vatNotes = "VAT (16%) - " + detailItem.notes;
        let vatAmount = getVATAmount(detailItem.amount);
        tmp.push({
          id: null,
          accountId: VATAccountId.current,
          notes: vatNotes,
          amount: vatAmount,
          applyVAT: false,
          isTax: true,
        });
        detailItem.applyVAT = true;
      } else {
        findAndRemoveBillVAT(detailItem, billItems, vendorExpenseAccountOptions);
        detailItem.applyVAT = false;
      }
    }

    setBillItems(tmp);
    populateBillItems(tmp, vendorExpenseAccountOptions);
  };

  const findAndRemoveBillVAT = (detailItem, billItems, vendorExpenseAccountOptions) => {
    let vatAmount = getVATAmount(detailItem.amount);
    let items = billItems;
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].amount === vatAmount &&
        items[i].notes.includes(detailItem.notes)
      ) {
        handleItemRemove(i, billItems, vendorExpenseAccountOptions);
      }
    }
  };

  const getVATAmount = (amount) => {
    if (isNaN(amount)) {
      issueWarnMessage("Only numeric amounts are allowed for VAT calculation");
    } else {
      return (amount * 0.16).toFixed(2);
    }
  };

  const populateBillItems = useCallback((billItems, vendorExpenseAccountOptions) => {
    let rows = "";
    let billTotal = 0
    setBillItems(billItems);
    billItems &&
      billItems.length > 0 &&
      (rows = billItems.map((billItem, i) => {
        let isWithholding = billItem.accountId === withholdingTaxAccountId.current
        if(!isWithholding){
          billTotal += tryParseFloat(billItem.amount, 0)
        }
        return (
          <TableRow key={i}>
            {vendorExpenseAccountOptions &&
              vendorExpenseAccountOptions.length > 0 && (
                <TableCell>
                  <Select
                    sx={{ width: "90%" }}
                    id={"account" + i}
                    value={billItems[i].accountId}
                    disabled={billItems[i].isTax}
                    onChange={(event) => handleVendorAccountChanged(event, i, billItems, vendorExpenseAccountOptions)}
                  >
                    {vendorExpenseAccountOptions &&
                    vendorExpenseAccountOptions.length > 0 ? (
                      vendorExpenseAccountOptions.map((account, i) => {
                        let disabled = false
                        for (let bill of billItems) {
                          if ((account.value === withholdingTaxAccountId.current) && (bill.accountId === withholdingTaxAccountId.current)) {
                            disabled = true
                          }
                        }
                        return (
                          <MenuItem
                              key={i}
                              disabled={disabled}
                              value={account.value}>
                            {account.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}

            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"notes" + i}
                placeholder={"Memo"}
                value={billItems[i].notes}
                disabled={billItems[i].isTax}
                onChange={(event) => onBillItemTextChange(event, i, billItems, vendorExpenseAccountOptions)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"amount" + i}
                placeholder={"Amount"}
                value={billItems[i].amount}
                disabled={billItems[i].isTax}
                onChange={(event) => onBillItemTextChange(event, i, billItems, vendorExpenseAccountOptions)}
              />
            </TableCell>

            <TableCell>
              {!billItems[i].isTax && !billItems[i].isWithholdingTax && (
                <Checkbox
                  checked={billItems[i].applyVAT}
                  disabled={
                    isNullUndefined(billItems[i].amount) ||
                    isEmptyString(billItems[i].amount) ||
                    billItems[i].amount < 1 ||
                    billItems[i].notes.length < 1
                  }
                  onChange={(event) => handleBillTaxableToggle(event, i, billItems, vendorExpenseAccountOptions)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </TableCell>
            <TableCell>
              {!billItems[i].isTax && (
                <IconButton
                  onClick={() => handleItemRemove(i, billItems, vendorExpenseAccountOptions)}
                  sx={{
                    marginLeft: "-25px",
                    color: "red",
                    fontSize: "5rem",
                    textAlign: "center",
                    fontWeight: 800,
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        );
      }, this));
    setBillItemTableRows(rows);
    setBillItemTotal(billTotal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPropertyForm = () => {
    return (
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <FormControl
          sx={{
            width: { xs: "100%", lg: "50%" },
            margin: "10px",
            padding: "auto 10px",
          }}
        >
          <InputLabel id="bill-payee-label">Property</InputLabel>
          {facilityOptions && facilityOptions.length > 0 && (
            <Select
              labelId="bill-property-label"
              id="bill-property"
              value={propertyId}
              label="Property"
              disabled={!isNullUndefined(propertyId) && !isEmptyString(propertyId)}
              onChange={(e) => setPropertyId(e.target.value)}
              sx={{ width: { xs: "100%", lg: "90%" } }}
            >
              <MenuItem value={""} disabled>
                Select Facility
              </MenuItem>
              {facilityOptions && facilityOptions.length > 0 ? (
                facilityOptions.map((facility, i) => {
                  return (
                    <MenuItem key={i} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          )}
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "50%" },
            margin: "auto 10px",
            padding: "auto 10px",
          }}
        >
          <InputLabel id="bill-payee-label">Unit</InputLabel>
          <Select
            labelId="bill-unit"
            id="bill-unit"
            value={unitId}
            label="Pay To"
            onChange={(e) => setUnitId(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          >
            <MenuItem disabled value={""}>
              Select Unit
            </MenuItem>

            {unitOptions && unitOptions.length > 0 ? (
              unitOptions.map((unit, i) => {
                return (
                  <MenuItem key={i} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
            {unitOptions && unitOptions.length < 1 && (
              <MenuItem disabled value={""}>
                No results found
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </ResponsiveRow>
    );
  };
  useEffect(() => {
    if (
      vendorId !== "" &&
      dueDate !== "" &&
      memo !== "" &&
      billItems.length > 0
    ) {
      !isAsset
        ? propertyId === "" && unitId === ""
          ? setCompletedForm(false)
          : setCompletedForm(true)
        : asset === ""
        ? setCompletedForm(false)
        : setCompletedForm(true);
    }

    if (!isNullUndefined(propertyId)) {
      getAllUnitOptions(propertyId).then((unitOptions) => {
        setUnitOptions(unitOptions);
      });
    }

    return () => {
      setCompletedForm(false);
    };
  }, [
    dueDate,
    isAsset,
    asset,
    memo,
    vendorId,
    propertyId,
    unitId,
    billItems.length,
  ]);

  useEffect(() => {
    setSelectedBill(props.selectedBill);
    getVendorOptions().then((vendorOptions) => {
      setVendorOptions(vendorOptions);
    });

    getFacilityOptions().then((facilityOptions) =>
      setFacilityOptions(facilityOptions)
    );

    getAssetOptions().then((assetOptions) => {
      setAssetOptions(assetOptions);
    });

    getVATAccount().then((account) => {
      VATAccountId.current = account.id;
    });

    getWithholdingTaxAccount().then((account) => {
      withholdingTaxAccountId.current = account.id;
    });
  }, [props.selectedBill]);

  useEffect(() => {
    if (!isEmptyString(vendorId)) {
      getVATAccount().then((account) => {
        VATAccountId.current = account.id;

      });
      getWithholdingTaxAccount().then((account) => {
        withholdingTaxAccountId.current = account.id;
      });
      getVendorExpenseAccountOptions(vendorId)
        .then((vendorExpenseAccountOptions) => {
          setVendorExpenseAccountOptions(vendorExpenseAccountOptions);
          setVendorId(vendorId);
          populateBillItems(billItems, vendorExpenseAccountOptions);
        })
    }
  }, [vendorId]);
  const renderAssetForm = () => {
    return (
      <Fragment>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "50%" },
            margin: "10px",
            padding: "auto 10px",
          }}
        >
          <InputLabel id="bill-asset-label">Asset</InputLabel>
          <Select
            labelId="bill-asset-label"
            id="bill-asset"
            value={asset}
            label="Asset"
            onChange={(e) => setAsset(e.target.value)}
          >
            <MenuItem value={""} disabled>
              Select Asset
            </MenuItem>
            {assetOptions && assetOptions.length > 0 ? (
              assetOptions.map((asset, i) => {
                return (
                  <MenuItem key={i} value={asset.value}>
                    {asset.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
      </Fragment>
    );
  };

  return (
    <ModalContainerModule
      size="specialCreateBill"
      accept={handleSave}
      cancel={handleCancel}
      openModal={props.openModal}
      modalTitle="Create New Bill"
      acceptButtonText="Save"
      acceptDisabled={!completedForm}
    >
      <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
        Bill Details
      </Typography>
      <ResponsiveRow
        sx={{
          alignItems: "flex-start",
          justifyContent: { xs: "center", lg: "space-between" },
          width: "100%",
          height: "auto",
          padding: "0 auto 10px auto ",
        }}
      >
        <FormControl
          sx={{
            width: { xs: "100%", lg: "30%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel id="bill-payee-label">Pay To</InputLabel>
          {vendorOptions && vendorOptions.length > 0 && (
            <Select
              labelId="bill-payee-label"
              id="bill-payee"
              value={vendorId}
              label="Pay To"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              onChange={(e) => handleVendorChange(e.target.value)}
            >
              {vendorOptions && vendorOptions.length > 0 ? (
                vendorOptions.map((vendor, i) => {
                  return (
                    <MenuItem key={i} value={vendor.value}>
                      {vendor.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          )}
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "30%" },
            marginBottom: "10px",
          }}
        >
          {" "}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              id={props.componentID}
              label={props.labelText}
              sx={{
                margin: "auto",
                width: {
                  xs: "100%",
                  lg: "90%",
                },
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
              openTo="day"
              InputLabelProps={{
                shrink: true,
              }}
              inputFormat="dd/MMM/yyyy"
              onChange={(new_value) => setDueDate(adjustForTimezone(new Date(new_value)))}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
            sx={{
              width: { xs: "100%", lg: "30%" },
              marginBottom: "10px",
            }}
        >
          <InputLabel id="bill-vendorRefNo-label">Vendor Ref No.</InputLabel>
          <OutlinedInput
              label="Vendor Ref No."
              labelId="bill-vendorRefNo-label"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"vendorRefNo"}
              value={vendorRefNo}
              onChange={(event) => setVendorRefNo(event.target.value)}
              inputProps={
                <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
              }
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel id="bill-memo-label">Memo</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", lg: "95%" } }}
            labelId="bill-memo-label"
            id="bill-memo"
            value={memo}
            label="Memo"
            onChange={(e) => setMemo(e.target.value)}
            multiline
            rows={4}
          />
        </FormControl>
      </ResponsiveRow>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
        Bill To
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: "flex-start",
          justifyContent: { xs: "space-between", lg: "flex-start" },
          width: "100%",
          padding: "0 10px",
        }}
      >
        <ResponsiveRow
          sx={{
            alignItems: "center",
            justifyContent: { xs: "space-between", lg: "flex-start" },
            width: "100%",
            height: "auto",
          }}
          component={FormGroup}
        >
          <Typography
            id="modal-modal-description"
            sx={{
              margin: "auto 2px",
              fontWeight: 600,
              color: "#037171",
              fontSize: ".9rem",
            }}
          >
            Property / Unit
          </Typography>
          <FormControlLabel
            control={<Switch value={isAsset} />}
            onChange={() => {
              setIsAsset(!isAsset);
            }}
            label=""
            sx={{ margin: "auto 5px" }}
          />

          <Typography
            id="modal-modal-description"
            sx={{
              margin: "auto 2px",
              fontWeight: 600,
              color: "#037171",
              fontSize: ".9rem",
            }}
          >
            Asset
          </Typography>
        </ResponsiveRow>
      </Box>
      <ResponsiveRow
        sx={{
          height: "auto",
          alignItems: "flex-start",
          justifyContent: { xs: "space-between", lg: "flex-start" },
          width: "100%",
          padding: "10px",
        }}
      >
        {isAsset ? renderAssetForm() : renderPropertyForm()}
      </ResponsiveRow>

      {appMessage.display && (
        <Alert severity={appMessage.type}>
          <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
          {appMessage.message}
        </Alert>
      )}
      <ResponsiveRow
        variant=""
        sx={{ height: "auto", width: "100%", padding: "10px" }}
      >
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h6"
          component="h6"
        >
          Bill Items (Transactions)
        </Typography>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ width: "25%", color: "#037171", fontSize: "1rem" }}
                >
                  Expense Account
                </TableCell>
                <TableCell
                  sx={{ width: "35%", color: "#037171", fontSize: "1rem" }}
                >
                  Memo
                </TableCell>
                <TableCell
                  sx={{ width: "20%", color: "#037171", fontSize: "1rem" }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{ width: "15%", color: "#037171", fontSize: "1rem" }}
                >
                  Apply VAT (16%)
                </TableCell>
                <TableCell
                  sx={{ width: "5%", color: "#037171", fontSize: "1rem" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{billItemTableRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
            sx={{
              width: "50%",
              justifyContent: { xs: "center", lg: "flex-start" },
            }}
        >
          <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h6"
              component="h6"
          >
            Total: {formatCurrency(billItemTotal)}
          </Typography>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "50%",
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
        >
          <ModalAcceptButton
            variant="contained"
            type="submit"
            onClick={handleBillItemAdd}
            disabled={isNullUndefined(vendorId) || isEmptyString(vendorId)}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Add Row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
