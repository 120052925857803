/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Grid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {useEffect, useMemo, useState} from "react";
import {Archive, Build, Cancel, Edit, Visibility} from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {
    archiveMeter,
    bulkEditMeter,
    deleteReading, downloadMeterReadingsBulkUploadTemplate,
    editReadings,
    exportUtilities,
    getBulkWaterMeterTypes,
    getMeterReadingPeriods,
    getMeterReadings,
    getMeterRevenue,
    getMeters,
    getMeterTypes,
    getTotalMeters,
    invoiceReading,
    reportFixedMeter,
    saveNewMeter,
    saveReading,
    uploadReadings,
} from "../services/meters.service";
import {
    adjustForTimezone,
    dateDifference,
    formatDate,
    isArray,
    isEmptyArray,
    isEmptyString,
    isNullUndefined,
    tryParseFloat,
} from "../util/util";
import Permit from "../util/permit";
import {useSelector} from "react-redux";
import {getAllUnitOptions, getComplexOptions, getFacilityOptions, getRateType,} from "../services/list.service";
import {issueErrorMessage, issueResponseMessage, issueSuccessMessage, issueWarnMessage,} from "../actions/message";
import {
    AddContainer,
    ColumnContainer,
    InnerModal,
    MainContainer,
    TopBarContainedBrandButton,
    TopBarContainer,
} from "../constants/component.constants";
import TransactionHistory from "../components/transactionHistory";
import {ResponsiveRow} from "../constants/layout.constants";
import AddMeterModal from "../components/modals/rentals/meters/addMeterModal";
import ViewMeterReadingsModal from "../components/modals/rentals/meters/viewMeterReadingsModal";
import AddMeterReadingsModal from "../components/modals/rentals/meters/addMeterReadingsModal";
import EditMeterReadingsModal from "../components/modals/rentals/meters/editMeterReadingsModal";
import ArchiveMeterModal from "../components/modals/rentals/meters/archiveMeterModal";
import UploadMeterReadingModal from "../components/modals/rentals/meters/uploadMeterReadingModal";
import RepairMeterModal from "../components/modals/rentals/meters/repairMeterModal";
import ExportMeterUtilitiesModal from "../components/modals/rentals/meters/exportMeterUtilitiesModal";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Meters(factory, deps) {
    const [EditDiv, setEditDiv] = useState(false);
    const [AddDiv, setAddDiv] = useState(false);
    const [ViewDiv, setViewDiv] = useState(false);
    const [BulkEdit, setBulkEdit] = useState(false);
    const [Blwtr, setBlwtr] = useState(false);
    const [ReadingsModal, setReadingsModal] = useState(false);
    const [ArchiveModal, setArchiveModal] = useState(false);
    const [RepairModal, setRepairModal] = useState(false);
    const [UploadReadingsModal, setUploadReadingsModal] = useState(false);
    const [ExportUtilitiesModal, setExportUtilitiesModal] = useState(false);
    const [complex, setComplex] = useState(false);
    const [property, setProperty] = useState(false);
    const [addReading, setAddReading] = useState(false);
    const [editReading, setEditReading] = useState(false);
    const [invoiceTenant, setInvoiceTenant] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [meterSearchName, setSearchMeterName] = useState([]);
    const [rows, setRows] = useState([]);
    const [meterReadingPeriods, setMeterReadingPeriods] = useState([]);
    const [meterTypes, setMeterTypes] = useState([]);
    const [bulkWaterMeterTypes, setBulkWaterMeterTypes] = useState([]);
    const [facilityOptions, setFacilityOptions] = useState([]);
    const [complexOptions, setComplexOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [rateTypeOptions, setRateTypeOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [meterItems, setMeterItems] = useState([]);
    const [meterReadings, setReadings] = useState([]);
    const [tieredItemsTableRows, setTieredItemsTableRows] = useState("");
    const [tieredItemsTotal, setTieredItemsTotal] = useState(0);
    const [selectedMeter, setselectedMeter] = useState(null);
    const [invoiced, setInvoiced] = useState(false);
    const [selectedMeters, setSelectedMeters] = useState(null);
    const [selectedReading, setselectedReading] = useState(null);
    const [totalMeters, setTotalMeters] = useState(0);
    const [meterName, setMeterName] = useState("");
    const [meterTypeCode, setMeterTypeCode] = useState("");
    const [rateType, setRateType] = useState("");
    const [facilityId, setFacilityId] = useState("");
    const [complexId, setComplexId] = useState("");
    const [unitId, setUnitId] = useState("");
    const [rate, setRate] = useState("");
    const [previousReading, setPreviousReading] = useState("");
    const [nextReading, setNextReading] = useState("");
    const [readingDate, setReadingDate] = useState("");
    const [meterReadingAmount, setMeterReadingAmount] = useState("");
    const [editedMeterReadingAmount, setEditedMeterReadingAmount] = useState("");
    const [standingCharge, setStandingCharge] = useState("");
    const [readingPeriod, setReadingPeriod] = useState([]);
    const [sortedReadings, setSortedReadings] = useState([]);
    const [invoiceLastReading, setInvoiceLastReading] = useState(false);
    const [includeImages, setincludeImages] = useState(false);
    const [bulkFile, setBulkFile] = useState(null);
    const [bulkFileProgress, setBulkFileProgress] = useState(0);
    const [exportFromDate, setExportFromDate] = useState("");
    const [exportToDate, setExportToDate] = useState("");
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const [bulkEditMetersType, setBulkEditMetersType] = useState("");
    const [isMeterTaxed, setIsMeterTaxed] = useState(false)
    const [file, setFile] = useState([]);
    const [fileProgress] = useState(0);

    const handleArchiveClose = () => {
        setInvoiceLastReading("");
        setArchiveModal(false);
    }

    const handleAddMeterClose = () => {
        setAddDiv(false)
        setEditDiv(false)
        setBulkEdit(false)
        setSelectedMeters(null)
        setRowSelectionModel([])
        setProperty(false)
        setComplex(false)
        setInvoiceTenant(false)
        setInvoiced(false)
        setItems([])
        setBulkEditMetersType('')
        setIsMeterTaxed(false)
    };

    useEffect(() => {
        setLoading(true);
        getMeters(meterSearchName, paginationModel.page, paginationModel.pageSize)
            .then((meters) => setRows(meters))
            .finally(() => {
                setLoading(false);
            });

        getTotalMeters(meterSearchName).then((meters) =>
            setTotalMeters(meters.totalMeters)
        );

        getMeterReadingPeriods().then((meterReadingPeriods) =>
            setMeterReadingPeriods(meterReadingPeriods)
        );

        getMeterTypes().then((meterTypes) => setMeterTypes(meterTypes));

        getBulkWaterMeterTypes().then((bulkWaterMeterTypes) =>
            setBulkWaterMeterTypes(bulkWaterMeterTypes)
        );

        getFacilityOptions().then((facilityOptions) =>
            setFacilityOptions(facilityOptions)
        );

        getComplexOptions().then((complexOptions) =>
            setComplexOptions(complexOptions)
        );

        getRateType().then((rateTypeOptions) =>
            setRateTypeOptions(rateTypeOptions)
        );
    }, [
        meterSearchName,
        paginationModel.pageSize,
        paginationModel.page,
        user.proxyFacility,
    ]);

    useEffect(() => {
        if (!isNullUndefined(selectedMeter) && !isEmptyString(selectedMeter.id)) {
            getAllUnitOptions(selectedMeter.facilityId).then((unitOptions) =>
                setUnitOptions(unitOptions)
            );

            getMeterRevenue(selectedMeter.id).then((revenue) => setRevenue(revenue));

            getMeterReadings(selectedMeter.id).then((readings) =>
                setReadings(readings)
            );
        }
    }, [selectedMeter]);

    useEffect(() => {
        if (!isNullUndefined(selectedMeter)) {
            getAllUnitOptions(selectedMeter.facilityId).then((unitOptions) =>
                setUnitOptions(unitOptions)
            );
        }
    }, [facilityId]);

    useEffect(() => {
        populateTieredItems();
    }, [meterItems]);

    function handleReadingsClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setReadingsModal(true);
    }

    function handleArchiveClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setArchiveModal(true);
    }

    function handleAddMeterClick() {
        setAddDiv(true);
        let data = {
            complexId: "",
            facilityId: "",
            id: "",
            invoiced: false,
            meterTypeCode: "",
            name: "",
            rate: "",
            rateTypeCode: "",
            readingFrequency: "",
            standingCharge: "",
            unitId: "",
            readings: [],
            rateArray: [],
            lastMeterReading: "",
            isMeterTaxed: false,
        };
        setselectedMeter(data);
    }

    function handleAddBulkMeterClick() {
        setAddDiv(true);
        let facilityId = null;
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility;
        }
        setBlwtr(true);
        let data = {
            complexId: "",
            facilityId: facilityId,
            id: "",
            invoiced: false,
            meterTypeCode: "",
            name: "",
            rate: "",
            rateTypeCode: "FIX",
            readingFrequency: "DAY",
            standingCharge: 0,
            unitId: "",
            readings: [],
            rateArray: [],
            lastMeterReading: "",
            isMeterTaxed: false
        };
        setselectedMeter(data);
    }

    function handleEditClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setMeterItems(selectedMeter.rates)
        setItems(selectedMeter.rates)
        if (!Blwtr) {
            if (!isNullUndefined(selectedMeter.facilityId)) {
                setProperty(true);
            } else {
                setComplex(true);
            }
            if (!isNullUndefined(selectedMeter.unitId) && !isNullUndefined(selectedMeter.invoiced)) {
                setInvoiceTenant(true);
                setInvoiced(selectedMeter && selectedMeter.invoiced)
            }
            if(!isNullUndefined(selectedMeter)) {
                setIsMeterTaxed(selectedMeter.isMeterTaxed)
            }
        }
        setAddDiv(true);
        setEditDiv(true);
        setBulkEdit(false);
    }

    function checkMeterTypes(metersToEdit) {
        let meterTypes = []
        const meterCodes = ["EU", "WU", "OTH", "PEU"]
        metersToEdit.forEach((item) => {
            meterTypes.push(item.meterTypeCode)
        })
        if (!isEmptyArray(meterTypes)) {
            if (meterTypes.every((val, i, arr) => val === arr[0])) {
                //ensures all items of this array are the same
                setBulkEditMetersType(meterTypes[0])
                if (meterCodes.includes(meterTypes[0])) {
                    setProperty(true)
                }
                return true
            } else {
                issueWarnMessage("Meter Type for bulk edited meters must be the same!");
                return false
            }
        }
    }

    function handleBulkEditClick() {
        let metersToEdit = [];
        rowSelectionModel.forEach((value) => {
            rows.forEach((meter) => {
                if (meter.id === value) {
                    metersToEdit.push(meter);
                }
            });
        });

        if (checkMeterTypes(metersToEdit)) {
            //Only allow Bulk Edit for meters of the same type
            setSelectedMeters(metersToEdit);
            setEditDiv(true);
            setAddDiv(true);
            setBulkEdit(true);
            let rateTypeCode = "";
            let readingFrequency = "";
            if (user.services.includes("BLWTR")) {
                rateTypeCode = "FIX";
                readingFrequency = "DAY";
            }

            let data = {
                invoiced: false,
                rate: "",
                meterTypeCode: "",
                rateTypeCode: rateTypeCode,
                readingFrequency: readingFrequency,
                standingCharge: "",
                readings: [],
                rateArray: [],
                isMeterTaxed: false,
            };
            setselectedMeter(data);
        }
    }

    useEffect(() => {
        if (BulkEdit) {
            let obj = selectedMeter
            obj.meterTypeCode = bulkEditMetersType
            setselectedMeter(obj)
        }
    }, [bulkEditMetersType])

    function handleViewClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setViewDiv(true);
        getPrevious(selectedMeter);
    }

    function handleRepairClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setRepairModal(true);
    }

    function saveMeter() {
        let obj = selectedMeter;
        obj.rateArray = items;
        setselectedMeter(obj);

        let data = {
            meterNode: selectedMeter,
            rateArray: selectedMeter.rateArray,
        };
        if (validateMeterForm()) {
            saveNewMeter(data).then((response) => {
                issueResponseMessage(response);
                getMeters(
                    meterSearchName,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meters) => setRows(meters));
                handleAddMeterClose();
            });
        }
    }

    function archiveSelectedMeter() {
        let data = {
            invoiceLastReading: invoiceLastReading,
            selectedMeter: selectedMeter,
        };
        archiveMeter(data).then((response) => {
            issueResponseMessage(response);
            getMeters(
                meterSearchName,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meters) => setRows(meters));
            setArchiveModal(false);
            setInvoiceLastReading(false);
        });
    }

    function meterFixed() {
        let data = {
            id: selectedMeter.id,
        };
        reportFixedMeter(data).then((response) => {
            issueResponseMessage(response);
            getMeters(
                meterSearchName,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meters) => setRows(meters));
            setRepairModal(false);
        });
    }

    function invoice(reading) {
        let data = {
            meterReadingId: reading.id,
        };
        invoiceReading(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.reading)) {
                getMeters(
                    meterSearchName,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meters) => setRows(meters));
            }
        });
    }

    function deleteSelected(reading) {
        let data = {
            meterReadingId: reading.id,
        };
        deleteReading(data).then((response) => {
            issueResponseMessage(response);
            getMeters(
                meterSearchName,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meters) => setRows(meters));
            setReadingsModal(false);
            setConfirmDelete(false);
        });
    }

    function addMeterReading() {
        let amount = tryParseFloat(meterReadingAmount, 0)
        if (selectedMeter.lastMeterReading > amount) {
            issueWarnMessage(
                "Current reading cannot be less than previous meter reading."
            );
            return false;
        }

        let formData = new FormData()
        if(file){
            formData.append("readingImage", file)
        }
        formData.append('meterId', selectedMeter.id)
        formData.append('amount', meterReadingAmount)
        formData.append('date', readingDate)
        formData.append('invoiceReading', invoiceLastReading)

        saveReading(formData).then((response) => {
            issueResponseMessage(response);
            getMeters(
                meterSearchName,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meters) => setRows(meters));
            setAddReading(false);
            setReadingsModal(false);
            setInvoiceLastReading(false);
            setReadingDate("");
            setMeterReadingAmount("");
            setFile(null)
        });
    }

    function editMeterReading() {
        if (
            isEmptyString(editedMeterReadingAmount) ||
            isNullUndefined(editedMeterReadingAmount)
        ) {
            issueWarnMessage("Please enter a valid value!");
            return false;
        }

        if (previousReading.amount > editedMeterReadingAmount) {
            issueWarnMessage(
                "Current reading cannot be less than previous meter reading " +
                previousReading.amount
            );
            return false;
        }
        if (editedMeterReadingAmount > nextReading.amount) {
            issueWarnMessage(
                "Current reading cannot be greater than next meter reading " +
                nextReading.amount
            );
            return false;
        }

        let formData = new FormData()
        if(file){
            formData.append("readingImage", file)
        }

        formData.append("readingId", selectedReading.id)
        formData.append("amount", editedMeterReadingAmount)

        editReadings(formData).then((response) => {
            issueResponseMessage(response);
            getMeters(
                meterSearchName,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meters) => setRows(meters));
            setEditReading(false);
            setReadingsModal(false);
            setEditedMeterReadingAmount("");
            setFile(null)
        });
    }

    const validate = () => {
        if (isEmptyString(selectedMeter && selectedMeter.rateTypeCode)) {
            issueWarnMessage(
                "Meter Rate Type is a required field!"
            );
            return false
        }

        if (isEmptyString(selectedMeter && selectedMeter.readingFrequency)) {
            issueWarnMessage(
                "Meter Reading Frequency is a required field!"
            );
            return false
        }

        return true
    }

    function bulkEditMeters() {
        if (isNullUndefined(selectedMeters) || selectedMeters.length < 1) {
            issueWarnMessage(
                "Could not determine the bulk edit items. Please refresh the page and try again."
            );
            return;
        }

        let obj = selectedMeter;
        obj.rateArray = items;
        setselectedMeter(obj);

        let data = {
            bulkEditIds: rowSelectionModel,
            bulkEditMeters: selectedMeter,
        };

        if (validate()) {
            bulkEditMeter(data).then((response) => {
                issueResponseMessage(response);
                getMeters(
                    meterSearchName,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meters) => setRows(meters));
                handleAddMeterClose();
            });
        }
    }

    function handleInvoiceLastReadingToggle(event) {
        setInvoiceLastReading(event.target.checked);
    }

    function handleIncludeImagesToggle(event) {
        setincludeImages(event.target.checked);
    }

    function handleMeterTypeChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.meterTypeCode = value;
        setselectedMeter(obj);
        setMeterTypeCode(value);
    }

    function handleRateTypeChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.rateTypeCode = value;
        setselectedMeter(obj);
        setRateType(value);
    }

    function handleReadingFrequencyChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.readingFrequency = value;
        setselectedMeter(obj);
        setReadingPeriod(value);
    }

    function handleFacilityOptionChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.facilityId = value;
        setselectedMeter(obj);
        setFacilityId(value);
    }

    function handleComplexOptionChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.complexId = value;
        setselectedMeter(obj);
        setComplexId(value);
    }

    function handleUnitOptionChange(event) {
        let value = event.target.value;
        let obj = selectedMeter;
        obj.unitId = value;
        setselectedMeter(obj);
        setUnitId(value);
    }

    function handleReadingDateChange(value) {
        let now = new Date()
        let dateValue = new Date(value);
        now.setFullYear(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate())
        setReadingDate(adjustForTimezone(now));
    }

    function handleInvoiceTenantToggle(event) {
        setInvoiceTenant(event.target.checked);
    }

    function handleInvoicedToggle(event) {
        let obj = selectedMeter;
        obj.invoiced = event.target.checked;
        setInvoiced(event.target.checked);
        setselectedMeter(obj);
    }

    function handleMeterTaxedToggle(event) {
        let obj = selectedMeter;
        obj.isMeterTaxed = event.target.checked;
        setIsMeterTaxed(event.target.checked);
        setselectedMeter(obj);
    }

    function handleReadingEdit(reading) {
        setselectedReading(reading);
        setEditReading(true);
        setEditedMeterReadingAmount(reading.amount)

        if (isArray(selectedMeter.readings)) {
            // NOTE: array is arranged index 0 at top and index last at bottom
            let currentReadingIndex = selectedMeter.readings.findIndex((r) => {
                return r.id === reading.id;
            });
            if (
                currentReadingIndex > -1 &&
                currentReadingIndex < selectedMeter.readings.length
            ) {
                if (currentReadingIndex > 0) {
                    // any after index 0 has a next
                    setNextReading(selectedMeter.readings[currentReadingIndex - 1]); // set to the item before
                }
                if (currentReadingIndex !== selectedMeter.readings.length - 1) {
                    // if index is not last then look for a reading down the list that is the previous reading
                    setPreviousReading(selectedMeter.readings[currentReadingIndex + 1]); // next reading
                }
            }
        }
    }

    function getTemplate() {
        downloadMeterReadingsBulkUploadTemplate().then(() => issueSuccessMessage("Template downloaded"))
    }

    function readingsUpload() {
        let data = new FormData();
        data.append("file", bulkFile);
        data.append("markAsInvoiced", invoiceLastReading);

        uploadReadings(data).then((response) => {
            issueResponseMessage(response);
            getMeters(meterSearchName, paginationModel.page, paginationModel.pageSize)
                .then((meters) => setRows(meters))
                .finally(() => {
                    setLoading(false);
                });
            setUploadReadingsModal(false);
            setInvoiceLastReading(false);
            setBulkFile(null);
            setBulkFileProgress(0);
        });
    }

    function utilitiesExport() {
        if (exportFromDate > exportToDate) {
            issueWarnMessage("The 'from' date cannot be after the 'to' date.");
            return false;
        }

        if (Math.round(dateDifference(exportFromDate, exportToDate)) > 90) {
            issueWarnMessage(
                "Cannot export daily readings with a date range greater than 90 days."
            );
            return false;
        }

        let data = {
            from: exportFromDate,
            to: exportToDate,
        };

        exportUtilities(includeImages, data)
            .then((response) => {
                setExportUtilitiesModal(false);
                setincludeImages(false);
                setExportFromDate("");
                setExportToDate("");
                getMeters(
                    meterSearchName,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meters) => setRows(meters));
            })
            .catch((error) => {
                if (includeImages) {
                    setExportUtilitiesModal(false);
                    setincludeImages(false);
                    setExportFromDate("");
                    setExportToDate("");
                    getMeters(
                        meterSearchName,
                        paginationModel.page,
                        paginationModel.pageSize
                    ).then((meters) => setRows(meters));
                    issueSuccessMessage(
                        "The data is being exported. Please wait a while for the email to come through."
                    );
                } else {
                    issueErrorMessage(error);
                }
            });
    }

    function handleTieredItemAdd() {
        setItems((items) => [...items, {id: "", min: null, max: null, amount: null}]);
        setMeterItems((meterItems) => [
            ...meterItems,
            {id: "", min: null, max: null, amount: null},
        ]);
    }

    function meterItemMinChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].min = value;
        setItems(newArr);
        populateTieredItems();
    }

    function meterItemMaxChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].max = value;
        setItems(newArr);
        populateTieredItems();
    }

    function meterItemAmountChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].amount = value;
        setItems(newArr);
        populateTieredItems();
    }

    function populateTieredItems() {
        let rows = "";
        let total = 0;
        items &&
        items.length > 0 &&
        (rows = items.map(function (meterItem, i) {
            let amount = tryParseFloat(meterItem.amount, -1);
            if (amount > 0) {
                total = total + amount;
            }
            return (
                <TableRow key={i}>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeItemMemo" + i}
                            name={"chargeItemMemo" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].min}
                            onChange={(event) => meterItemMinChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeItemMemo" + i}
                            name={"chargeItemMemo" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].max}
                            onChange={(event) => meterItemMaxChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeAmount" + i}
                            name={"chargeAmount" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => meterItemAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            sx={{marginLeft: "10px"}}
                            onClick={() => handleItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }, this));
        setTieredItemsTableRows(rows);
        setTieredItemsTotal(total);
    }

    function validateMeterForm() {
        let data = selectedMeter;
        if (isNullUndefined(data)) {
            issueWarnMessage("Could not determine Meter to save");
            return false;
        }
        if (isEmptyString(data.name)) {
            issueWarnMessage("The meter must have a name!!");
            return false;
        }
        if (
            isEmptyString(data.readingFrequency) &&
            !user.services.includes("MAINT")
        ) {
            issueWarnMessage("Meter reading frequency must be selected!!");
            return false;
        }
        if (!Blwtr) {
            if (isEmptyString(data.meterTypeCode)) {
                issueWarnMessage("Meter type must be selected!!");
                return false;
            }
            if (isEmptyString(data.facilityId) && isEmptyString(data.complexId)) {
                issueWarnMessage("Either a facility or complex must be selected!!");
                return false;
            }
            if (!user.services.includes("MAINT") && data.rateTypeCode === "FIXED") {
                if (isEmptyString(data.rate)) {
                    issueWarnMessage("Rate must be set!!");
                    return false;
                }
            }
            if (!user.services.includes("MAINT") && data.rateTypeCode === "TIERD") {
                if (data.rateArray.length < 1) {
                    issueWarnMessage(
                        "You must add at least one charge to save a tiered meter."
                    );
                    return false;
                }
            }
            if (isArray(data.rateArray)) {
                for (let charge of data.rateArray) {
                    if (isEmptyString(charge.min)) {
                        issueWarnMessage("A charge must have a min amount.");
                        return false;
                    }
                    if (isNullUndefined(charge.max)) {
                        issueWarnMessage("A charge must have a max amount.");
                        return false;
                    }
                    if (isNullUndefined(charge.amount)) {
                        issueWarnMessage("A charge must have an amount.");
                        return false;
                    }
                }
            }
        }
        setselectedMeter(data);
        return true;
    }

    function fileValueChanged(event) {
        setBulkFile(event.target.files[0]);
    }

    function handleFromDateChange(value) {
        let date = new Date(value);
        setExportFromDate(date);
    }

    function handleToDateChange(value) {
        let date = new Date(value);
        setExportToDate(date);
    }

    function getPrevious(selectedMeter) {
        let sortedReading = [];
        if (isEmptyArray(selectedMeter.readings)) {
            return null;
        }
        if (
            isEmptyArray(sortedReading) ||
            selectedMeter.readings.length !== sortedReading.length
        ) {
            sortedReading = selectedMeter.readings.sort((obj1, obj2) => {
                return obj1.date > obj2.date ? -1 : obj1.date < obj2.date ? 1 : 0;
            }); // desc
        }
        setSortedReadings(sortedReading);
    }

    function handleItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            newArr.splice(index, 1);
            setItems(newArr);
            setMeterItems(newArr);
        } else {
            issueErrorMessage("There must be at least one tiered item");
        }
    }

    const columns = useMemo(() => [
        {headerName: "Meter Number", field: "name", minWidth: 138, flex: 1.38},
        {headerName: "Type", field: "meterType", minWidth: 180, flex: 1.8},
        {
            headerName: "Read Frequency",
            field: "readingFrequencyName",
            minWidth: 120,
            flex: 1.2,
        },
        {
            headerName: "Invoiced", field: "invoiced", width: "100",
            valueFormatter: (row) => {
                if (row.value) {
                    return "Yes";
                } else {
                    return "No";
                }
            },
        },
        {headerName: "Attached to", field: "attachedTo", width: "280"},
        {headerName: "Rate Type", field: "rateTypeName", width: "120"},
        {headerName: "Rate", field: "rate", width: "80"},
        {headerName: "Standing Charge", field: "standingCharge", width: "80"},
        {
            headerName: "Readings",
            field: "readings",
            width: "140",
            renderCell: (params) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={user.proxyRole === "REC"}
                        onClick={() => handleReadingsClick(params.row)}
                    >
                        Readings ({params.row.readingCount})
                    </Button>
                </strong>
            ),
        },
        {
            headerName: "Actions",
            field: "actions",
            width: "100",
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => handleEditClick(params.row)}
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                )
                arr.push(
                    <GridActionsCellItem
                        icon={<Archive/>}
                        label="Archive"
                        onClick={() => handleArchiveClick(params.row)}
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                )
                if (params.row && params.row.broken) {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Build/>}
                            label="Repair"
                            onClick={() => handleRepairClick(params.row)}
                            showInMenu
                            disabled={user.proxyRole === "REC"}
                        />
                    );
                }
                return arr;
            },
        },
    ]);

    const maintenanceMeterColumns = useMemo(() => [
        {headerName: "Meter Number", field: "name", width: "330"},
        {headerName: "Type", field: "meterType", width: "300"},
        {headerName: "Attached to", field: "attachedTo", width: "350"},
        {
            headerName: "Readings",
            field: "readings",
            width: "250",
            renderCell: (params) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleReadingsClick(params.row)}
                        disabled={user.proxyRole === "REC"}
                    >
                        Readings ({params.row.readingCount})
                    </Button>
                </strong>
            ),
        },
        {
            headerName: "Actions",
            field: "actions",
            width: "120",
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit/>}
                    label="Edit"
                    onClick={() => handleEditClick(params.row)}
                    showInMenu
                    disabled={user.proxyRole === "REC"}
                />,
                <GridActionsCellItem
                    icon={<Archive/>}
                    label="Archive"
                    onClick={() => handleArchiveClick(params.row)}
                    showInMenu
                    disabled={user.proxyRole === "REC"}
                />,
            ],
        },
    ]);

    const bulkColumns = useMemo(() => [
        {headerName: "Name/Number", field: "name", width: "330"},
        {headerName: "Type", field: "meterType", width: "370"},
        {headerName: "Rate Type", field: "rateTypeName", width: "250"},
        {headerName: "Rate(Price per Litre)", field: "rate", width: "250"},
        {
            headerName: "Actions",
            field: "actions",
            width: "150",
            type: "actions",
            getActions: (params) => {
                let arr = [];
                setBlwtr(true);
                arr.push(
                    <GridActionsCellItem
                        icon={<Visibility/>}
                        label="View"
                        onClick={() => handleViewClick(params.row)}
                        showInMenu
                    />
                );
                if (params.row && params.row.broken) {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Build/>}
                            label="Repair"
                            onClick={() => handleRepairClick(params.row)}
                            showInMenu
                            disabled={user.proxyRole === "REC"}
                        />
                    );
                }
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => handleEditClick(params.row)}
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />,
                    <GridActionsCellItem
                        icon={<Archive/>}
                        label="Archive"
                        onClick={() => handleArchiveClick(params.row)}
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                );
                return arr;
            },
        },
    ]);

    return (
        <Permit
            roles="BLDIR,ACC,BLDMG,CS,REC"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {!ViewDiv &&
                    <TopBarContainer
                        container
                        sx={{
                            width: {xs: "100%", lg: "80%"},
                            boxShadow: "4px 4px 12px #aaa",
                        }}
                    >
                        <ResponsiveRow
                            item
                            sx={{
                                justifyContent: {xs: "center", lg: "flex-start"},
                                width: {xs: "100%", lg: "33.3%"},
                                texAlign: {xs: "center", lg: "start"},
                                padding: 0,
                            }}
                        >
                            <FormControl sx={{width: "100%"}}>
                                <InputLabel>Search Utilities</InputLabel>
                                <OutlinedInput
                                    id="searchCustomer"
                                    label="Search Utilities"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setSearchMeterName(value);
                                    }}
                                    inputProps={
                                        <TextField
                                            sx={{
                                                width: {xs: "100%", lg: "90%"},
                                            }}
                                        />
                                    }
                                />
                            </FormControl>
                        </ResponsiveRow>
                        <ResponsiveRow
                            item
                            sx={{
                                justifyContent: {xs: "center", lg: "flex-end"},
                                width: {xs: "100%", lg: "66.6%"},
                                alignItems: {xs: "center", lg: "flex-end"},
                                padding: 0,
                            }}
                        >
                            <Permit roles="BLDIR,BLDMG,SYSAD,CS">
                                {!Blwtr ? (
                                    <TopBarContainedBrandButton
                                        sx={{width: {xs: "100%", lg: "150px"}, height: "50px"}}
                                        onClick={handleAddMeterClick}
                                    >
                                        Add Meter
                                    </TopBarContainedBrandButton>
                                ) : (
                                    <TopBarContainedBrandButton
                                        sx={{width: {xs: "100%", lg: "150px"}, height: "50px"}}
                                        onClick={handleAddBulkMeterClick}
                                    >
                                        Add Meter
                                    </TopBarContainedBrandButton>
                                )}
                            </Permit>
                            <Permit roles="BLDIR,BLDMG,SYSAD,CS">
                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "150px"}, height: "50px"}}
                                    onClick={() => {
                                        setUploadReadingsModal(true);
                                        setInvoiceLastReading(false);
                                    }}
                                >
                                    Upload readings
                                </TopBarContainedBrandButton>
                            </Permit>
                            <Permit roles="BLDIR,BLDMG,SYSAD,CS">
                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "150px"}, height: "50px"}}
                                    onClick={() => {
                                        setExportUtilitiesModal(true);
                                        let dateOffset = 24 * 60 * 60 * 1000 * 30;
                                        setExportToDate(new Date());
                                        setExportFromDate(new Date() - dateOffset);
                                    }}
                                >
                                    Export Utilities
                                </TopBarContainedBrandButton>
                            </Permit>
                            <Permit roles="BLDIR,BLDMG,ACC,CS">
                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "150px"}, height: "50px"}}
                                    onClick={handleBulkEditClick}
                                    disabled={rowSelectionModel.length < 2}
                                >
                                    {rowSelectionModel.length > 1
                                        ? "Bulk Edit " + rowSelectionModel.length + " Meters"
                                        : "Bulk Edit"}
                                </TopBarContainedBrandButton>
                            </Permit>
                        </ResponsiveRow>
                    </TopBarContainer>
                }

                <br/>

                {AddDiv && (
                    <AddMeterModal
                        AddDiv={AddDiv}
                        bulkEdit={BulkEdit ? BulkEdit : null}
                        EditDiv={EditDiv}
                        saveMeter={saveMeter}
                        bulkEditMeters={bulkEditMeters}
                        handleAddMeterClose={handleAddMeterClose}
                        handleComplexOptionChange={handleComplexOptionChange}
                        handleFacilityOptionChange={handleFacilityOptionChange}
                        handleInvoiceTenantToggle={handleInvoiceTenantToggle}
                        handleInvoicedToggle={handleInvoicedToggle}
                        handleMeterTypeChange={handleMeterTypeChange}
                        handleRateTypeChange={handleRateTypeChange}
                        handleReadingFrequencyChange={handleReadingFrequencyChange}
                        handleTieredItemAdd={handleTieredItemAdd}
                        handleUnitOptionChange={handleUnitOptionChange}
                        invoiced={invoiced}
                        setComplex={setComplex}
                        setComplexId={setComplexId}
                        setFacilityId={setFacilityId}
                        setMeterName={setMeterName}
                        setProperty={setProperty}
                        setRate={setRate}
                        setStandingCharge={setStandingCharge}
                        Blwtr={Blwtr}
                        bulkWaterMeterTypes={bulkWaterMeterTypes}
                        complex={complex}
                        complexId={complexId}
                        complexOptions={complexOptions}
                        facilityId={facilityId}
                        facilityOptions={facilityOptions}
                        invoiceTenant={invoiceTenant}
                        meterName={meterName}
                        meterReadingPeriods={meterReadingPeriods}
                        meterTypes={meterTypes}
                        meterTypeCode={meterTypeCode}
                        property={property}
                        rate={rate}
                        rateType={rateType}
                        rateTypeOptions={rateTypeOptions}
                        readingPeriod={readingPeriod}
                        selectedMeter={selectedMeter}
                        standingCharge={standingCharge}
                        tieredItemsTableRows={tieredItemsTableRows}
                        tieredItemsTotal={tieredItemsTotal}
                        unitId={unitId}
                        unitOptions={unitOptions}
                        bulkEditMetersType={bulkEditMetersType}
                        isMeterTaxed={isMeterTaxed}
                        handleMeterTaxedToggle={handleMeterTaxedToggle}
                    />
                )}

                <Permit services="HOA, PREMIUM, METER, MAINT">
                    <MuiDataGrid
                        dataGridColumns={user.services.includes("MAINT") ? maintenanceMeterColumns : columns}
                        dataGridRows={rows}
                        serverPagination={true}
                        handlePageLoad={setPaginationModel}
                        handleSelectedRows={setRowSelectionModel}
                        currentSelectionModel={rowSelectionModel}
                        loading={loading}
                        getRowClassName={(params) => {
                            return params.row.broken ? "suspended" : "";
                        }}
                        height="60vh"
                        totalRows={totalMeters}
                    />
                </Permit>

                <Permit services="BLWTR">
                    {ViewDiv && (
                        <AddContainer>
                            <Paper
                                variant="outlined"
                                sx={{
                                    height: "auto",
                                    margin: "auto",
                                    padding: "10px",
                                    textAlign: "left",
                                }}
                            >
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        height: "auto",
                                        margin: "auto",
                                        padding: "10px",
                                        textAlign: "left",
                                    }}
                                >
                                    <InnerModal container>
                                        <Grid item xs={4}>
                                            <Paper
                                                variant="outlined"
                                                sx={{height: "auto", margin: "auto", padding: "10px"}}
                                            >
                                                <ColumnContainer container>
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h3"
                                                        component="h2"
                                                    >
                                                        {selectedMeter.name}
                                                    </Typography>
                                                    <InputLabel>
                                                        Date: {formatDate(new Date())}
                                                    </InputLabel>
                                                    <br/>
                                                </ColumnContainer>
                                                <InnerModal container>
                                                    <Grid item xs={6}>
                                                        <ColumnContainer container>
                                                            <InputLabel>Last Reading: </InputLabel>
                                                            <Typography
                                                                id="modal-modal-title"
                                                                variant="h4"
                                                                component="h3"
                                                            >
                                                                {selectedMeter.lastMeterReading}
                                                                {"\u33A5"}
                                                            </Typography>
                                                            <br/>
                                                            <InputLabel>
                                                                {!isEmptyArray(sortedReadings) &&
                                                                sortedReadings[0].imageFile ? (
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={
                                                                            "/kodi/utilityReadingImage?filename=" +
                                                                            sortedReadings[0].imageFile
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                "/kodi/utilityReadingImage?filename=" +
                                                                                sortedReadings[0].imageFile
                                                                            }
                                                                            alt=""
                                                                            style={{width: "50px"}}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    "No image file"
                                                                )}
                                                            </InputLabel>
                                                            <InputLabel>
                                                                {!isEmptyArray(sortedReadings) &&
                                                                sortedReadings[0].localCode ? (
                                                                    <a
                                                                        target={"_blank"}
                                                                        rel="noreferrer"
                                                                        title={"View on Map"}
                                                                        href={
                                                                            "https://www.google.com.sa/maps/search/" +
                                                                            encodeURIComponent(
                                                                                sortedReadings[0].localCode
                                                                            ) +
                                                                            "?hl=en"
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexWrap: "wrap",
                                                                            }}
                                                                        >
                                                                            {selectedMeter.readings.localCode}
                                                                        </div>
                                                                    </a>
                                                                ) : (
                                                                    "No Location Info"
                                                                )}
                                                            </InputLabel>
                                                        </ColumnContainer>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <ColumnContainer container>
                                                            <InputLabel>Current Reading: </InputLabel>
                                                            <Typography
                                                                id="modal-modal-title"
                                                                variant="h4"
                                                                component="h3"
                                                            >
                                                                {!isEmptyArray(sortedReadings) &&
                                                                sortedReadings[1].amount
                                                                    ? sortedReadings[1].amount
                                                                    : "0.0"}
                                                                {"\u33A5"}
                                                            </Typography>
                                                            <br/>
                                                            <InputLabel>
                                                                {!isEmptyArray(sortedReadings) &&
                                                                sortedReadings[1].imageFile ? (
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={
                                                                            "/kodi/utilityReadingImage?filename=" +
                                                                            sortedReadings[1].imageFile
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                "/kodi/utilityReadingImage?filename=" +
                                                                                sortedReadings[1].imageFile
                                                                            }
                                                                            alt=""
                                                                            style={{width: "50px"}}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    "No image file"
                                                                )}
                                                            </InputLabel>
                                                            <InputLabel>
                                                                {!isEmptyArray(sortedReadings) &&
                                                                sortedReadings[1].localCode ? (
                                                                    <a
                                                                        target={"_blank"}
                                                                        rel="noreferrer"
                                                                        title={"View on Map"}
                                                                        href={
                                                                            "https://www.google.com.sa/maps/search/" +
                                                                            encodeURIComponent(
                                                                                sortedReadings[1].localCode
                                                                            ) +
                                                                            "?hl=en"
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexWrap: "wrap",
                                                                            }}
                                                                        >
                                                                            {selectedMeter.readings.localCode}
                                                                        </div>
                                                                    </a>
                                                                ) : (
                                                                    "No Location Info"
                                                                )}
                                                            </InputLabel>
                                                        </ColumnContainer>
                                                    </Grid>
                                                </InnerModal>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Paper
                                                variant="outlined"
                                                sx={{
                                                    height: "auto",
                                                    margin: "auto",
                                                    padding: "10px",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{marginBottom: "5px"}}
                                                    id="modal-modal-title"
                                                    variant="h5"
                                                    component="h4"
                                                >
                                                    <Box sx={{float: "left"}}>Meter Revenue </Box>
                                                </Typography>
                                                <TableContainer style={{maxHeight: 350}}>
                                                    <Table aria-label="simple table" stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{width: "20%"}}></TableCell>
                                                                <TableCell sx={{width: "20%"}}>
                                                                    Cash
                                                                </TableCell>
                                                                <TableCell sx={{width: "20%"}}>
                                                                    Mobile Money
                                                                </TableCell>
                                                                {selectedMeter.meterTypeCode === "BLK" && (
                                                                    <TableCell sx={{width: "20%"}}>
                                                                        Credit
                                                                    </TableCell>
                                                                )}
                                                                {selectedMeter.meterTypeCode === "BLK" ? (
                                                                    <TableCell sx={{width: "20%"}}>
                                                                        Credit Outstanding
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell sx={{width: "20%"}}>
                                                                        Total
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Today</TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[0].cash}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[0].mobileMoney}
                                                                </TableCell>
                                                                {selectedMeter.meterTypeCode === "BLK" && (
                                                                    <TableCell>
                                                                        {revenue.meterRevenues &&
                                                                            revenue.meterRevenues[0].credit}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[0].total}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Past Week</TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[1].cash}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[1].mobileMoney}
                                                                </TableCell>
                                                                {selectedMeter.meterTypeCode === "BLK" && (
                                                                    <TableCell>
                                                                        {revenue.meterRevenues &&
                                                                            revenue.meterRevenues[1].credit}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[1].total}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Past 30 Days</TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[2].cash}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[2].mobileMoney}
                                                                </TableCell>
                                                                {selectedMeter.meterTypeCode === "BLK" && (
                                                                    <TableCell>
                                                                        {revenue.meterRevenues &&
                                                                            revenue.meterRevenues[2].credit}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[2].total}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Year To Date</TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[3].cash}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[3].mobileMoney}
                                                                </TableCell>
                                                                {selectedMeter.meterTypeCode === "BLK" && (
                                                                    <TableCell>
                                                                        {revenue.meterRevenues &&
                                                                            revenue.meterRevenues[3].credit}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {revenue.meterRevenues &&
                                                                        revenue.meterRevenues[3].total}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Grid>
                                    </InnerModal>
                                </Paper>
                                <br/>

                                <TransactionHistory
                                    transactionMode={"Meters"}
                                    contract={selectedMeter}
                                />
                                <br/>

                                {/*FOOTER WITH BUTTONS*/}
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        height: "auto",
                                        margin: "auto",
                                        padding: "10px",
                                        alignItems: "left",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        //   type="submit"
                                        color="error"
                                        onClick={() => setViewDiv(false)}
                                    >
                                        Close
                                    </Button>
                                </Paper>
                            </Paper>
                            <br/>
                        </AddContainer>
                    )}

                    {ViewDiv && <br/>}

                    {!ViewDiv && (
                        <MuiDataGrid
                            dataGridColumns={bulkColumns}
                            dataGridRows={rows}
                            handleSelectedRows={setRowSelectionModel}
                            currentSelectionModel={rowSelectionModel}
                            loading={loading}
                            height="60vh"
                            getRowClassName={(params) => {
                                return params.row.broken ? "suspended" : "";
                            }}
                            totalRows={totalMeters}
                            handlePageLoad={setPaginationModel}
                            serverPagination={true}
                        />
                    )}
                </Permit>

                {/*READINGS MODAL*/}
                <ViewMeterReadingsModal
                    ReadingsModal={ReadingsModal}
                    user={user}
                    handleReadingEdit={handleReadingEdit}
                    setAddReading={setAddReading}
                    setConfirmDelete={setConfirmDelete}
                    setReadingsModal={setReadingsModal}
                    confirmDelete={confirmDelete}
                    deleteSelected={deleteSelected}
                    invoice={invoice}
                    meterReadings={meterReadings}
                    selectedMeter={selectedMeter}
                />

                <AddMeterReadingsModal
                    user={user}
                    handleInvoiceLastReadingToggle={handleInvoiceLastReadingToggle}
                    handleReadingDateChange={handleReadingDateChange}
                    setAddReading={setAddReading}
                    setMeterReadingAmount={setMeterReadingAmount}
                    addMeterReading={addMeterReading}
                    addReading={addReading}
                    invoiceLastReading={invoiceLastReading}
                    meterReadingAmount={meterReadingAmount}
                    readingDate={readingDate}
                    selectedMeter={selectedMeter}
                    file={file}
                    setFile={setFile}
                    fileProgress={fileProgress}
                />

                <EditMeterReadingsModal
                    setEditReading={setEditReading}
                    setEditedMeterReadingAmount={setEditedMeterReadingAmount}
                    setselectedReading={setselectedReading}
                    editMeterReading={editMeterReading}
                    editedMeterReadingAmount={editedMeterReadingAmount}
                    editReading={editReading}
                    nextReading={nextReading}
                    previousReading={previousReading}
                    selectedMeter={selectedMeter}
                    selectedReading={selectedReading}
                    file={file}
                    setFile={setFile}
                    fileProgress={fileProgress}
                />

                {/*ARCHIVE MODAL*/}
                <ArchiveMeterModal
                    ArchiveModal={ArchiveModal}
                    handleArchiveClose={handleArchiveClose}
                    handleInvoiceLastReadingToggle={handleInvoiceLastReadingToggle}
                    archiveSelectedMeter={archiveSelectedMeter}
                    invoiceLastReading={invoiceLastReading}
                    selectedMeter={selectedMeter}
                />

                {/*UPLOAD READINGS MODAL*/}
                <UploadMeterReadingModal
                    readingsUpload={readingsUpload}
                    UploadReadingsModal={UploadReadingsModal}
                    handleInvoiceLastReadingToggle={handleInvoiceLastReadingToggle}
                    setBulkFile={setBulkFile}
                    setBulkFileProgress={setBulkFileProgress}
                    setUploadReadingsModal={setUploadReadingsModal}
                    setInvoiceLastReading={setInvoiceLastReading}
                    bulkFile={bulkFile}
                    bulkFileProgress={bulkFileProgress}
                    fileValueChanged={fileValueChanged}
                    getTemplate={getTemplate}
                    invoiceLastReading={invoiceLastReading}
                />

                {/*EXPORT UTILITIES MODAL*/}
                <ExportMeterUtilitiesModal
                    utilitiesExport={utilitiesExport}
                    ExportUtilitiesModal={ExportUtilitiesModal}
                    handleFromDateChange={handleFromDateChange}
                    handleIncludeImagesToggle={handleIncludeImagesToggle}
                    handleToDateChange={handleToDateChange}
                    setExportUtilitiesModal={setExportUtilitiesModal}
                    setExportToDate={setExportToDate}
                    setExportFromDate={setExportFromDate}
                    setincludeImages={setincludeImages}
                    exportFromDate={exportFromDate}
                    exportToDate={exportToDate}
                    includeImages={includeImages}
                />

                {/*REPAIR MODAL*/}
                <RepairMeterModal
                    RepairModal={RepairModal}
                    setRepairModal={setRepairModal}
                    selectedMeter={selectedMeter}
                    meterFixed={meterFixed}
                />
            </MainContainer>{" "}
        </Permit>
    );
}
