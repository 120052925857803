/* eslint-disable react-hooks/exhaustive-deps */
//all the shared components in the system
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  formatCurrency,
  formatDate,
  getRefNumber,
  isAfterToday,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  sortJSONArray,
  tryParseFloat,
} from "../../util/util";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { getAllAccounts, getCreditAccounts } from "../../services/list.service";
import { getOutstandingTitleInvoicesCharges } from "../../services/ownership.service";
import { creditItemsList, saveCreditNote } from "../../services/shared.service";
import { ModalAcceptButton } from "../../constants/component.constants";
import { getOutstandingLeaseInvoicesCharges } from "../../services/lease.service";
import { getOutstandingMeterBillingInvoicesCharges } from "../../services/meterbilling.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import dayjs from "dayjs";
import { getOutstandingSubscriptionInvoicesCharges } from "../../services/client.service";
import { saveClientCreditNote } from "../../services/subscription.service";
import {getOutstandingInvoicesForPersonOrOrg} from "../../services/invoices.service";

export default function CreditNote(props) {
  const [creditNoteDate, setCreditNoteDate] = useState(new Date());
  const [creditNoteMemo, setCreditNoteMemo] = useState(null);
  const [items, setItems] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [creditItemsRows, setCreditItemsRows] = useState(null);
  const [creditItemsTotal, setCreditItemsTotal] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [outstandingInvoicesCharges, setOutstandingInvoicesCharges] = useState(
    []
  );

  useEffect(() => {
    let total = 0;
    let rows =
      items &&
      items.length > 0 &&
      items.map(function (creditItem, i) {
        let amount = tryParseFloat(creditItem.amount, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                name={"accountId"}
                isrequired={true}
                disabled={!isNullUndefined(props.voiditem)}
                value={creditItem.accountId}
                onChange={(event) => handleCreditAccountChange(event, i)}
              >
                {creditAccounts && creditAccounts.length > 0 ? (
                  creditAccounts.map((creditAccount, i) => {
                    return (
                      <MenuItem key={i} value={creditAccount.value}>
                        {creditAccount.label}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                name={"amount"}
                disabled={!isNullUndefined(props.voiditem)}
                isrequired={true}
                value={creditItem.amount}
                onChange={(event) => creditAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                disabled={!isNullUndefined(props.voiditem)}
                sx={{ marginLeft: "10px" }}
                onClick={() => handleCreditItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    setCreditItemsRows(rows);
    setCreditItemsTotal(total);
  }, [props, items, creditAccounts]);

  useEffect(() => {
    if (isNullUndefined(props.subscriptionId)) {
      //get accounts for non-subscription credit
      if (props.voiditem) {
        getAllAccounts().then((creditAccounts) => {
          setCreditAccounts(creditAccounts.sort(sortJSONArray("label", false)));
          if (!isNullUndefined(props.voiditem)) {
            //get the full details of the invoice or charge being voided
            creditItemsList(props.voiditem.type, props.voiditem.id).then(
              (data) => {
                setItems(data);
              }
            );
            setCreditNoteMemo(
              "Credit to void " +
                props.voiditem.type +
                " #" +
                props.voiditem.referenceNumber
            );
          }
        });
      } else {
        getCreditAccounts().then((creditAccounts) => {
          setCreditAccounts(creditAccounts);
          if (!isNullUndefined(props.voiditem)) {
            //get the full details of the invoice or charge being voided
            creditItemsList(props.voiditem.type, props.voiditem.id).then(
              (data) => {
                setItems(data);
              }
            );
            setCreditNoteMemo(
              "Credit to void " +
                props.voiditem.type +
                " #" +
                props.voiditem.referenceNumber
            );
          }
        });
      }
    }

    if (!isNullUndefined(props.ownershipid)) {
      getOutstandingTitleInvoicesCharges(props.ownershipid).then(
        (outstandingInvoicesCharges) => {
          setOutstandingInvoicesCharges(outstandingInvoicesCharges);
          if (items.length === 0 && isNullUndefined(props.voiditem)) {
            handleCreditItemAdd(); //call this for adding the first row
          }
        }
      );
    }
    if (!isNullUndefined(props.leaseid)) {
      getOutstandingLeaseInvoicesCharges(props.leaseid).then(
        (outstandingInvoicesCharges) => {
          setOutstandingInvoicesCharges(outstandingInvoicesCharges);
          if (items.length === 0 && isNullUndefined(props.voiditem)) {
            handleCreditItemAdd(); //call this for adding the first row
          }
        }
      );
    }
    if (!isNullUndefined(props.meterbillingid)) {
      getOutstandingMeterBillingInvoicesCharges(props.meterbillingid).then(
        (outstandingInvoicesCharges) => {
          setOutstandingInvoicesCharges(outstandingInvoicesCharges);
          if (items.length === 0 && isNullUndefined(props.voiditem)) {
            handleCreditItemAdd(); //call this for adding the first row
          }
        }
      );
    }
    if (!isNullUndefined(props.subscriptionId)) {
      getOutstandingSubscriptionInvoicesCharges(props.subscriptionId).then(
        (data) => {
          setOutstandingInvoicesCharges(data.outstandingItems);
        }
      )
    }
    if (!isNullUndefined(props.customerId)) {
      const splitCustomerId = props.customerId.split("_")
      const customerId = splitCustomerId[0]
      getOutstandingInvoicesForPersonOrOrg(customerId).then((data) => {
        setOutstandingInvoicesCharges(data);
      })
    }
  }, [props])

  function handleCreditAccountChange(event, index) {
    let newArr = [...items];
    newArr[index].accountId = event.target.value;
    setItems(newArr);
  }

  function creditAmountChange(event, index) {
    let newArr = [...items];
    newArr[index].amount = event.target.value;
    setItems(newArr);
  }

  function handleCreditItemRemove(index) {
    if (items.length > 1) {
      setItems(items.filter((item, i) => i !== index));
    } else {
      issueWarnMessage("There must be at least one credit item");
    }
  }

  function handleCreditItemAdd() {
    setItems([
      ...items,
      {
        accountId: "",
        amount: null,
      },
    ]);
  }

  function validateCreditForm(note) {
    if (isNullUndefined(note)) {
      issueWarnMessage(
        "Something went wrong with credit data, please refresh the page"
      );
      return false;
    }
    if (!isValidDate(note.date)) {
      issueWarnMessage("Select a valid credit note date");
      return false;
    }
    if (isAfterToday(note.date)) {
      issueWarnMessage("The credit date cannot be in the future");
      return false;
    }
    if (!isEmptyString(note.memo) && note.memo.length > 400) {
      issueWarnMessage("The credit memo cannot be greater than 400 characters");
      return false;
    }
    let amount = tryParseFloat(note.amount, -1);
    if (amount < 1) {
      issueWarnMessage(
        "Invalid Amount. The amount entered must be more than 0"
      );
      return false;
    }
    if (isNullUndefined(props.subscriptionId) && note.items.length <= 0) {
      issueWarnMessage(
        "You must enter at least one item to apply the credit to"
      );
      return false;
    }
    if (isNullUndefined(props.voiditem) && note.memo.toLowerCase().includes("credit to void")) {
      issueWarnMessage("'Credit to void' is a reserved memo. Please use the correct voiding procedure.");
      return false;
    }
    
    // eslint-disable-next-line array-callback-return
    note.items.map((creditItem, i) => {
      if (isEmptyString(creditItem.accountId)) {
        issueWarnMessage("You must select an account for row " + (i + 1));
        return false;
      } else if (isNullUndefined(creditItem.amount) || creditItem.amount < 1) {
        issueWarnMessage(
          "You must enter an amount greater than 0 for row " + (i + 1)
        );
        return false;
      }
    });
    return true;
  }

  function submit() {
    let data = {
      titleId: props.ownershipid,
      leaseId: props.leaseid,
      customerId: isNullUndefined(props.customerId) ? null : props.customerId,
      subscriptionId: props.subscriptionId,
      meterBillingId: props.meterbillingid,
      referenceNumber: getRefNumber(),
      date: creditNoteDate,
      memo: creditNoteMemo,
      isVoid: !isNullUndefined(props.voiditem),
      items: items,
      amount: isNullUndefined(props.subscriptionId)
        ? creditItemsTotal
        : creditAmount,
    };
    if (validateCreditForm(data)) {
      if (isNullUndefined(props.subscriptionId)) {
        saveCreditNote(data).then((response) => {
          issueResponseMessage(response);
          props.onClose();
        });
      } else {
        saveClientCreditNote(data).then((response) => {
          issueResponseMessage(response);
          props.onClose();
        });
      }
    }
  }

  return (
    <>
      <ModalContainerModule
        size="specialLeaseCreditNoteSize"
        accept={submit}
        cancel={props.onClose}
        openModal={props.open}
        modalTitle="Create a Credit"
        acceptButtonText=" Save Credit"
      >
        <ResponsiveRow
          container
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",

            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "flex-start",

            height: "auto",
            width: "100%",

            paddingTop: 0,
          }}
        >
          <ResponsiveRow
            item
            sx={{
              width: "100%",
              height: "auto",

              flexDirection: "row",
              flexWrap: { xs: "wrap", lg: "nowrap" },

              justifyContent: { xs: "center", lg: "space-between" },
              margin: { xs: "auto", lg: "0" },
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px", width: { xs: "100%", lg: "50%" } }}
            >
              Credit Information
            </Typography>
          </ResponsiveRow>
          <ResponsiveRow sx={{ width: "100%", padding: 0 }}>
            <FormControl
              sx={{
                width: {
                  xs: "100%",
                  lg: isNullUndefined(props.subscriptionId) ? "50%" : "30%",
                },
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Date *"
                  id={"creditNoteDate"}
                  name={"creditNoteDate"}
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  disabled={!isNullUndefined(props.voiditem)}
                  value={dayjs(creditNoteDate)}
                  onChange={(newDate) => setCreditNoteDate(new Date(newDate))}
                />
              </LocalizationProvider>
            </FormControl>
            {!isNullUndefined(props.subscriptionId) && (
              <FormControl
                sx={{ width: { xs: "100%", lg: "30%" }, marginBottom: "10px" }}
              >
                <InputLabel>Credit Amount *</InputLabel>
                <OutlinedInput
                  label="Credit Amount"
                  sx={{ width: "100%" }}
                  id={"creditAmount"}
                  name={"creditAmount"}
                  type={"number"}
                  disabled={!isNullUndefined(props.voiditem)}
                  required={true}
                  value={creditAmount}
                  onChange={(event) => setCreditAmount(event.target.value)}
                />
              </FormControl>
            )}
            <FormControl
              sx={{
                width: {
                  xs: "100%",
                  lg: isNullUndefined(props.subscriptionId) ? "50%" : "30%",
                },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Credit Memo *</InputLabel>
              <OutlinedInput
                label="Credit Memo *"
                sx={{ width: "100%" }}
                id={"memo"}
                name={"memo"}
                disabled={!isNullUndefined(props.voiditem)}
                required={true}
                value={creditNoteMemo || ""}
                onChange={(event) => setCreditNoteMemo(event.target.value)}
              />
            </FormControl>
          </ResponsiveRow>
          {isNullUndefined(props.subscriptionId) && (
            <ResponsiveRow
              item
              sx={{
                width: "100%",
                height: "auto",

                flexDirection: "row",
                flexWrap: { xs: "wrap", lg: "nowrap" },

                justifyContent: { xs: "center", lg: "space-between" },
                margin: { xs: "auto", lg: "10px auto auto auto" },
              }}
            >
              <Typography
                variant="h6"
                sx={{ margin: "10px 0", width: { xs: "100%", lg: "50%" } }}
              >
                Select Account to Credit
              </Typography>
              <ModalAcceptButton
                // type="submit"
                disabled={!isNullUndefined(props.voiditem)}
                onClick={handleCreditItemAdd}
                sx={{
                  width: { xs: "100%", md: "250px" },
                  height: "50px",
                }}
              >
                Add Account To Credit
              </ModalAcceptButton>
            </ResponsiveRow>
          )}
          {isNullUndefined(props.subscriptionId) && (
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: { xs: "wrap", lg: "nowrap" },
                justifyContent: { xs: "center", lg: "space-between" },
                margin: "auto",
              }}
            >
              <TableContainer style={{ maxHeight: 250 }}>
                <Table
                  sx={{ width: "100%" }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50%" }}>Account</TableCell>
                      <TableCell sx={{ width: "40%" }}>Amount</TableCell>
                      <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{creditItemsRows}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {isNullUndefined(props.subscriptionId) && (
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: { xs: "wrap", lg: "nowrap" },
                justifyContent: { xs: "center", lg: "space-between" },
                margin: "auto",
              }}
            >
              <ResponsiveRow
                container
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  width: "100%",
                  height: "auto",
                  margin: "10px 0",
                }}
              >
                <Grid item sx={{ width: "100%" }}>
                  <Alert severity="info">
                    <AlertTitle>
                      Credit Total: {formatCurrency(creditItemsTotal)}
                    </AlertTitle>
                  </Alert>
                </Grid>
              </ResponsiveRow>
            </Grid>
          )}
          <br />
          {outstandingInvoicesCharges &&
            outstandingInvoicesCharges.length > 0 && (
              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  margin: "auto",
                }}
              >
                <Typography variant="h5">
                  Outstanding Invoices/Charges (Unpaid & Partial)
                </Typography>
                <TableContainer style={{ maxHeight: 250 }}>
                  <Table
                    sx={{ minWidth: 150 }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Ref No.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Due date</TableCell>
                        <TableCell>Invoicee</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Outstanding Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {outstandingInvoicesCharges.map(function (
                        outstanding,
                        i
                      ) {
                        return (
                          <TableRow key={outstanding.id}>
                            <TableCell>{outstanding.referenceNumber}</TableCell>
                            <TableCell>
                              {formatDate(outstanding.date)}
                            </TableCell>
                            <TableCell>
                              {formatDate(outstanding.date)}
                            </TableCell>
                            <TableCell>Jane Doe</TableCell>
                            <TableCell>{outstanding.description}</TableCell>
                            <TableCell>{outstanding.status}</TableCell>
                            <TableCell>
                              {formatCurrency(outstanding.amount)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(outstanding.outstandingBalance)}
                            </TableCell>
                          </TableRow>
                        );
                      },
                      this)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
        </ResponsiveRow>
      </ModalContainerModule>
    </>
  );
}
