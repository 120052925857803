import {Delete, Done, Edit, Photo, PlayCircleOutline,} from "@mui/icons-material";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {InnerModal, ModalAcceptButton, ModalCancelButton,} from "../../../../constants/component.constants";
import {formatDateTime, isNullUndefined} from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";
import {setMessage} from "../../../../actions/message";
import {useDispatch} from "react-redux";
import kodi_theme from "../../../../themes/kodiThemeProvider";
import {downloadUtilityImage} from "../../../../services/meters.service";

export default function ViewMeterReadingsModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * ReadingsModal
     * user
     *
     * handleReadingEdit
     * setAddReading
     * setConfirmDelete
     * setReadingsModal
     *
     * confirmDelete
     * deleteSelected
     * invoice
     * meterReadings
     * selectedMeter
     *
     */
    const dispatch = useDispatch()
    return (
        <ModalContainerModule
            size="md"
            accept={
                props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" &&
                (props.user.proxyRole === "BLDIR" ||
                props.user.proxyRole === "SYSAD" ||
                props.user.proxyRole === "BLDMG" ||
                props.user.proxyRole === "CS" ||
                props.user.proxyRole === "REC")
                    ? () => props.setAddReading(true)
                    : null
            }
            cancel={() => {
                props.setReadingsModal(false);
                props.setConfirmDelete(false);
            }}
            openModal={props.ReadingsModal}
            modalTitle={`Meter Readings for ${
                props.selectedMeter && props.selectedMeter.name
            }`}
            acceptButtonText="Add Reading"
        >
            <Paper variant="" sx={{width: "100%", height: "100%", padding: "10px"}}>
                <InnerModal container>
                    <Grid item xs={12}>
                        <TableContainer style={{maxHeight: 400}}>
                            <Table
                                sx={{minWidth: 650}}
                                aria-label="simple table"
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Reading</TableCell>
                                        <TableCell>Read by</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Image</TableCell>
                                        {(props.user.proxyRole === "BLDIR" ||
                                            props.user.proxyRole === "SYSAD") && (
                                            <TableCell>Invoiced</TableCell>
                                        )}
                                        <TableCell>Last Edited</TableCell>
                                        {(props.user.proxyRole === "BLDIR" ||
                                            props.user.proxyRole === "SYSAD") && props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" && (
                                            <TableCell>Edit</TableCell>
                                        )}
                                        {props.user.proxyRole === "BLDIR" && props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" && (
                                            <TableCell>Delete</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.meterReadings &&
                                        props.meterReadings.readings &&
                                        props.meterReadings.readings.map(function (reading, i) {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{formatDateTime(reading.date)}</TableCell>
                                                    <TableCell>{reading.amount}</TableCell>
                                                    <TableCell>{reading.readByName}</TableCell>
                                                    <TableCell>
                                                        {reading.localCode ? (
                                                            <a
                                                                target={"_blank"}
                                                                rel="noreferrer"
                                                                title={"View on Map"}
                                                                href={
                                                                    "https://www.google.com.sa/maps/search/" +
                                                                    encodeURIComponent(reading.localCode) +
                                                                    "?hl=en"
                                                                }
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: kodi_theme.palette.primary.dark,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexWrap: "wrap",
                                                                        color: kodi_theme.palette.primary.dark,
                                                                    }}
                                                                >
                                                                    {reading.localCode}
                                                                </div>
                                                            </a>
                                                        ) : (
                                                            "No Location Info"
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reading.imageFile ? (
                                                            <IconButton
                                                                onClick={() => downloadUtilityImage(reading.imageFile)}>
                                                                <Photo
                                                                    sx={{
                                                                        fontSize: "1.75rem",
                                                                        margin: "auto",
                                                                        width: "50%",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ) : (
                                                            "No image file"
                                                        )}
                                                    </TableCell>
                                                    {(props.user.proxyRole === "BLDIR" ||
                                                        props.user.proxyRole === "SYSAD") && (
                                                        <TableCell>
                                                            {reading.transactionDetailId ? (
                                                                <Done
                                                                    sx={{
                                                                        fontSize: "1.75rem",
                                                                        margin: "auto",
                                                                        width: "50%",
                                                                    }}
                                                                />
                                                            ) : (
                                                                props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" &&
                                                                (<PlayCircleOutline
                                                                    title={"Invoice Reading"}
                                                                    sx={{
                                                                        fontSize: "1.75rem",
                                                                        margin: "auto",
                                                                        width: "50%",
                                                                    }}
                                                                    onClick={() => {
                                                                        if (!props.selectedMeter.invoiced) {
                                                                            dispatch(
                                                                                setMessage({
                                                                                    type: "warning",
                                                                                    message:
                                                                                        "Cannot invoice a reading on an uninvoiced meter",
                                                                                })
                                                                            );
                                                                        } else {
                                                                            props.invoice(reading);
                                                                        }
                                                                    }}
                                                                    disabled={!props.selectedMeter.invoiced}
                                                                />)
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    <TableCell>{reading.editedAt}</TableCell>
                                                    {(props.user.proxyRole === "BLDIR" ||
                                                        props.user.proxyRole === "SYSAD") &&
                                                        props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" && (
                                                        <TableCell>
                                                            <Edit
                                                                onClick={() => props.handleReadingEdit(reading)}
                                                            />
                                                        </TableCell>
                                                    )}
                                                    {props.user.proxyRole === "BLDIR" &&
                                                        isNullUndefined(reading.transactionDetailId) &&
                                                         props && props.selectedMeter && props.selectedMeter.statusCode !== "DEL" && (
                                                            <TableCell>
                                                                {!props.confirmDelete && (
                                                                    <Delete
                                                                        onClick={() => props.setConfirmDelete(true)}
                                                                    />
                                                                )}
                                                                {props.confirmDelete && (
                                                                    <ModalAcceptButton
                                                                        variant="contained"
                                                                        // type="submit"
                                                                        sx={{marginLeft: "10px"}}
                                                                        onClick={() =>
                                                                            props.deleteSelected(reading)
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </ModalAcceptButton>
                                                                )}
                                                                {props.confirmDelete && (
                                                                    <ModalCancelButton
                                                                        variant="contained"
                                                                        // type="submit"
                                                                        sx={{marginLeft: "10px"}}
                                                                        onClick={() =>
                                                                            props.setConfirmDelete(false)
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </ModalCancelButton>
                                                                )}
                                                            </TableCell>
                                                        )}
                                                </TableRow>
                                            );
                                        }, this)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </InnerModal>
            </Paper>
        </ModalContainerModule>
    );
}
