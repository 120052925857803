/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import BillingPaymentsTabBar from "../bars/billingPaymentsTabBar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { useCallback, useEffect, useState } from "react";
import {
  adjustForTimezone,
  formatCurrency,
  formatDateTime,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  titleCase,
} from "../../util/util";
import {
  fetchPayment,
  getPayments,
  getTotalPayments,
  savePaymentData,
} from "../../services/billAndPayment.service";
import { LocalPrintshop, Visibility } from "@mui/icons-material";
import ViewPayment from "../modals/viewPayment";
import CreatePayment from "../modals/createPayment";
import { issueResponseMessage } from "../../actions/message";
import { printPaymentVoucher } from "../../services/print.service";
import { renderCellExpand } from "../../util/renderCellExpand";
import { useSelector } from "react-redux";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import dayjs from "dayjs";
import MuiDataGrid from "./MuiDataGrid";
import { getVendorOptions } from "../../services/list.service";

export default function PaymentsTab() {
  const [expanded, setExpanded] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState("");
  const [totalPayments, setTotalPayments] = useState(0);
  const [payments, setPayments] = useState([]);
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [payees, setPayees] = useState([]);
  const [payeeOptions, setPayeeOptions] = useState([]);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [showViewPaymentModal, setShowViewPaymentModal] = useState(false);
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [statuses] = useState([
    { label: "Pending", value: "PEN" },
    { label: "Approved", value: "APP" },
    { label: "Rejected", value: "REJ" },
  ]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const user = useSelector((state) => state.auth.user);
  let disableSearch =
    isEmptyString(description) &&
    isEmptyArray(payees) &&
    isEmptyString(reference) &&
    isEmptyString(status) &&
    isEmptyString(date);

  const loadPayments = useCallback((paginationModel) => {
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });

    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      status: status,
      paymentDate: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    }
    if (!isNullUndefined(date) && isValidDate(date)) {
      searchObject.paymentDate = adjustForTimezone(new Date(date));
    }

    getPayments(searchObject).then((payments) => {
      setPayments(payments);
      setExpanded(false);
      setTask("Result");
    });

    getTotalPayments(searchObject).then((response) => {
      setTotalPayments(response.totalPayments);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    handleSearchClicked()
    setLoading(false);
  }, [paginationModel]);

  useEffect(() => {
    setLoading(true);
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });

    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      status: status,
      paymentDate: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(date) && isValidDate(date)) {
      searchObject.paymentDate = adjustForTimezone(new Date(date));
    }

    getPayments(searchObject).then((payments) => {
      setPayments(payments);
      setExpanded(false);
      setTask("Result");
    });

    getTotalPayments(searchObject).then((response) => {
      setTotalPayments(response.totalPayments);
    });
    setLoading(false);
  }, [user.proxySubscription]);

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "reference") {
      setReference(value);
    }
    if (id === "description") {
      setDescription(value);
    }
    if (id === "status") {
      setStatus(value);
    }
  }

  function handlePayeeChange(event) {
    setPayees(event.target.value);
  }

  function onStatusChange(value) {
    setStatus(value);
  }

  function handleViewClick(selectedPayment) {
    fetchPayment(selectedPayment.id).then((payment) => {
      setShowViewPaymentModal(true);
      setSelectedPayment(payment);
    });
  }

  const dataGridColumns = [
    {
      field: "referenceNumber",
      headerName: "Reference",
      width: 100,
    },
    {
      field: "payee",
      headerName: "Payee",
      minWidth: 145,
      flex: 1.45,
      renderCell: renderCellExpand,
    },
    {
      field: "date",
      headerName: "Date Paid",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 90,
      flex: 0.9,
      valueFormatter: (row) => {
        return titleCase(row.value.name);
      },
    },
    {
      field: "approvedBy",
      headerName: "Approved By",
      minWidth: 130,
      flex: 1.3,
    },
    {
      field: "approvedOn",
      headerName: "Approve Date",
      minWidth: 150,
      flex: 1.5,
      renderCell: renderCellExpand,
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value)) {
          return formatDateTime(row.value);
        }
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 175,
      flex: 1.75,
      renderCell: renderCellExpand,
    },
    {
      field: "workOrder",
      headerName: "Work Order",
      minWidth: 150,
      flex: 1.5,
      renderCell: renderCellExpand,
    },
    {
      field: "appliedTo",
      headerName: "Applied To",
      minWidth: 150,
      flex: 1.5,
      renderCell: renderCellExpand,
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      minWidth: 150,
      flex: 1.5,
      valueFormatter: (row) => {
        return titleCase(row.value.name);
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 80,
      flex: 0.8,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewClick(params.row)}
            label="View"
            showInMenu
          />
        );

        arr.push(
          <GridActionsCellItem
            icon={<LocalPrintshop />}
            onClick={() => {
              printPaymentVoucher(params.row.id);
            }}
            label="Print"
            showInMenu
          />
        );
        return arr;
      },
    },
  ];

  function handleExpandClick() {
    setExpanded(true);
    setTask("Search");
  }

  function handleClearSearch() {
    setReference("");
    setDescription("");
    setPayees([]);
    setStatus("");
    setDate("");
  }

  function handleCloseSearch() {
    handleClearSearch();
    loadPayments(paginationModel);
    setExpanded(false);
    setTask("Result");
    setSearchResult(false);
  }

  function handleSearchClicked() {
    setLoading(true);
    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      status: status,
      paymentDate: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(date) && isValidDate(date)) {
      searchObject.paymentDate = adjustForTimezone(new Date(date));
    }
    getPayments(searchObject).then((payments) => {
      setPayments(payments);
      setExpanded(false);
      setTask("Result");
      setSearchResult(true);
    });

    getTotalPayments(searchObject).then((response) => {
      setTotalPayments(response.totalPayments);
    });
    setLoading(false);
  }

  function handleMakePaymentClose() {
    setShowMakePaymentModal(false);
    setSelectedPayment(null);
    setTask("Result");
    setExpanded(false);
  }

  function handleMakePaymentClick() {
    const data = {
      id: null,
      chequeNumber: "",
      vendorId: null,
      date: null,
      paymentMethodCd: "",
      description: "",
      amount: null,
      cashAccount: null,
    };
    setSelectedPayment(data);
    setShowMakePaymentModal(true);
  }

  function handleViewPaymentClose() {
    setShowViewPaymentModal(false);
    setSelectedPayment(null);
  }

  function savePayment(uploadData) {
    savePaymentData(uploadData).then((response) => {
      issueResponseMessage(response);
      handleMakePaymentClose();
      handleSearchClicked();
    });
  }

  return (
    <ResponsiveRow
      sx={{
        width: "100%",
        height: "auto",
        padding: { xs: 0, lg: "10px" },
        margin: "0",
      }}
    >
      <BillingPaymentsTabBar
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        handleCloseSearch={handleCloseSearch}
        handleMakePaymentClick={handleMakePaymentClick}
        searchResult={searchResult}
      />
      {task !== "Result" && (
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: { xs: 0, lg: "10px" },
          }}
          container
        >
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              marginBottom: "10px ",
            }}
          >
            <InputLabel>Ref Number</InputLabel>
            <OutlinedInput
              label={"Ref Number"}
              sx={{ width: { xs: "100%", lg: "90%" }, marginBottom: "10px" }}
              id={"reference"}
              onChange={(event) => onTextChange(event)}
              value={reference}
            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              marginBottom: "10px",
            }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label={"Description"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"description"}
              onChange={(event) => onTextChange(event)}
              value={description}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Payee(s)</InputLabel>
            <Select
              label="Payee(s)"
              multiple
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"payees"}
              value={payees}
              onChange={(event) => handlePayeeChange(event)}
            >
              {payeeOptions && payeeOptions.length > 0 ? (
                payeeOptions.map(function (unit, i) {
                  return (
                    <MenuItem key={i} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel id="statusLabel">Status</InputLabel>
            <Select
              label="Status"
              displayEmpty
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"status"}
              value={status}
              onChange={(event) => onStatusChange(event.target.value)}
            >
              {statuses && statuses.length > 0 ? (
                statuses.map(function (statusValue, i) {
                  return (
                    <MenuItem key={statusValue.value} value={statusValue.value}>
                      {statusValue.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                value={isEmptyString(date) ? null : dayjs(date)}
                onChange={(newDate) => setDate(adjustForTimezone(new Date(newDate)))}
              />
            </LocalizationProvider>
          </FormControl>

          <ResponsiveRow
            item
            sx={{
              width: "100%",
              height: "auto",
              marginBottom: { xs: 0, lg: "10px" },
              justifyContent: { xs: "center", lg: "flex-end" },
            }}
          >
            <ModalCancelButton
              onClick={handleClearSearch}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Clear
            </ModalCancelButton>
            <ModalAcceptButton
              onClick={handleSearchClicked}
              disabled={disableSearch}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Search
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}
      {task === "Result" && (
        <MuiDataGrid
          loading={loading}
          noBoxShadow={true}
          noSelection={true}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          dataGridColumns={dataGridColumns}
          dataGridRows={payments}
          height="50vh"
          totalRows={totalPayments}
        />
      )}

      {/*VIEW PAYMENT MODAL*/}
      {showViewPaymentModal && (
        <ViewPayment
          openModal={showViewPaymentModal}
          handleClose={handleViewPaymentClose}
          selectedPayment={selectedPayment}
        />
      )}

      {/*MAKE PAYMENT MODAL*/}
      {showMakePaymentModal && (
        <CreatePayment
          openModal={showMakePaymentModal}
          handleClose={handleMakePaymentClose}
          savePayment={savePayment}
          selectedPayment={selectedPayment}
        />
      )}
    </ResponsiveRow>
  );
}
