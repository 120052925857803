import {
  Alert,
  AlertTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import EmptyOverlay from "../overlays/emptyOverlay";
import { useEffect, useState } from "react";
import { isNullUndefined } from "../../util/util";

export default function ContractContactModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleContactClose
   * handlePersonOrgSelect
   * handleContactClear
   * handleIdTypeChange
   * searchPersonOrg
   *
   * contactModal
   * contactTableRows
   * foundTenant
   * idNoSearch
   * idTypes
   * onTextChange
   * selectedContract
   * contractType
   *
   */

  const [contactSearched, setContactSearched] = useState(false);
  let [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    let tmp = "";
    if (
      !isNullUndefined(
        props.selectedContract && props.selectedContract.billingCode
      )
    ) {
      tmp = `(` + props.selectedContract.billingCode + `)`;
    }
    tmp = props.contractType + " " + tmp + " Contact";
    setModalTitle(tmp);
  }, [props]);

  return (
    <ModalContainerModule
      size="sm"
      cancel={props.handleContactClose}
      openModal={props.contactModal}
      modalTitle={modalTitle}
    >
      <ResponsiveRow
        item
        sx={{
          width: "100%",
          height: "100%",
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",
          justifyContent: { xs: "flex-start", lg: "space-between" },
          alignItems: { xs: "center", lg: "flex-start" },
          overflowY: "auto",
          padding: 0,
        }}
      >
        <ResponsiveRow
          sx={{
            width: "100%",
            height: { xs: "auto", lg: "100%" },
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: "10px auto 20px auto",
            padding: "10px",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="modal-modal-title"
            variant="h5"
            component="h4"
          >
            Add New Contacts
          </Typography>
          <Alert severity="info" sx={{ width: "100%", height: "auto" }}>
            Use this form to search for an existing individual or create new
            contact person.
          </Alert>
          <ResponsiveRow
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              height: "auto",
              padding: "10px",
            }}
          >
            <ResponsiveRow
              container
              sx={{
                width: "100%",
                height: "auto",
                justifyContent: { xs: "center", lg: "space-between" },
                alignItems: "flex-start",
                padding: 0,
              }}
            >
              <FormControl
                item
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "5px" }}
              >
                <InputLabel>ID Number</InputLabel>
                <OutlinedInput
                  label="ID Number"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"idNoSearch"}
                  value={props.idNoSearch}
                  onChange={(searchText) => props.onTextChange(searchText)}
                  inputProps={
                    <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
                  }
                />
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
                <InputLabel>ID Type</InputLabel>
                <Select
                  label="ID Type"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"idTypeSearch"}
                  value={props.idTypeSearch}
                  onChange={(event) => props.handleIdTypeChange(event)}
                >
                  {props.idTypes && props.idTypes.length > 0 ? (
                    props.idTypes.map(function (idType, i) {
                      return (
                        <MenuItem key={i} value={idType.value}>
                          {idType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              <ResponsiveRow
                item
                sx={{
                  justifyContent: { xs: "center", lg: "flex-end" },
                  marginTop: "20px",
                  width: "100%",
                  height: "auto",
                }}
              >
                <ModalAcceptButton
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    setContactSearched(true);
                    props.searchPersonOrg();
                  }}
                  sx={{
                    backgroundColor: "#037171",
                    margin: { xs: "10px 0 0 ", lg: "0 0 0 10px" },
                    width: { xs: "100%", lg: "150px" },
                    height: "40px",
                  }}
                >
                  Search
                </ModalAcceptButton>
                <ModalCancelButton
                  variant="contained"
                  type="submit"
                  onClick={props.handleContactClear}
                  sx={{
                    margin: { xs: "10px 0 0 ", lg: "0 0 0 10px" },
                    width: { xs: "100%", lg: "150px" },
                    height: "40px",
                  }}
                  color="error"
                >
                  Clear
                </ModalCancelButton>
              </ResponsiveRow>

              {contactSearched === true &&
                props.foundTenant &&
                props.foundTenant.length === 0 && (
                  <Alert
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      marginTop: "20px",
                      padding: "20x 0 0 0",
                    }}
                    severity="error"
                  >
                    <AlertTitle color="error">No Individuals found</AlertTitle>
                    <Typography sx={{ width: "100%" }}>
                      There were no individuals found with that ID number. Would
                      you like to create a new individuals?
                    </Typography>
                    <ResponsiveRow
                      sx={{
                        width: "100%",
                        justifyContent: "flex-end",
                        height: "auto",
                      }}
                    >
                      <Grid sx={{ width: "100%" }}>
                        <ModalAcceptButton
                          onClick={() =>
                            props.showAddNewContactForm(
                              props.idNoSearch,
                              props.idTypeSearch
                            )
                          }
                          sx={{ width: { xs: "100%", lg: "100px" } }}
                        >
                          Yes
                        </ModalAcceptButton>
                        <ModalCancelButton
                          onClick={props.handleContactClose}
                          sx={{ width: { xs: "100%", lg: "100px" } }}
                        >
                          No
                        </ModalCancelButton>
                      </Grid>
                    </ResponsiveRow>
                  </Alert>
                )}
            </ResponsiveRow>
            {contactSearched === true &&
              props.foundTenant &&
              props.foundTenant.length > 0 && (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.foundTenant.map(function (tenant, i) {
                          return (
                            <TableRow key={i}>
                              <TableCell>{tenant.name}</TableCell>
                              <TableCell>
                                <ModalAcceptButton
                                  variant="contained"
                                  type="submit"
                                  onClick={() =>
                                    props.handlePersonOrgSelect(tenant)
                                  }
                                  sx={{ backgroundColor: "#037171" }}
                                >
                                  Add
                                </ModalAcceptButton>
                              </TableCell>
                            </TableRow>
                          );
                        }, this)}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </ResponsiveRow>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: { xs: "auto", lg: "100%" },
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "0",
            margin: "10px auto 20px auto",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="modal-modal-title"
            variant="h5"
            component="h4"
          >
            Contacts
          </Typography>
          {props.contactTableRows ? (
            <TableContainer sx={{ height: "100%", width: "100%" }}>
              <Table
                sx={{ minWidth: 20 }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%", color: "#037171" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ width: "30%", color: "#037171" }}>
                      Phone Number
                    </TableCell>
                    <TableCell sx={{ width: "20%", color: "#037171" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    height: "100px",
                    overflowY: "auto",
                    textAlign: "center",
                  }}
                >
                  {props.contactTableRows}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <ResponsiveRow
              sx={{
                width: "100%",
                height: "150px",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <EmptyOverlay />
            </ResponsiveRow>
          )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
