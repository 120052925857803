/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  DetailsLabel,
  DetailsText,
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  getDaysOfWeek,
  getEmailReportPeriods,
  getIdTypes,
  getRentDays,
} from "../../services/list.service";
import { isEmptyArray, isEmptyString, isNullUndefined } from "../../util/util";
import Permit from "../../util/permit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "@mui/lab";
import { useSelector } from "react-redux";
import { ResponsiveRow } from "../../constants/layout.constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { issueWarnMessage } from "../../actions/message";
import dayjs from "dayjs";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import kodi_theme from "../../themes/kodiThemeProvider";

const FormContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  align: "left",
  alignItems: "left",
}));

export default function UserDetailsCard(props) {
  const [userExpanded, setUserExpanded] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [passwordExpanded, setPasswordExpanded] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [notificationExpanded, setNotificationExpanded] = useState(false);
  const [reportExpanded, setReportExpanded] = useState(false);
  const [idTypes, setIdTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState(props.selectedUser);
  const [notification, setNotification] = useState(props.notification);
  const [emailNotification, setEmailNotification] = useState(
    props.emailNotification
  );
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idTypeCd, setIdTypeCd] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invoicing, setInvoicing] = useState(false);
  const [meterReading, setMeterReading] = useState(false);
  const [onBoarding, setOnBoarding] = useState(false);
  const [payment, setPayment] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [report, setReport] = useState(false);
  const [reportPeriods, setReportPeriods] = useState([]);
  const [rentDays, setRentDays] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [emailNotificationFields, setEmailNotificationFields] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    getIdTypes().then((idTypes) => setIdTypes(idTypes));
    getEmailReportPeriods().then((reportPeriods) =>
      setReportPeriods(reportPeriods)
    );
    getRentDays().then((rentDays) => setRentDays(rentDays));
    getDaysOfWeek().then((daysOfWeek) => setDaysOfWeek(daysOfWeek));
  }, []);

  useEffect(() => {
    setSelectedUser(props.selectedUser);
    setFirstName(props.selectedUser.firstName);
    setMiddleName(props.selectedUser.middleName);
    setLastName(props.selectedUser.lastName);
    setIdNumber(props.selectedUser.idNumber);
    setIdTypeCd(props.selectedUser.idTypeCd);
    setMobilePhone(props.selectedUser.mobilePhone);
    setOfficePhone(props.selectedUser.officePhone);
    setEmail(props.selectedUser.email);
  }, [props.selectedUser]);

  useEffect(() => {
    setNotification(props.notification);
    setInvoicing(props.notification.INV);
    setMeterReading(props.notification.MTRDG);
    setOnBoarding(props.notification.ONBRD);
    setPayment(props.notification.PAY);
    setPendingApproval(props.notification.PENAP);
    setReminder(props.notification.REM);
    setReport(props.notification.REPRT);
  }, [props.notification]);

  useEffect(() => {
    setEmailNotification(props.emailNotification);
    populateEmailNotification();
    let tmp = props.emailNotification;
    populateEmailNotification();
    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.balSheetDate) &&
      !isEmptyString(props.emailNotification.balSheetDate)
    ) {
      tmp.balSheetDate = new Date(props.emailNotification.balSheetDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.collReportDate) &&
      !isEmptyString(props.emailNotification.collReportDate)
    ) {
      tmp.collReportDate = new Date(props.emailNotification.collReportDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.dailySalesDate) &&
      !isEmptyString(props.emailNotification.dailySalesDate)
    ) {
      tmp.dailySalesDate = new Date(props.emailNotification.dailySalesDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.elecIncReportDate) &&
      !isEmptyString(props.emailNotification.elecIncReportDate)
    ) {
      tmp.elecIncReportDate = new Date(
        props.emailNotification.elecIncReportDate
      );
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.incStatementDate) &&
      !isEmptyString(props.emailNotification.incStatementDate)
    ) {
      tmp.incStatementDate = new Date(props.emailNotification.incStatementDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.mobileReportDate) &&
      !isEmptyString(props.emailNotification.mobileReportDate)
    ) {
      tmp.mobileReportDate = new Date(props.emailNotification.mobileReportDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.mtrVarianceDate) &&
      !isEmptyString(props.emailNotification.mtrVarianceDate)
    ) {
      tmp.mtrVarianceDate = new Date(props.emailNotification.mtrVarianceDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.recReportDate) &&
      !isEmptyString(props.emailNotification.recReportDate)
    ) {
      tmp.recReportDate = new Date(props.emailNotification.recReportDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.vacUnitsReportDate) &&
      !isEmptyString(props.emailNotification.vacUnitsReportDate)
    ) {
      tmp.vacUnitsReportDate = new Date(
        props.emailNotification.vacUnitsReportDate
      );
    }
    populateEmailNotification();
    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.vatReportDate) &&
      !isEmptyString(props.emailNotification.vatReportDate)
    ) {
      tmp.vatReportDate = new Date(props.emailNotification.vatReportDate);
    }

    if (
      !isNullUndefined(props.emailNotification) &&
      !isNullUndefined(props.emailNotification.wtrIncReportDate) &&
      !isEmptyString(props.emailNotification.wtrIncReportDate)
    ) {
      tmp.wtrIncReportDate = new Date(props.emailNotification.wtrIncReportDate);
    }

    setEmailNotification(tmp);
    populateEmailNotification();
  }, [props.emailNotification]);

  useEffect(() => {
    populateEmailNotification();
    populateEmailNotification();
  }, [emailNotification]);

  function populateEmailNotification() {
    setEmailNotificationFields(emailNotificationF());
  }

  function emailNotificationF() {
    return (
      <ResponsiveRow container>
        {/* Balance Sheet */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow item sx={{ width: { xs: "100%", lg: "50%" } }}>
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.BALSH}
                onChange={(event) => handleToggle(event, "BALSH")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Balance Sheet Report
            </Grid>

            {emailNotification &&
              emailNotification.BALSH &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.balSheetPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "BALSH")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.BALSH &&
              emailNotification.balSheetPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.balSheetDay}
                    onChange={(event) =>
                      handleDayChange(event.target.value, "BALSH")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.BALSH &&
              emailNotification.balSheetPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.balSheetDay}
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "BALSH")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.BALSH &&
              (emailNotification.balSheetPeriod === "QT" ||
                emailNotification.balSheetPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.balSheetDate)
                      }
                      onChange={(newDate) => {
                        console.log("emailNotification", emailNotification);
                        handleReportDateChange(newDate, "BALSH");
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Collections Report */}
        <Permit roles="BLDIR,BLDMG,CS" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.COLL}
                onChange={(event) => handleToggle(event, "COLL")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Collections Report
            </Grid>

            {emailNotification &&
              emailNotification.COLL &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.collReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "COLL")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.COLL &&
              emailNotification.collReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.collReportDay}
                    onChange={(event) =>
                      handleDayChange(event.target.value, "COLL")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.COLL &&
              emailNotification.collReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.collReportDay}
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "COLL")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.COLL &&
              (emailNotification.collReportPeriod === "QT" ||
                emailNotification.collReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.collReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "COLL")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Daily Sales Report */}
        <Permit roles="BLDIR" services="BLWTR">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.DS}
                onChange={(event) => handleToggle(event, "DS")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Daily Sales Report
            </Grid>

            {emailNotification &&
              emailNotification.DS &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.dailySalesPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "DS")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.DS &&
              emailNotification.dailySalesPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.dailySalesDay}
                    onChange={(event) =>
                      handleDayChange(event.target.value, "DS")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.DS &&
              emailNotification.dailySalesPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.dailySalesDay}
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "DS")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.DS &&
              (emailNotification.dailySalesPeriod === "QT" ||
                emailNotification.dailySalesPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.dailySalesDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "DS")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Utilities Electricity Income Report */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.ELEC}
                onChange={(event) => handleToggle(event, "ELEC")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Utilities Electricity Income Report
            </Grid>

            {emailNotification &&
              emailNotification.ELEC &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.elecIncReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "ELEC")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.ELEC &&
              emailNotification.elecIncReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.elecIncReportDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "ELEC")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.ELEC &&
              emailNotification.elecIncReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.elecIncReportDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "ELEC")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.ELEC &&
              (emailNotification.elecIncReportPeriod === "QT" ||
                emailNotification.elecIncReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.elecIncReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "ELEC")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Income Statement */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.INC}
                onChange={(event) => handleToggle(event, "INC")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Income Statement Report
            </Grid>

            {emailNotification &&
              emailNotification.INC &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.incStatementPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "INC")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.INC &&
              emailNotification.incStatementPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.incStatementDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "INC")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.INC &&
              emailNotification.incStatementPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.incStatementDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "INC")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.INC &&
              (emailNotification.incStatementPeriod === "QT" ||
                emailNotification.incStatementPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.incStatementDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "INC")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Mobile Money Receipt Report */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.MOB}
                onChange={(event) => handleToggle(event, "MOB")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Mobile Money Receipt Report
            </Grid>

            {emailNotification &&
              emailNotification.MOB &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mobileReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "MOB")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MOB &&
              emailNotification.mobileReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mobileReportDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "MOB")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MOB &&
              emailNotification.mobileReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mobileReportDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "MOB")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MOB &&
              (emailNotification.mobileReportPeriod === "QT" ||
                emailNotification.mobileReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.mobileReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "MOB")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Meter Variance Report */}
        <Permit roles="BLDIR" services="BLWTR">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.MTRV}
                onChange={(event) => handleToggle(event, "MTRV")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Meter Variance Report
            </Grid>

            {emailNotification &&
              emailNotification.MTRV &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mtrVariancePeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "MTRV")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MTRV &&
              emailNotification.mtrVariancePeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mtrVarianceDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "MTRV")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MTRV &&
              emailNotification.mtrVariancePeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.mtrVarianceDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "MTRV")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.MTRV &&
              (emailNotification.mtrVariancePeriod === "QT" ||
                emailNotification.mtrVariancePeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.mtrVarianceDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "MTRV")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Reconciliation Report */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.RECN}
                onChange={(event) => handleToggle(event, "RECN")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Reconciliation Report
            </Grid>

            {emailNotification &&
              emailNotification.RECN &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.recReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "RECN")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.RECN &&
              emailNotification.recReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.recReportDay}
                    onChange={(event) =>
                      handleDayChange(event.target.value, "RECN")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.RECN &&
              emailNotification.recReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.recReportDay}
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "RECN")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.RECN &&
              (emailNotification.recReportPeriod === "QT" ||
                emailNotification.recReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.recReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "RECN")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Vaccant Units Report */}
        <Permit roles="BLDIR,BLDMG" services="METER,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.VAC}
                onChange={(event) => handleToggle(event, "VAC")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Vaccant Units Report
            </Grid>

            {emailNotification &&
              emailNotification.VAC &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification &&
                      emailNotification.vacUnitsReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "VAC")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAC &&
              emailNotification.vacUnitsReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.vacUnitsReportDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "VAC")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAC &&
              emailNotification.vacUnitsReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.vacUnitsReportDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "VAC")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAC &&
              (emailNotification.vacUnitsReportPeriod === "QT" ||
                emailNotification.vacUnitsReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.vacUnitsReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "VAC")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* VAT Input Output Report */}
        <Permit roles="ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.VAT}
                onChange={(event) => handleToggle(event, "VAT")}
                inputProps={{ "aria-label": "controlled" }}
              />
              VAT Input Output Report
            </Grid>

            {emailNotification &&
              emailNotification.VAT &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.vatReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "VAT")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAT &&
              emailNotification.vatReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.vatReportDay}
                    onChange={(event) =>
                      handleDayChange(event.target.value, "VAT")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAT &&
              emailNotification.vatReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={emailNotification && emailNotification.vatReportDay}
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "VAT")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.VAT &&
              (emailNotification.vatReportPeriod === "QT" ||
                emailNotification.vatReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.vatReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "VAT")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Utilities Water Income Report */}
        <Permit roles="BLDIR,ACC" services="METER,HOA,PREMIUM,LEAN,CORE">
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginTop: "20px" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Switch
                checked={emailNotification && emailNotification.WTR}
                onChange={(event) => handleToggle(event, "WTR")}
                inputProps={{ "aria-label": "controlled" }}
              />
              Utilities Water Income Report
            </Grid>

            {emailNotification &&
              emailNotification.WTR &&
              reportPeriods &&
              reportPeriods.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.wtrIncReportPeriod
                    }
                    onChange={(event) =>
                      handlePeriodChange(event.target.value, "WTR")
                    }
                  >
                    {reportPeriods && reportPeriods.length > 0 ? (
                      reportPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.WTR &&
              emailNotification.wtrIncReportPeriod === "MNTH" &&
              rentDays &&
              rentDays.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.wtrIncReportDay
                    }
                    onChange={(event) =>
                      handleDayChange(event.target.value, "WTR")
                    }
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.WTR &&
              emailNotification.wtrIncReportPeriod === "WEEK" &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <Select
                    sx={{ width: "90%" }}
                    value={
                      emailNotification && emailNotification.wtrIncReportDay
                    }
                    onChange={(event) =>
                      handleDayOfWeekChange(event.target.value, "WTR")
                    }
                    defaultValue={[""]}
                  >
                    {daysOfWeek && daysOfWeek.length > 0 ? (
                      daysOfWeek.map(function (dayOfWeek, i) {
                        return (
                          <MenuItem
                            key={dayOfWeek.value}
                            value={dayOfWeek.value}
                          >
                            {dayOfWeek.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

            {emailNotification &&
              emailNotification.WTR &&
              (emailNotification.wtrIncReportPeriod === "QT" ||
                emailNotification.wtrIncReportPeriod === "ANU") &&
              daysOfWeek &&
              daysOfWeek.length > 0 && (
                <Grid item sx={{ width: "100%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: "90%" }}
                      value={
                        emailNotification &&
                        dayjs(emailNotification.wtrIncReportDate)
                      }
                      onChange={(newDate) =>
                        handleReportDateChange(newDate, "WTR")
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              )}
          </ResponsiveRow>
        </Permit>

        {/* Buttons */}
        <ResponsiveRow
          sx={{
            marginBottom: "10px",
            justifyContent: { xs: "center", lg: "flex-end" },
            marginTop: "2%",
          }}
        >
          <Permit roles="BLDIR,BLDMG,ACC">
            <Grid
              item
              style={{ marginBottom: "10px", float: "right", marginTop: "2%" }}
            >
              <ModalAcceptButton
                // type="submit"
                onClick={handleUpdateReportClick}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                }}
              >
                Update
              </ModalAcceptButton>
              <ModalCancelButton
                // type="submit"
                onClick={handleExpandClose}
                sx={{ width: { xs: "100%", lg: "200px" } }}
              >
                Cancel
              </ModalCancelButton>
            </Grid>
          </Permit>
        </ResponsiveRow>
      </ResponsiveRow>
    );
  }

  function handleUserExpandClick() {
    setUserExpanded(!userExpanded);
    setPasswordExpanded(false);
    setNotificationExpanded(false);
    setReportExpanded(false);
  }

  function handleExpandClose() {
    setUserExpanded(false);
    setPasswordExpanded(false);
    setNotificationExpanded(false);
    setReportExpanded(false);
  }

  function handleUpdateUserClick() {
    let tmp = selectedUser;
    tmp.firstName = firstName;
    tmp.middleName = middleName;
    tmp.lastName = lastName;
    tmp.idNumber = idNumber;
    tmp.idTypeCd = idTypeCd;
    tmp.mobilePhone = mobilePhone;
    tmp.officePhone = officePhone;
    tmp.email = email;
    handleExpandClose();
    props.handleUpdatePersonDetailsClick(tmp);
  }

  function handleUpdateNotificationClick() {
    let tmp = notification;
    tmp.INV = invoicing;
    tmp.MTRDG = meterReading;
    tmp.ONBRD = onBoarding;
    tmp.PAY = payment;
    tmp.PENAP = pendingApproval;
    tmp.REM = reminder;
    tmp.REPRT = report;
    handleExpandClose();
    props.handleUpdateNotificationsClick(tmp);
  }

  function validateForm(emailNotification) {
    if (isNullUndefined(emailNotification)) {
      issueWarnMessage("Could not determine item to update");
      return false;
    }
    if (
      (emailNotification.BALSH &&
        isEmptyString(emailNotification.balSheetPeriod)) ||
      (emailNotification.INC &&
        isEmptyString(emailNotification.incStatementPeriod)) ||
      (emailNotification.COLL &&
        isEmptyString(emailNotification.collReportPeriod)) ||
      (emailNotification.VAT &&
        isEmptyString(emailNotification.vatReportPeriod)) ||
      (emailNotification.WTR &&
        isEmptyString(emailNotification.wtrIncReportPeriod)) ||
      (emailNotification.ELEC &&
        isEmptyString(emailNotification.elecIncReportPeriod)) ||
      (emailNotification.MOB &&
        isEmptyString(emailNotification.mobileReportPeriod)) ||
      (emailNotification.VAC &&
        isEmptyString(emailNotification.vacUnitsReportPeriod)) ||
      (emailNotification.MTRV &&
        isEmptyString(emailNotification.mtrVariancePeriod)) ||
      (emailNotification.DS &&
        isEmptyString(emailNotification.dailySalesPeriod)) ||
      (emailNotification.RECN &&
        isEmptyString(emailNotification.recReportPeriod))
    ) {
      issueWarnMessage("Could not determine frequency");
      return false;
    }

    return true;
  }

  function handleUpdateReportClick() {
    if (validateForm(emailNotification)) {
      handleExpandClose();
      props.handleUpdateReportClick(emailNotification);
    }
  }

  function handlePasswordExpandClick() {
    setUserExpanded(false);
    setPasswordExpanded(!passwordExpanded);
    setNotificationExpanded(false);
    setReportExpanded(false);
  }

  function handleChangePasswordClick() {
    if (newPassword === confirmPassword) {
      let params = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      handleExpandClose();
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      props.handleUpdatePasswordClick(params);
    } else {
      issueWarnMessage("New password and confirm password should be equal");
    }
  }

  function handleNotificationExpandClick() {
    setUserExpanded(false);
    setPasswordExpanded(false);
    setNotificationExpanded(!notificationExpanded);
    setReportExpanded(false);
  }

  function handleReportExpandClick() {
    setUserExpanded(false);
    setPasswordExpanded(false);
    setNotificationExpanded(false);
    setReportExpanded(!reportExpanded);
    populateEmailNotification();
  }

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "firstName") {
      setFirstName(value);
    }
    if (id === "middleName") {
      setMiddleName(value);
    }
    if (id === "lastName") {
      setLastName(value);
    }
    if (id === "idNumber") {
      setIdNumber(value);
    }
    if (id === "mobilePhone") {
      setMobilePhone(value);
    }
    if (id === "officePhone") {
      setOfficePhone(value);
    }
    if (id === "email") {
      setEmail(value);
    }
    if (id === "currentPassword") {
      setCurrentPassword(value);
    }
    if (id === "newPassword") {
      setNewPassword(value);
    }
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    }
  }

  function handleIdTypeChange(event) {
    let value = event.target.value;
    setIdTypeCd(value);
  }

  function handleToggle(event, id) {
    let value = event.target.checked;
    if (id === "invoicing") {
      setInvoicing(value);
    }
    if (id === "meterReading") {
      setMeterReading(value);
    }
    if (id === "onBoarding") {
      setOnBoarding(value);
    }
    if (id === "payment") {
      setPayment(value);
    }
    if (id === "pendingApproval") {
      setPendingApproval(value);
    }
    if (id === "reminder") {
      setReminder(value);
    }
    if (id === "report") {
      setReport(value);
    }
    if (id === "BALSH") {
      let tmp = emailNotification;
      tmp.BALSH = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "COLL") {
      let tmp = emailNotification;
      tmp.COLL = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "DS") {
      let tmp = emailNotification;
      tmp.DS = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "ELEC") {
      let tmp = emailNotification;
      tmp.ELEC = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "INC") {
      let tmp = emailNotification;
      tmp.INC = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MOB") {
      let tmp = emailNotification;
      tmp.MOB = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MTRV") {
      let tmp = emailNotification;
      tmp.MTRV = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "RECN") {
      let tmp = emailNotification;
      tmp.RECN = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAC") {
      let tmp = emailNotification;
      tmp.VAC = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAT") {
      let tmp = emailNotification;
      tmp.VAT = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "WTR") {
      let tmp = emailNotification;
      tmp.WTR = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
  }

  function handlePeriodChange(value, id) {
    if (id === "BALSH") {
      let tmp = emailNotification;
      tmp.balSheetPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
    if (id === "COLL") {
      let tmp = emailNotification;
      tmp.collReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "DS") {
      let tmp = emailNotification;
      tmp.dailySalesPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "ELEC") {
      let tmp = emailNotification;
      tmp.elecIncReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "INC") {
      let tmp = emailNotification;
      tmp.incStatementPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MOB") {
      let tmp = emailNotification;
      tmp.mobileReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MTRV") {
      let tmp = emailNotification;
      tmp.mtrVariancePeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "RECN") {
      let tmp = emailNotification;
      tmp.recReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
    if (id === "VAC") {
      let tmp = emailNotification;
      tmp.vacUnitsReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAT") {
      let tmp = emailNotification;
      tmp.vatReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
    if (id === "WTR") {
      let tmp = emailNotification;
      tmp.wtrIncReportPeriod = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
  }

  function handleDayChange(value, id) {
    if (id === "BALSH") {
      let tmp = emailNotification;
      tmp.balSheetDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "COLL") {
      let tmp = emailNotification;
      tmp.collReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "DS") {
      let tmp = emailNotification;
      tmp.dailySalesDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "ELEC") {
      let tmp = emailNotification;
      tmp.elecIncReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "INC") {
      let tmp = emailNotification;
      tmp.incStatementDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MOB") {
      let tmp = emailNotification;
      tmp.mobileReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MTRV") {
      let tmp = emailNotification;
      tmp.mtrVarianceDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "RECN") {
      let tmp = emailNotification;
      tmp.recReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAC") {
      let tmp = emailNotification;
      tmp.vacUnitsReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAT") {
      let tmp = emailNotification;
      tmp.vatReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "WTR") {
      let tmp = emailNotification;
      tmp.wtrIncReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
  }

  function handleDayOfWeekChange(value, id) {
    if (id === "BALSH") {
      let tmp = emailNotification;
      tmp.balSheetDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "COLL") {
      let tmp = emailNotification;
      tmp.collReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "DS") {
      let tmp = emailNotification;
      tmp.dailySalesDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "ELEC") {
      let tmp = emailNotification;
      tmp.elecIncReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "INC") {
      let tmp = emailNotification;
      tmp.incStatementDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MOB") {
      let tmp = emailNotification;
      tmp.mobileReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MTRV") {
      let tmp = emailNotification;
      tmp.mtrVarianceDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "RECN") {
      let tmp = emailNotification;
      tmp.recReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAC") {
      let tmp = emailNotification;
      tmp.vacUnitsReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAT") {
      let tmp = emailNotification;
      tmp.vatReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "WTR") {
      let tmp = emailNotification;
      tmp.wtrIncReportDay = value;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
  }

  function handleReportDateChange(value, id) {
    let date = new Date(value);
    if (id === "BALSH") {
      let tmp = emailNotification;
      tmp.balSheetDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
    if (id === "COLL") {
      let tmp = emailNotification;
      tmp.collReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "DS") {
      let tmp = emailNotification;
      tmp.dailySalesDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "ELEC") {
      let tmp = emailNotification;
      tmp.elecIncReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "INC") {
      let tmp = emailNotification;
      tmp.incStatementDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MOB") {
      let tmp = emailNotification;
      tmp.mobileReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "MTRV") {
      let tmp = emailNotification;
      tmp.mtrVarianceDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "RECN") {
      let tmp = emailNotification;
      tmp.recReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAC") {
      let tmp = emailNotification;
      tmp.vacUnitsReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "VAT") {
      let tmp = emailNotification;
      tmp.vatReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }

    if (id === "WTR") {
      let tmp = emailNotification;
      tmp.wtrIncReportDate = date;
      setEmailNotification(tmp);
      populateEmailNotification();
    }
  }

  return (
    <Grid
      container
      spacing={1}
      sx={{
        padding: "20px",
        width: { xs: "100%", md: "100%" },
        backgroundColor: "#fff",
        borderRadius: "10px",
        margin: "20px auto",
        // boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          margin: "auto 20px",
          width: "100%",
          height: "fit-content",
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: "18px",
          }}
          variant="h4"
        >
          User Details
        </Typography>
        <ResponsiveRow
          sx={{
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <ResponsiveRow
            sx={{
              display: "flex",
              width: { xs: "100%", lg: "33.3%" },
              justifyContent: "flex-start",
            }}
          >
            <DetailsLabel
              sx={{
                fontWeight: 700,
                textAlign: "start",
                maxWidth: "30%",
              }}
              variant="h6"
            >
              Name:
            </DetailsLabel>
            <DetailsText
              sx={{
                color: "#037171",
                marginLeft: "5%",
                textAlign: "start",
                fontWeight: 600,
              }}
              variant="h6"
            >
              {props &&
                props.selectedUser &&
                props.selectedUser.firstName +
                  " " +
                  props.selectedUser.lastName}
            </DetailsText>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              width: { xs: "100%", lg: "33.3%" },
            }}
          >
            <DetailsLabel
              sx={{
                fontWeight: 700,
                textAlign: "start",
              }}
              variant="h6"
            >
              UserName:
            </DetailsLabel>
            <DetailsText
              sx={{
                color: "#037171",
                textAlign: "start",
                marginLeft: "5%",
                fontWeight: 600,
              }}
              variant="h6"
            >
              {props &&
                props.selectedUser &&
                props.selectedUser.username &&
                props.selectedUser.username.replace(/"/g, "")}
            </DetailsText>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              width: { xs: "100%", lg: "33.3%" },
            }}
          >
            <DetailsLabel
              sx={{
                fontWeight: 700,
                textAlign: "start",
              }}
              variant="h6"
            >
              Role:
            </DetailsLabel>
            <DetailsText
              sx={{
                color: "#037171",
                textAlign: "start",
                marginLeft: "5%",
                fontWeight: 600,
              }}
              variant="h6"
            >
              {!isNullUndefined(props.selectedUser) &&
                !isEmptyArray(props.selectedUser.rolesList) &&
                (props.selectedUser.rolesList.find((role) => {
                  return role === "SYSAD";
                })
                  ? "System Administrator"
                  : props.selectedUser.rolesList.find((role) => {
                      return role === "BLDIR";
                    })
                  ? "Building Director"
                  : props.selectedUser.rolesList.find((role) => {
                      return role === "BLDMG";
                    })
                  ? "Building Manager"
                  : props.selectedUser.rolesList.find((role) => {
                      return role === "TEN";
                    })
                  ? "Tenant"
                  : props.selectedUser.rolesList.find((role) => {
                      return role === "CLK";
                    })
                  ? "Security Officer"
                  : props.selectedUser.rolesList.find((role) => {
                      return role === "REC";
                    })
                  ? "Receptionist"
                  : "No Role")}
            </DetailsText>
          </ResponsiveRow>
        </ResponsiveRow>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
          backgroundColor: "#efefef",
          padding: "10px",
          borderRadius: "10px",
          margin: "20px 0",
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            width: "100%",
            textAlign: "start",
            padding: "10px ",
          }}
          variant="h5"
        >
          Edit Details
        </Typography>

        <Grid item sx={{ width: "100%", paddingBottom: "10px" }}>
          <Accordion
            sx={{ marginBottom: 1, width: "100%", boxShadow: 0 }}
            expanded={userExpanded}
            onChange={handleUserExpandClick}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="header"
            >
              <Typography sx={{ fontWeight: userExpanded ? 700 : 600 }}>
                {userExpanded ? "Update Your Account Details" : "User Details"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormContainer>
                <Paper
                  variant=""
                  sx={{
                    height: "auto",
                    margin: "auto",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  <ResponsiveRow container>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>First Name *</InputLabel>
                      <OutlinedInput
                        placeholder={"First Name"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"firstName"}
                        onChange={(event) => onTextChange(event)}
                        value={firstName}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Middle Name</InputLabel>
                      <OutlinedInput
                        placeholder={"Middle Name"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"middleName"}
                        onChange={(event) => onTextChange(event)}
                        value={isNullUndefined(middleName) ? "" : middleName}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Last Name *</InputLabel>
                      <OutlinedInput
                        placeholder={"Last Name"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"lastName"}
                        onChange={(event) => onTextChange(event)}
                        value={lastName}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Mobile Phone * (Primary Number)</InputLabel>
                      <OutlinedInput
                        placeholder={"Primary Number"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"mobilePhone"}
                        onChange={(event) => onTextChange(event)}
                        value={mobilePhone}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>ID Type *</InputLabel>
                      <Select
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"idTypeCd"}
                        value={idTypeCd}
                        onChange={(event) => handleIdTypeChange(event)}
                      >
                        {idTypes && idTypes.length > 0 ? (
                          idTypes.map(function (idType, i) {
                            return (
                              <MenuItem key={idType.value} value={idType.value}>
                                {idType.label}
                              </MenuItem>
                            );
                          }, this)
                        ) : (
                          <MenuItem sx={{ width: "100%" }}>
                            No Results Found
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>ID Number *</InputLabel>
                      <OutlinedInput
                        placeholder={"ID Number"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"idNumber"}
                        onChange={(event) => onTextChange(event)}
                        value={idNumber}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Email</InputLabel>
                      <OutlinedInput
                        placeholder={"Email"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"email"}
                        onChange={(event) => onTextChange(event)}
                        value={email}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Office Phone (Secondary Number)</InputLabel>
                      <OutlinedInput
                        placeholder={"Secondary Number"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"officePhone"}
                        onChange={(event) => onTextChange(event)}
                        value={officePhone}
                      />
                    </Grid>
                  </ResponsiveRow>
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      justifyContent: { xs: "center", lg: "flex-end" },
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <ModalAcceptButton
                        // type="submit"
                        disabled={false}
                        onClick={handleUpdateUserClick}
                        sx={{
                          width: { xs: "100%", lg: "200px" },
                        }}
                      >
                        Update
                      </ModalAcceptButton>
                      <ModalCancelButton
                        // type="submit"
                        onClick={handleExpandClose}
                        sx={{ width: { xs: "100%", lg: "200px" } }}
                      >
                        Cancel
                      </ModalCancelButton>
                    </Grid>
                  </ResponsiveRow>
                </Paper>
              </FormContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item sx={{ width: "100%", paddingBottom: "10px" }}>
          <Accordion
            sx={{ marginBottom: 1, width: "100%", boxShadow: 0 }}
            expanded={passwordExpanded}
            onChange={handlePasswordExpandClick}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="header"
            >
              <Typography sx={{ fontWeight: userExpanded ? 700 : 600 }}>
                {passwordExpanded
                  ? "Change Your Account Password"
                  : "Update Password"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormContainer>
                <Paper
                  variant=""
                  sx={{
                    height: "auto",
                    margin: "auto",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  <ResponsiveRow container>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Current Password *</InputLabel>
                      <OutlinedInput
                        placeholder={"Current Password"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        type={passwordVisible ? "text" : "password"}
                        endAdornment={
                          passwordVisible === false ? (
                            <Visibility
                              onClick={togglePasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={togglePasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          )
                        }
                        id={"currentPassword"}
                        onChange={(event) => onTextChange(event)}
                        value={currentPassword}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Enter New Password *</InputLabel>
                      <OutlinedInput
                        placeholder={"Enter New Password"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"newPassword"}
                        onChange={(event) => onTextChange(event)}
                        value={newPassword}
                        type={currentPasswordVisible ? "text" : "password"}
                        endAdornment={
                          currentPasswordVisible === false ? (
                            <Visibility
                              onClick={toggleCurrentPasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={toggleCurrentPasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel>Confirm New Password *</InputLabel>
                      <OutlinedInput
                        placeholder={"Confirm New Password"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        type={confirmPasswordVisible ? "text" : "password"}
                        endAdornment={
                          confirmPasswordVisible === false ? (
                            <Visibility
                              onClick={toggleConfirmPasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={toggleConfirmPasswordVisibility}
                              sx={{ color: kodi_theme.palette.primary.dark }}
                            />
                          )
                        }
                        id={"confirmPassword"}
                        onChange={(event) => onTextChange(event)}
                        value={confirmPassword}
                      />
                    </Grid>
                  </ResponsiveRow>
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      justifyContent: { xs: "center", lg: "flex-end" },
                    }}
                  >
                    <Grid item>
                      <ModalAcceptButton
                        // type="submit"
                        disabled={
                          isEmptyString(currentPassword) ||
                          isEmptyString(newPassword) ||
                          isEmptyString(confirmPassword)
                        }
                        onClick={handleChangePasswordClick}
                        sx={{
                          width: { xs: "100%", lg: "200px" },
                        }}
                      >
                        Change password
                      </ModalAcceptButton>
                      <ModalCancelButton
                        // type="submit"
                        onClick={handleExpandClose}
                        sx={{ width: { xs: "100%", lg: "200px" } }}
                      >
                        Cancel
                      </ModalCancelButton>
                    </Grid>
                  </ResponsiveRow>
                </Paper>
              </FormContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            paddingRight: { xs: 0, lg: "10px" },
          }}
        >
          <Accordion
            sx={{ marginBottom: 1, width: "100%", boxShadow: 0 }}
            expanded={notificationExpanded}
            onChange={handleNotificationExpandClick}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="header"
            >
              <Typography sx={{ fontWeight: notificationExpanded ? 700 : 600 }}>
                {notificationExpanded
                  ? "Change Your Account Notification"
                  : "Notification Settings"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormContainer>
                <Paper
                  variant=""
                  sx={{
                    height: "auto",
                    margin: "auto",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  <ResponsiveRow container>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <Switch
                        checked={reminder}
                        onChange={(event) => handleToggle(event, "reminder")}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      Reminder
                    </Grid>

                    <Permit roles="BLDIR,BLDMG,TEN,OWN">
                      <Grid
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <Switch
                          checked={invoicing}
                          onChange={(event) => handleToggle(event, "invoicing")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Invoicing
                      </Grid>
                    </Permit>

                    <Permit roles="BLDIR,BLDMG,TEN,OWN,SAL">
                      <Grid
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <Switch
                          checked={payment}
                          onChange={(event) => handleToggle(event, "payment")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Payment
                      </Grid>
                    </Permit>

                    <Permit roles="BLDIR,BLDMG,TEN,OWN">
                      <Grid
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <Switch
                          checked={onBoarding}
                          onChange={(event) =>
                            handleToggle(event, "onBoarding")
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Onboarding
                      </Grid>
                    </Permit>

                    <Permit roles="BLDIR,BLDMG,TEN,OWN">
                      <Grid
                        item
                        sx={{ width: { xs: "100%", lg: "33.3%" } }}
                        style={{ marginBottom: 10 }}
                      >
                        <Switch
                          checked={meterReading}
                          onChange={(event) =>
                            handleToggle(event, "meterReading")
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Meter Reading
                      </Grid>
                    </Permit>

                    <Permit roles="BLDIR,BLDMG">
                      <Grid
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <Switch
                          checked={report}
                          onChange={(event) => handleToggle(event, "report")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Report
                      </Grid>
                    </Permit>

                    <Permit roles="BLDIR">
                      <Grid
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <Switch
                          checked={pendingApproval}
                          onChange={(event) =>
                            handleToggle(event, "pendingApproval")
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        Pending Approval
                      </Grid>
                    </Permit>
                  </ResponsiveRow>

                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      justifyContent: { xs: "center", lg: "flex-end" },
                    }}
                  >
                    <ModalAcceptButton
                      //   type="submit"
                      onClick={handleUpdateNotificationClick}
                      sx={{
                        width: { xs: "100%", lg: "200px" },
                      }}
                    >
                      Update Notification
                    </ModalAcceptButton>
                    <ModalCancelButton
                      // type="submit"
                      onClick={handleExpandClose}
                      sx={{ width: { xs: "100%", lg: "200px" } }}
                    >
                      Cancel
                    </ModalCancelButton>
                  </ResponsiveRow>
                </Paper>
              </FormContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
          <Accordion
            sx={{ marginBottom: 1, width: "100%", boxShadow: 0 }}
            expanded={reportExpanded}
            onChange={handleReportExpandClick}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="header"
            >
              <Typography sx={{ fontWeight: reportExpanded ? 700 : 600 }}>
                {reportExpanded
                  ? "Update Email Notifications"
                  : "Report Settings"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!isNullUndefined(emailNotification) && (
                <FormContainer>
                  <Paper
                    variant=""
                    sx={{
                      height: "auto",
                      margin: "auto",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    {emailNotificationFields}
                  </Paper>
                </FormContainer>
              )}
              {isNullUndefined(emailNotification) &&
                isEmptyString(user.proxyFacility) && (
                  <Alert severity="warning">
                    proxy to facility to view this.
                  </Alert>
                )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
}
