import {
    ACTIVATE_LEASE_URL,
    ADD_LEASE_CONTACT_URL,
    ADD_OR_DELETE_UNIT_URL,
    CHG_CD_URL,
    DELETE_DRAFT_LEASE_URL,
    DELETE_LEASE_DOCUMENT,
    DOWNLOAD_LEASES_URL,
    EXPORT_LEASES_URL,
    GET_EDIT_UNIT_DETAILS_URL,
    GET_LEASE_CONTACTS_URL,
    GET_LEASE_DETAILS_URL,
    GET_MPESA_PAYMENT_BY_CODE_URL,
    GET_UNIT_METERS_URL,
    INCREMENT_RENT_URL,
    LEASE_FINANCIALS_URL,
    LEASE_NOTES_URL,
    LEASE_TERMINATION_ACCOUNT_URL,
    LEASE_TERMINATION_URL,
    LEASE_UNIT_CHARGES_URL,
    LES_PAY_MTH_URL,
    LES_TER_URL,
    LES_TYPE_URL,
    LIST_LEASES_URL,
    LIST_TERMINATED_LEASES_URL,
    OUTSTANDING_LEASE_INVOICES_CHARGES_URL,
    REMOVE_LEASE_CONTACT_URL,
    RESEND_LAST_INVOICE_URL,
    RESEND_LAST_RECEIPT_URL,
    RESEND_LAST_STATEMENT_URL,
    SAVE_LEASE_NOTE_URL,
    SAVE_LEASE_PAYMENT_URL,
    SAVE_LEASE_URL,
    SUSPEND_REACTIVATE_LEASE_URL,
    TOTAL_LEASES_URL,
    TOTAL_TERMINATED_LEASES_URL,
    UPDATE_LEASE_DEPOSIT_URL,
    UPDATE_LEASE_URL,
    UPLOAD_LEASE_DOCUMENT
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {handleErrorResponse} from "../actions/message"

//POST REQUEST
export const applyIncrement = (data) => {
    return serviceAPI.post(INCREMENT_RENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//GET REQUEST
export const getLeasePaymentMethods = () => {
    return serviceAPI.get(LES_PAY_MTH_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOutstandingLeaseInvoicesCharges = (leaseId) => {
    let url = OUTSTANDING_LEASE_INVOICES_CHARGES_URL + leaseId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMpesaPaymentByCode = (confirmationCode, contractId) => {
    let url = GET_MPESA_PAYMENT_BY_CODE_URL + confirmationCode + "&leaseId=" + contractId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseTerminationAccount = (leaseId) => {
    let url = LEASE_TERMINATION_ACCOUNT_URL + leaseId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseFinancials = (leaseId, pageNo) => {
    let url = LEASE_FINANCIALS_URL + leaseId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeases = (searchReference, searchTenant, pageNo, rowsPerPage) => {
    let url = LIST_LEASES_URL + searchReference + "&tenant=" + searchTenant + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTerminatedLeases = (searchReference, searchTenant, pageNo, rowsPerPage) => {
    let url = LIST_TERMINATED_LEASES_URL + searchReference + "&tenant=" + searchTenant + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalLeases = (searchReference, searchTenant) => {
    let url = TOTAL_LEASES_URL + searchReference + "&tenant=" + searchTenant
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalTerminatedLeases = (searchReference, searchTenant) => {
    let url = TOTAL_TERMINATED_LEASES_URL + searchReference + "&tenant=" + searchTenant
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseNotes = (leaseId, pageNo) => {
    let url = LEASE_NOTES_URL + leaseId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseContacts = (leaseId) => {
    let url = GET_LEASE_CONTACTS_URL + leaseId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data.contacts
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseDetails = (leaseId) => {
    let url = GET_LEASE_DETAILS_URL + leaseId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getEditUnitDetails = (leaseId) => {
    let url = GET_EDIT_UNIT_DETAILS_URL + leaseId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseTerms = () => {
    return serviceAPI.get(LES_TER_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getUnitMeters = (unitId) => {
    return serviceAPI.get(GET_UNIT_METERS_URL + unitId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseTypes = () => {
    return serviceAPI.get(LES_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportLeases = () => {
    return serviceAPIDownloadFile.get(EXPORT_LEASES_URL)
}

export const getLeaseChargeCodes = () => {
    return serviceAPI.get(CHG_CD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//POST REQUEST
export const saveLeaseNote = (data) => {
    return serviceAPI.post(SAVE_LEASE_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadLeaseDocument(data, leaseId) {
    let url = UPLOAD_LEASE_DOCUMENT + leaseId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deleteLeaseDocument(data, leaseId) {
    let url = DELETE_LEASE_DOCUMENT + leaseId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const addLeaseContact = (data) => {
    return serviceAPI.post(ADD_LEASE_CONTACT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const addOrDeleteUnit = (data) => {
    return serviceAPI.post(ADD_OR_DELETE_UNIT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const removeLeaseContact = (data) => {
    return serviceAPI.post(REMOVE_LEASE_CONTACT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveLeasePayment = (data) => {
    return serviceAPI.post(SAVE_LEASE_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const downloadLeases = (data) => {
    return serviceAPI.post(DOWNLOAD_LEASES_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const leaseTermination = (data) => {
    return serviceAPI.post(LEASE_TERMINATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const suspendReactivateLease = (data) => {
    return serviceAPI.post(SUSPEND_REACTIVATE_LEASE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const leaseUnitCharges = (data) => {
    return serviceAPI.post(LEASE_UNIT_CHARGES_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveLeaseData = (data) => {
    return serviceAPI.post(SAVE_LEASE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteDraftLease = (data) => {
    return serviceAPI.post(DELETE_DRAFT_LEASE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const activateLease = (data) => {
    return serviceAPI.post(ACTIVATE_LEASE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateActivateLease = (data) => {
    return serviceAPI.post(UPDATE_LEASE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateLeaseDeposit = (data) => {
    return serviceAPI.post(UPDATE_LEASE_DEPOSIT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastInvoice = (data) => {
    return serviceAPI.post(RESEND_LAST_INVOICE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastReceipt = (data) => {
    return serviceAPI.post(RESEND_LAST_RECEIPT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendStatement = (data) => {
    return serviceAPI.post(RESEND_LAST_STATEMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}