import {useEffect, useState} from "react";
import {getHomeOwnerDues} from "../../../services/dashboard.service";
import {sortJSONArray} from "../../../util/util";
import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    owner: string
 *    unit: string
 *    amount: number
 *  }
 *
 */

export default function HomeOwnersDues({customHeight, customWidth}) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const {user} = useSelector((state) => state.auth)
    const DataGridColumns = [
        {
            field: "owner",
            headerName: "Owner",
            width: 200,
            editable: true,
        },
        {
            field: "unit",
            headerName: "Unit",
            minWidth: 200,
            flex: 1,
            editable: true,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minWidth: 200,
            flex: 1,
        },
    ];

    useEffect(() => {
        getHomeOwnerDues()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response.data.sort(sortJSONArray("amount", false)));
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => setDataGridRows([]);
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Home Owners Dues (Balances)
            </Typography>

            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
            />
        </ResponsiveRow>
    );
}
