import {useEffect, useState} from "react"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {Box, FormControl, InputLabel, OutlinedInput} from "@mui/material"
import Grid from "@mui/material/Grid"
import {loadOwners, saveOwner, totalOwners} from "../services/owners.service"
import {Edit} from "@mui/icons-material"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import AddNewUser from "../components/modals/addNewUser"
import {issueResponseMessage} from "../actions/message"
import {useSelector} from "react-redux"
import {MainContainer} from "../constants/component.constants"
import Permit from "../util/permit"
import MuiDataGrid from "../components/modules/MuiDataGrid"

export default function Owners() {
    const user = useSelector((state) => state.auth.user)
    const [ownerName, setOwnerName] = useState("")
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    })
    const [ownerList, setOwnerList] = useState([])
    const [total, setTotalOwners] = useState(0)
    const [task, setTask] = useState("")
    const [selectedOwner, setSelectedOwner] = useState(null)
    const [addOrganizationModal, setAddOrganizationModal] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        loadOwners(ownerName, paginationModel.page)
            .then((data) => setOwnerList(data))
        totalOwners(ownerName)
            .then((data) => setTotalOwners(data.total))
            .finally(() => {
                setLoading(false)
            })
    }, [ownerName, paginationModel, user.proxyFacility])

    const columns = [
        {headerName: "Owner's Name", field: "name", minWidth: 250, flex: 2.5},
        {headerName: "Property", field: "property", minWidth: 200, flex: 2},
        {headerName: "Units Owned", field: "unit", minWidth: 250, flex: 2.5},
        {
            headerName: "Ownership Start",
            field: "titleStart",
            minWidth: 150,
            flex: 1.5,
        },
        {
            headerName: "Ownership End",
            field: "titleEnd",
            minWidth: 150,
            flex: 1.5,
        },
        {headerName: "Phone", field: "mobilePhone", minWidth: 150, flex: 1.5},
        {headerName: "Email", field: "email", minWidth: 175},
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                // console.log("Params", params.row)
                let arr = []
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editOwnersClicked(params.row)}
                        label="Edit"
                        showInMenu
                    />
                )

                return arr
            },
        },
    ]

    function editOwnersClicked(item) {
        setTask("Edit")
        setSelectedOwner(item)
        if (item.type === "Company") {
            setAddOrganizationModal(true)
        }
        if (item.type === "Individual") {
            setAddUserModal(true)
        }
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false)
        setTask("")
    }

    function handleAddUserClose() {
        setAddUserModal(false)
        setTask("")
    }

    function validateForm() {
    }

    function handleUpdateOwner() {
        if (validateForm) {
            saveOwner(selectedOwner)
                .then((response) => {
                    issueResponseMessage(response)
                    handleAddUserClose()
                    loadOwners(ownerName, paginationModel.page)
                        .then((data) => setOwnerList(data))
                    totalOwners(ownerName)
                        .then((data) => setTotalOwners(data.total))
                })
        }
    }

    return (
        <Permit
            roles="BLDIR,BLDMG,ACC,CS"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {task !== "Edit" && (
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: {lg: "nowrap", md: "wrap"},
                            justifyContent: "space-between",
                            width: {xs: "100%", md: "80%"},
                            backgroundColor: "white",
                            padding: {md: "1vh", xs: "1vh"},
                            borderRadius: "1vh",
                            margin: "20px auto",
                            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {xl: "row", xs: "column"},
                                justifyContent: {md: "space-between", xs: "center"},
                                width: {lg: "33.3%", md: "50%", sm: "100%"},
                            }}
                            component="form"
                        >
                            <FormControl
                                fullWidth
                                sx={{
                                    margin: {md: "auto 20px", xs: "10px auto "},
                                    width: "100%",
                                }}
                            >
                                <InputLabel sx={{fontSize: ".9rem"}} id="ownerLabel">
                                    Search Home owner's name
                                </InputLabel>
                                <OutlinedInput
                                    id="ownerNameSearch"
                                    value={ownerName}
                                    label={`Search home owner's name`}
                                    sx={{width: "100%"}}
                                    onChange={(event) => {
                                        let value = event.target.value
                                        setOwnerName(value)
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {xl: "row", xs: "column"},
                                justifyContent: {md: "space-between", xs: "center"},
                                width: {md: "20%", xs: "100%"},
                                padding: "10px",
                            }}
                            component="form"
                        ></Box>
                    </Grid>
                )}

                {task !== "Edit" && (
                    <MuiDataGrid
                        noSelection={true}
                        dataGridColumns={columns}
                        dataGridRows={ownerList}
                        serverPagination={true}
                        handlePageLoad={setPaginationModel}
                        loading={loading}
                        height="60vh"
                        totalRows={total}
                    />
                )}

                {task === "Edit" && selectedOwner.type === "Company" && (
                    <AddNewOrganisation
                        openModal={addOrganizationModal}
                        closeModal={handleAddOrgClose}
                        task={task}
                        homeOwner={true}
                        selectedOrg={selectedOwner}
                    />
                )}

                {task === "Edit" && selectedOwner.type === "Individual" && (
                    <AddNewUser
                        openModal={addUserModal}
                        closeModal={handleAddUserClose}
                        saveUser={handleUpdateOwner}
                        manageUsers={false}
                        task={task}
                        homeOwner={true}
                        selectedPerson={selectedOwner}
                    />
                )}
            </MainContainer>
        </Permit>
    )
}
