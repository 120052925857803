import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import CreditNotes from "./creditNotes";
import DebitNotes from "./debitNotes";
import Receipts from "./receipts";
import Invoices from "./invoices";

import {
  Button,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  listJournalEntries,
  reverseJournalEntry,
  saveJournalEntry,
} from "../services/journalEntries.service";
import {
  formatDate,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../util/util";
import {
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { Cancel, History, Visibility } from "@mui/icons-material";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import { useSelector } from "react-redux";
import {
  allUnitOptions,
  getActiveComplexForOrgOptions,
  getActiveLeases,
  getAllAccountCategories,
  getAllAccounts,
  getAllAccountSubCategories,
  getAssetOptions,
  getProxyFacilityForUserOptions,
} from "../services/list.service";
import CreateJournalEntry from "../components/modals/accounting/journalentries/createJournalEntry";
import ViewJournalModal from "../components/modals/accounting/journalentries/viewJournalModal";
import ReverseJournalEntryModal from "../components/modals/accounting/journalentries/reverseJournalEntryModal";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import { loadAccountsList, saveAccount } from "../services/accounts.service";
import AddAccountsModal from "../components/modals/accounting/chart of accounts/addAccountsModal";

export default function JournalV2() {
  const [currentTab, setCurrentTab] = useState("Invoices");

  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [complexOptions, setComplexOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [leaseOptions, setLeaseOptions] = useState([]);
  const [enteredBy] = useState("");
  const [AddDiv, setAddDiv] = useState(false);
  const [ViewDiv, setViewDiv] = useState(false);
  const [ReverseModal, setReverseModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [items, setItems] = useState([]);
  const [tieredItemsTableRows, setTieredItemsTableRows] = useState("");
  const [journalItems, setJournalItems] = useState([]);
  const [date, setDate] = useState("");
  const [memo, setMemo] = useState("");
  const [property, setProperty] = useState("");
  const [unit, setUnit] = useState("");
  const [complex, setComplex] = useState("");
  const [asset, setAsset] = useState("");
  const [lease, setLease] = useState("");
  const user = useSelector((state) => state.auth.user);

  const [name, setName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [category] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [accountsList, setAccountsList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [accountForm, setAccountForm] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountCategoryList, setAccountCategoryList] = useState([]);
  const [accountSubCategoryList, setAccountSubCategoryList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let facilityId = "";
    if (user.proxyFacility != null) {
      facilityId = user.proxyFacility;
      setProperty(facilityId);
    }
    getAllAccounts().then((accounts) => {
      setAccounts(accounts);
    });

    getActiveLeases(facilityId).then((leaseOptions) => {
      setLeaseOptions(leaseOptions);
    });

    allUnitOptions(facilityId).then((unitOptions) => {
      setUnitOptions(unitOptions);
    });

    getProxyFacilityForUserOptions().then((facilityOptions) => {
      setFacilityOptions(facilityOptions);
    });

    getActiveComplexForOrgOptions().then((complexOptions) => {
      setComplexOptions(complexOptions);
    });

    getAssetOptions().then((assetOptions) => {
      setAssetOptions(assetOptions);
    });

    listJournalEntries(enteredBy)
      .then((entries) => {
        setEntries(entries);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enteredBy]);

  useEffect(() => {
    setLoading(true);
    getActiveLeases(property).then((leaseOptions) => {
      setLeaseOptions(leaseOptions);
    });

    allUnitOptions(property)
      .then((unitOptions) => {
        setUnitOptions(unitOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [property]);

  useEffect(() => {
    populateTieredItems();
  }, [journalItems]);

  function handleItemRemove(index) {
    let newArr = [...items]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      setItems(newArr);
      setJournalItems(items);
    } else {
      issueWarnMessage("There must be at least one transaction item");
    }
  }

  function handleTieredItemAdd() {
    setItems((items) => [
      ...items,
      { debit: null, credit: null, description: null, amount: null },
    ]);
    setJournalItems((journalItems) => [
      ...journalItems,
      { debit: null, credit: null, description: null, amount: null },
    ]);
  }

  function handleDebitChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].debit = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleCreditChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].credit = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleDescriptionChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].description = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].amount = value;
    setItems(newArr);
    populateTieredItems();
  }

  function populateTieredItems() {
    let rows = "";
    items &&
      items.length > 0 &&
      (rows = items.map(function (meterItem, i) {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"debit"}
                value={items && items[i] && items[i].debit}
                onChange={(event) => handleDebitChange(event, i)}
              >
                {accounts && accounts.length > 0 ? (
                  accounts.map(function (account, i) {
                    return (
                      <MenuItem key={account.value} value={account.value}>
                        {account.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"credit"}
                value={items && items[i] && items[i].credit}
                onChange={(event) => handleCreditChange(event, i)}
              >
                {accounts && accounts.length > 0 ? (
                  accounts.map(function (account, i) {
                    return (
                      <MenuItem key={account.value} value={account.value}>
                        {account.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"description" + i}
                name={"description" + i}
                isrequired={true}
                value={items && items[i] && items[i].description}
                onChange={(event) => handleDescriptionChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"amount" + i}
                name={"amount" + i}
                isrequired={true}
                value={items && items[i] && items[i].amount}
                onChange={(event) => handleAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setTieredItemsTableRows(rows);
  }

  function handleViewClick(journalEntry) {
    setSelectedEntry(journalEntry);
    setViewDiv(true);
  }

  function handleReverseClick(journalEntry) {
    setSelectedEntry(journalEntry);
    setReverseModal(true);
  }

  function handleAddClick() {
    setAddDiv(true);
    let facilityId = "";
    if (user.proxyFacility != null) {
      facilityId = user.proxyFacility;
      setProperty(facilityId);
    }
    let data = {
      id: null,
      postingDate: "",
      description: "",
      facility: {
        id: facilityId,
      },
      unit: {
        id: null,
      },
      complex: {
        id: null,
      },
      asset: {
        id: null,
      },
      lease: {
        id: null,
      },
      title: {
        id: null,
      },
      meterBilling: {
        id: null,
      },
      meter: {
        id: null,
      },
      transactionDetailItems: [],
    };
    setSelectedEntry(data);
  }

  function handleDateChange(value) {
    let date = new Date(value);
    setDate(date);
    let obj = selectedEntry;
    obj.postingDate = date;
    setSelectedEntry(obj);
  }

  function handlePropertyChange(event) {
    let value = event.target.value;
    setProperty(value);
    let obj = selectedEntry;
    obj.facility.id = value;
    setSelectedEntry(obj);
  }

  function handleUnitChange(event) {
    let value = event.target.value;
    setUnit(value);
    let obj = selectedEntry;
    obj.unit.id = value;
    setSelectedEntry(obj);
  }

  function handleComplexChange(event) {
    let value = event.target.value;
    setComplex(value);
    let obj = selectedEntry;
    obj.complex.id = value;
    setSelectedEntry(obj);
  }

  function handleAssetChange(event) {
    let value = event.target.value;
    setAsset(value);
    let obj = selectedEntry;
    obj.asset.id = value;
    setSelectedEntry(obj);
  }

  function handleLeaseChange(event) {
    let value = event.target.value;
    setLease(value);
    let obj = selectedEntry;
    obj.lease.id = value;
    setSelectedEntry(obj);
  }

  function reverseEntry() {
    reverseJournalEntry(selectedEntry.id).then((response) => {
      issueResponseMessage(response);
      listJournalEntries(enteredBy).then((entries) => setEntries(entries));
      setSelectedEntry(null);
      setReverseModal(false);
    });
  }

  function saveEntry() {
    if (isEmptyString(selectedEntry.postingDate)) {
      issueWarnMessage("The Journal entry must have a date!");
      return false;
    }
    if (isEmptyString(selectedEntry.description)) {
      issueWarnMessage("The Journal entry must have a description!");
      return false;
    }
    if (isEmptyArray(items)) {
      issueWarnMessage("There must be at least 1 transaction detail item!");
      return false;
    }

    if (isArray(items)) {
      for (let item of items) {
        if (isNullUndefined(item.amount) || isEmptyString(item.amount)) {
          issueWarnMessage("Amount is invalid. Must be more than 0");
          return false;
        }
        let amount = tryParseFloat(item.amount.toString(), -1);
        if (amount < 1) {
          let message =
            "Amount '" + item.amount + "' is invalid. Must be more than 0";
          issueWarnMessage(message);
          return false;
        }
        if (
          isNullUndefined(item.debit) ||
          isEmptyString(item.debit) ||
          isNullUndefined(item.credit) ||
          isEmptyString(item.credit)
        ) {
          issueWarnMessage("Debit and credit accounts are required.");
          return false;
        }
        if (
          isNullUndefined(item.description) ||
          isEmptyString(item.description)
        ) {
          issueWarnMessage("Description is required.");
          return false;
        }
      }
    }

    let obj = selectedEntry;
    obj.transactionDetailItems = items;
    setSelectedEntry(obj);

    saveJournalEntry(selectedEntry).then((response) => {
      issueResponseMessage(response);
      listJournalEntries(enteredBy).then((entries) => setEntries(entries));
      setAddDiv(false);
      setSelectedEntry(null);
      setItems([]);
      setJournalItems([]);
    });
  }

  const dataGridColumns = [
    {
      field: "description",
      headerName: "Description",
      minWidth: 500,
      flex: 5,
    },
    {
      field: "entryDate",
      headerName: "Entry Date",
      minWidth: 120,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    {
      field: "postingDate",
      headerName: "Posting Date",
      minWidth: 120,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    { field: "appliedTo", headerName: "Applied To", minWidth: 350, flex: 3.5 },
    { field: "totalAmount", headerName: "Amount", minWidth: 110, flex: 1.1 },
    {
      field: "createdBy",
      headerName: "Created By",
      minWidth: 275,
      flex: 2.75,
      valueFormatter: (row) => {
        if (isNullUndefined(row.value) || isEmptyString(row.value)) {
          return "System Generated";
        } else {
          return row.value;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => handleViewClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<History />}
          label="Reverse Entry"
          onClick={() => handleReverseClick(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAllAccountCategories().then((data) => setAccountCategoryList(data));
    loadAccountsList(name)
      .then((data) => setAccountsList(data))
      .finally(() => {
        setLoading(false);
      });
  }, [name, category, subCategory]);

  const columns = [
    { headerName: "Account Name", field: "name", width: 400 },
    { headerName: "Code", field: "code", minWidth: 350, flex: 0.875 },
    {
      headerName: "Category",
      field: "accountCategory",
      minWidth: 360,
      flex: 0.9,
      valueFormatter: (row) => {
        return row.value.name;
      },
    },
    {
      headerName: "Sub-Category",
      field: "accountSubCategory",
      minWidth: 360,
      flex: 0.9,
      valueFormatter: (row) => {
        return row.value.name;
      },
    },
  ];

  function handleAccountCategoryChanged(event) {
    let value = event.target.value;
    setSelectedCategory(value);
    let obj = selectedAccount;
    obj.accountCategoryCd = value;
    setSelectedAccount(obj);

    getAllAccountSubCategories(value).then((data) =>
      setAccountSubCategoryList(data)
    );
  }

  function handleAddAccountClicked() {
    let data = {
      id: null,
      name: "",
      accountCategoryCd: "",
      accountSubCategoryCd: "",
    };
    setSelectedAccount(data);
    setAccountForm(true);
  }

  function handleCloseAccForm() {
    setAccountForm(false);
    setSelectedAccount(null);
    setSelectedCategory("");
    setSubCategory("");
    setAccountName("");
    setAccountSubCategoryList([]);
  }

  function validateFormData(sendData) {
    if (isNullUndefined(sendData.name) || isEmptyString(sendData.name)) {
      issueWarnMessage("Please enter the account name");
      return false;
    }
    if (
      isNullUndefined(sendData.accountCategoryCd) ||
      isEmptyString(sendData.accountCategoryCd)
    ) {
      issueWarnMessage("Please select an account category");
      return false;
    }
    if (
      isNullUndefined(sendData.accountSubCategoryCd) ||
      isEmptyString(sendData.accountSubCategoryCd)
    ) {
      issueWarnMessage("Please select an account sub category");
      return false;
    }

    return true;
  }

  function handleSaveAccountClicked() {
    if (validateFormData(selectedAccount)) {
      saveAccount(selectedAccount).then((response) => {
        issueResponseMessage(response);
        handleCloseAccForm();
      });
    }
  }

  return (
    <Permit
      roles="BLDIR,ACC"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",

          flexWrap: "nowrap",
          flexDirection: "column",

          justifyContent: "flex-start",
          alignItems: "flex-start",

          padding: 0,
          marginBottom: "20px",
        }}
      >
        {!AddDiv && !ViewDiv && (
          <TopBarContainer
            sx={{
              justifyContent: { xs: "center", lg: "space-between" },
              width: { xs: "100%", lg: "90%" },
              minHeight: "80px",
            }}
            container
          >
            <ResponsiveRow
              item
              sx={{ width: { xs: "100%", lg: "50%" }, padding: 0 }}
            >
              <Typography
                variant="h5"
                sx={{
                  width: "100%",
                  height: "auto",

                  textAlign: { xs: "center", lg: "start" },
                }}
              >
                Journal Entries
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow
              item
              sx={{
                width: { xs: "100%", lg: "50%" },
                padding: 0,
                justifyContent: { xs: "center", lg: "flex-end" },
              }}
              container
            >
              <ResponsiveRow
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  justifyContent: { xs: "center", lg: "flex-end" },
                }}
              >
                <TopBarContainedBrandButton
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                  onClick={handleAddClick}
                >
                  Add Journal Entry
                </TopBarContainedBrandButton>
              </ResponsiveRow>
            </ResponsiveRow>
          </TopBarContainer>
        )}
        {!AddDiv && !ViewDiv && <br />}
        <CreateJournalEntry
          AddDiv={AddDiv}
          saveEntry={saveEntry}
          handleDateChange={handleDateChange}
          handleUnitChange={handleUnitChange}
          handlePropertyChange={handlePropertyChange}
          handleComplexChange={handleComplexChange}
          handleAssetChange={handleAssetChange}
          handleLeaseChange={handleLeaseChange}
          handleTieredItemAdd={handleTieredItemAdd}
          setAddDiv={setAddDiv}
          setJournalItems={setJournalItems}
          setSelectedEntry={setSelectedEntry}
          setItems={setItems}
          selectedEntry={selectedEntry}
          date={date}
          setMemo={setMemo}
          asset={asset}
          assetOptions={assetOptions}
          complex={complex}
          complexOptions={complexOptions}
          lease={lease}
          leaseOptions={leaseOptions}
          memo={memo}
          tieredItemsTableRows={tieredItemsTableRows}
          unit={unit}
          unitOptions={unitOptions}
          facilityOptions={facilityOptions}
        />
        {ViewDiv && (
          <ViewJournalModal
            ViewDiv={ViewDiv}
            setViewDiv={setViewDiv}
            selectedEntry={selectedEntry}
          />
        )}
        <MuiDataGrid
          dataGridColumns={dataGridColumns}
          noSelection={true}
          dataGridRows={entries}
          loading={loading}
          height="60vh"
          totalRows={entries.length}
        />
        <ReverseJournalEntryModal
          ReverseModal={ReverseModal}
          setReverseModal={setReverseModal}
          setSelectedEntry={setSelectedEntry}
          selectedEntry={selectedEntry}
          reverseEntry={reverseEntry}
        />
      </ResponsiveRow>

      <MainContainer sx={{ marginBottom: "40px" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: { lg: "nowrap", md: "wrap" },
            justifyContent: "space-between",
            width: { xs: "100%", md: "100%" },
            backgroundColor: "white",
            padding: { md: "1vh", xs: "1vh" },
            borderRadius: "1vh",
            margin: "20px auto 20px auto",
            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xl: "row", xs: "column" },
              justifyContent: { md: "space-between", xs: "center" },
              width: { lg: "40%", md: "50%", sm: "100%" },
            }}
            component="form"
          >
            <FormControl
              fullWidth
              sx={{
                margin: { md: "auto 20px", xs: "10px auto " },
                width: "100%",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }} id="mobileMoneyLabel">
                Search Account Name
              </InputLabel>
              <OutlinedInput
                id="accountNameSearch"
                value={name}
                label={`Search Account Name`}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  let value = event.target.value;
                  setName(value);
                }}
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xl: "row", xs: "column" },
              justifyContent: { md: "space-between", xs: "center" },
              width: { md: "20%", xs: "100%" },
              padding: "10px",
            }}
            component="form"
          >
            <TopBarContainedBrandButton
              variant="contained"
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
              // type="submit"
              onClick={handleAddAccountClicked}
            >
              Add Account
            </TopBarContainedBrandButton>
          </Box>
        </Grid>
        {/*
        <StyledDataGrid
          autoHeight
          rows={accountsList}
          columns={columns}
          pageSize={12}
          loading={loading}
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyOverlay,
          }}
        /> */}

        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={accountsList}
          serverPagination={false}
          loading={loading}
          height="60vh"
          totalRows={accountsList.length}
        />

        <AddAccountsModal
          handleSaveAccountClicked={handleSaveAccountClicked}
          handleCloseAccForm={handleCloseAccForm}
          handleAccountCategoryChanged={handleAccountCategoryChanged}
          accountForm={accountForm}
          setAccountName={setAccountName}
          setSelectedAccount={setSelectedAccount}
          setSubCategory={setSubCategory}
          accountCategoryList={accountCategoryList}
          accountName={accountName}
          accountSubCategoryList={accountSubCategoryList}
          selectedAccount={selectedAccount}
          selectedCategory={selectedCategory}
          subCategory={subCategory}
        />
      </MainContainer>
    </Permit>
  );
}
