import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import { DocumentStore } from "../../../documentStore";
import ModalContainerModule from "../../../modules/modalContainer";
import { FileUpload } from "../../../inputs/fileUpload";
import { isNullUndefined } from "../../../../util/util";
import dayjs from "dayjs";
import Permit from "../../../../util/permit";

export default function CreateInvoiceModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleAddInvoiceItems
   * handleCancelClicked
   * saveInvoiceClicked
   * showAddInvoiceForm
   *
   * setDate
   * setDueDate
   * setMemo
   * setSelectedClient
   * setSelectedFacility
   * setSelectedInvoice
   * setSelectedTenant
   * setSelectedUnit
   *
   * client
   * clientList
   * date
   * dueDate
   * facilityOptions
   * invoiceItemsInputs
   * memo
   * onRadioChange
   * radioValue
   * selectedClient
   * selectedInvoice
   * selectedFacility
   * selectedTenant
   * selectedUnit
   * task
   * tenant
   * tenantList
   * unitOptions
   *
   */

  return (
    <ModalContainerModule
      size="md"
      accept={props.saveInvoiceClicked}
      cancel={props.handleCancelClicked}
      openModal={props.showAddInvoiceForm}
      modalTitle={props.task === "Create" ? "Create Invoice" : "Edit Invoice"}
      acceptButtonText={props.task === "Create" ? "Save" : "Update"}
    >
      <ResponsiveRow
        sx={{
          height: "auto",
        }}
      >
        <Typography
          sx={{ marginBottom: "5px" }}
          id="addInvoice"
          variant="h6"
          component="h6"
        >
          Invoice Details
        </Typography>
        <ResponsiveRow
          sx={{ height: "auto", width: "100%", padding: 0 }}
          container
        >
          <FormControl
            item
            sx={{ width: { sx: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date"
                id={"date"}
                value={dayjs(props.date)}
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", event)
                  let date = new Date(newDate);
                  props.setDate(date);
                  let obj = props.selectedInvoice;
                  obj.date = date;
                  props.setSelectedInvoice(obj);
                }}
                sx={{ width: { xs: "100%", lg: "90%" } }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Due Date"
                id={"dueDate"}
                value={dayjs(props.dueDate)}
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", event)
                  let date = new Date(newDate);
                  props.setDueDate(date);
                  let obj = props.selectedInvoice;
                  obj.dueDate = date;
                  props.setSelectedInvoice(obj);
                }}
                sx={{ width: { xs: "100%", lg: "90%" } }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Property</InputLabel>
            <Select
              label="Property"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"property"}
              value={props.selectedFacility}
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedFacility(value);
                let obj = props.selectedInvoice;
                obj.facilityId = value;
                props.setSelectedInvoice(obj);
              }}
            >
              {props.facilityOptions && props.facilityOptions.length > 0 ? (
                props.facilityOptions.map(function (facility, i) {
                  return (
                    <MenuItem key={i} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Memo</InputLabel>
            <OutlinedInput
              id="memo"
              label="Memo"
              multiline
              rows={4}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              value={props.memo}
              onChange={(event) => {
                let value = event.target.value;
                props.setMemo(value);
                let obj = props.selectedInvoice;
                obj.description = value;
                props.setSelectedInvoice(obj);
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <FormLabel id="radio-buttons-group-label">
              Issue invoice to:
            </FormLabel>
            <div className="ui toggle checkbox" style={{ marginTop: 15 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="use-radio-group"
                defaultValue={props.radioValue}
              >
                <FormControlLabel
                  name="radio"
                  control={
                    <Radio value="Client" onClick={props.onRadioChange} />
                  }
                  label="Client"
                />
                  <Permit services={"PREMIUM,HOA,METER,BLWTR"}>
                    <FormControlLabel
                      name="radio"
                      control={
                        <Radio value="Tenant" onClick={props.onRadioChange} />
                      }
                      label="Tenant"
                    />
                    <FormControlLabel
                      name="radio"
                      control={<Radio value="Unit" onClick={props.onRadioChange} />}
                      label="Property/Unit"
                    />
                    <FormControlLabel
                      name="radio"
                      control={
                        <Radio value="Asset" onClick={props.onRadioChange} />
                      }
                      label="Asset"
                    />
                    <FormControlLabel
                      name="radio"
                      control={
                        <Radio value="Complex" onClick={props.onRadioChange} />
                      }
                      label="Complex"
                    />
                  </Permit>
              </RadioGroup>
            </div>
          </FormControl>

          {props.client && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Client</InputLabel>
              <Select
                label="Client"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"client"}
                value={props.selectedClient}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedClient(value);
                  let obj = props.selectedInvoice;
                  obj.clientId = value;
                  obj.tenantId = null;
                  obj.unitId = null;
                  obj.assetId = null;
                  obj.complexId = null;
                  props.setSelectedInvoice(obj);
                }}
              >
                {props.clientList && props.clientList.length > 0 ? (
                  props.clientList.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {props.tenant && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Tenant</InputLabel>
              <Select
                label="Tenant"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"property"}
                value={props.selectedTenant}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedTenant(value);
                  let obj = props.selectedInvoice;
                  obj.tenantId = value;
                  obj.clientId = null;
                  obj.unitId = null;
                  obj.assetId = null;
                  obj.complexId = null;
                  props.setSelectedInvoice(obj);
                }}
              >
                {props.tenantList && props.tenantList.length > 0 ? (
                  props.tenantList.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {props.attachToAsset && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Asset</InputLabel>
              <Select
                label="Asset"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"asset"}
                value={props.selectedAsset}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedAsset(value);
                  let obj = props.selectedInvoice;
                  obj.assetId = value;
                  obj.tenantId = null;
                  obj.unitId = null;
                  obj.clientId = null;
                  obj.complexId = null;
                  props.setSelectedInvoice(obj);
                }}
              >
                {props.assetsList && props.assetsList.length > 0 ? (
                  props.assetsList.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {props.attachToComplex && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Complex</InputLabel>
              <Select
                label="Complex"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"complex"}
                value={props.selectedComplex}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedComplex(value);
                  let obj = props.selectedInvoice;
                  obj.complexId = value;
                  obj.assetId = null;
                  obj.tenantId = null;
                  obj.unitId = null;
                  obj.clientId = null;
                  props.setSelectedInvoice(obj);
                }}
              >
                {props.complexesList && props.complexesList.length > 0 ? (
                  props.complexesList.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {props.attachToUnit && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
            >
              <InputLabel>Unit</InputLabel>
              <Select
                label="Unit"
                sx={{
                  width: { xs: "100%", lg: "90%" },
                }}
                id={"unit"}
                value={props.selectedUnit}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedUnit(value);
                  let obj = props.selectedInvoice;
                  obj.unitId = value;
                  props.setSelectedInvoice(obj);
                }}
              >
                {props.unitOptions && props.unitOptions.length > 0 ? (
                  props.unitOptions.map(function (unit, i) {
                    return (
                      <MenuItem key={i} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </ResponsiveRow>

        <br />
        {props.task === "Create" && (
          <ResponsiveRow
            sx={{ height: "auto", width: "80%", padding: 0, marginTop: "20px" }}
          >
            <FormControl
              item
              sx={{
                width: { xs: "100%", md: "50%" },
                marginBottom: "10px",
              }}
            >
              <FormLabel>Upload Document (Max 10MB) (Optional)</FormLabel>
              <FileUpload
                id={"fileInput"}
                label={"Upload Document (Max 10MB)"}
                currentFile={props.file}
                onChange={(event) => {
                  props.uploadFileChanged(event);
                }}
                accept=".xlsx,.pdf,.jpeg,.png,.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </FormControl>
          </ResponsiveRow>
        )}

        <br />
        <ResponsiveRow sx={{ height: "auto", width: "100%", padding: 0 }}>
          <Typography
            sx={{ marginBottom: "5px" }}
            id="invoices"
            variant="h6"
            component="h6"
          >
            <ResponsiveRow container>
              <Typography>Invoice Items (Transactions)</Typography>
            </ResponsiveRow>
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Pay To(Credit)</TableCell>
                  <TableCell sx={{ width: "20%" }}>Description</TableCell>
                  <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                  <TableCell sx={{ width: "20%" }}>Apply VAT (16%)</TableCell>
                  <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.invoiceItemsInputs}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: "100%",
              height: "auto",
              padding: 0,
            }}
          >
            <ModalAcceptButton
              variant="contained"
              type="submit"
              onClick={() => props.handleAddInvoiceItems()}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Add Row
            </ModalAcceptButton>
          </ResponsiveRow>

          {props.task === "Edit" && !isNullUndefined(props.selectedInvoice) && (
            <DocumentStore
              disableShadow
              width="100%"
              files={[
                ...props.selectedInvoice.imageFiles,
                ...props.selectedInvoice.otherDocuments,
              ]}
              item={props.selectedInvoice}
              type={"Invoice"}
              maxWidth="100%"
              verticleLayout={true}
              // closeView={props.handleViewClose}
            />
          )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
