import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getPendingApprovals} from "../../../services/dashboard.service";
import Button from "@mui/material/Button";
import {
    approveCreditNote,
    approveDebitNote,
    approveDepositCharge,
    approvePayment,
    getCreditNote,
    getDebitNote,
    getDeposit,
    getPayment,
    getWorkOrder,
    getWorkOrderDetail,
    rejectCreditNote,
    rejectDebitNote,
    rejectDepositCharge,
    rejectPayment,
} from "../../../services/shared.service";
import DebitNoteView from "../../../pages/debitNoteView";
import {issueResponseMessage, issueWarnMessage,} from "../../../actions/message";
import CreditNoteView from "../../../pages/creditNoteView";
import {formatCurrency, formatDate, isEmptyString, isNullUndefined, sortJSONArray,} from "../../../util/util";
import WorkOrderReview from "../../../pages/workOrderReview";
import {
    approveWorkOrderCharge,
    approveWorkOrderData,
    rejectWorkOrderData,
    removeWorkOrderCharge,
} from "../../../services/workOrder.service";
import WorkOrderDetailReview from "../../../pages/workOrderDetailReview";
import LeaseDepositChargeReview from "../../../pages/leaseDepositChargeReview";
import {renderCellExpand} from "../../../util/renderCellExpand";
import ViewPendingPaymentInfo from "../../modals/dashboard/accountant/viewPendingPaymentInfo";
import {approveQuotationData, fetchQuotation, rejectQuotationData,} from "../../../services/quotation.service";
import QuotationReview from "../../../pages/quotationReview";
import MuiDataGrid from "../MuiDataGrid";

export default function PendingApprovals({customHeight, customWidth}) {
    const {user} = useSelector((state) => state.auth);
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [chargeModal, setChargeModal] = useState(false);
    const [creditModal, setCreditModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [workOrderModal, setWorkOrderModal] = useState(false);
    const [workOrderDetailModal, setWorkOrderDetailModal] = useState(false);
    const [depositModal, setDepositModal] = useState(false);
    const [chargeItem, setChargeItem] = useState(null);
    const [creditItem, setCreditItem] = useState(null);
    const [paymentItem, setPaymentItem] = useState(null);
    const [workOrderDetailItem, setWorkOrderDetailItem] = useState(null);
    const [workOrderItem, setWorkOrderItem] = useState(null);
    const [depositItem, setDepositItem] = useState(null);
    const [note, setNote] = useState("");
    const [quotationItem, setQuotationItem] = useState(null);
    const [quotationModal, setQuotationModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPendingApprovals()
            .then((response) => {
                if (response && response.data) {
                    setPendingApprovals(response.data.sort(sortJSONArray("date", false)));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    const handleApproveAndReviewClick = (pendingItem) => {
        switch (pendingItem.type) {
            case "Charge":
                getDebitNote(pendingItem.id).then((data) => {
                    setChargeItem(data);
                    setChargeModal(true);
                });
                break;
            case "Credit":
                getCreditNote(pendingItem.id).then((data) => {
                    setCreditItem(data);
                    setCreditModal(true);
                });
                break;
            case "Payment":
                getPayment(pendingItem.id).then((data) => {
                    setPaymentItem(data);
                    setPaymentModal(true);
                });
                break;
            case "Work Order":
                getWorkOrder(pendingItem.id).then((data) => {
                    data.actionNotes = "";
                    data.items = [];
                    data.vendors.forEach((vendor) => {
                        let items = vendor.charges.map((charge) => {
                            return {
                                vendorName: vendor.vendorName,
                                description: charge.description,
                                total: charge.total,
                                status: charge.status.name,
                            };
                        });
                        data.items.push(...items);
                    });
                    setWorkOrderItem(data);
                    setWorkOrderModal(true);
                });
                break;
            case "Quotation":
                fetchQuotation(pendingItem.id).then((data) => {
                    setQuotationItem(data);
                    setQuotationModal(true);
                });
                break;
            case "Work Order Charge":
                getWorkOrderDetail(pendingItem.id).then((data) => {
                    setWorkOrderDetailItem(data);
                    setWorkOrderDetailModal(true);
                });
                break;
            case "Deposit":
                getDeposit(pendingItem.id).then((data) => {
                    setDepositItem(data);
                    setDepositModal(true);
                });
                break;
            default:
        }
    };

    const handleApproveDebitNote = () => {
        approveDebitNote(chargeItem).then((response) => {
            issueResponseMessage(response);
            handleChargeCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectDebitNote = () => {
        rejectDebitNote(chargeItem).then((response) => {
            issueResponseMessage(response);
            handleChargeCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleApproveCreditNote = () => {
        approveCreditNote(creditItem).then((response) => {
            issueResponseMessage(response);
            handleCreditCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectCreditNote = () => {
        rejectCreditNote(creditItem).then((response) => {
            issueResponseMessage(response);
            handleCreditCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleApprovePayment = () => {
        approvePayment(paymentItem).then((response) => {
            issueResponseMessage(response);
            handlePaymentCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectDepositCharge = () => {
        rejectDepositCharge(depositItem).then((response) => {
            issueResponseMessage(response);
            handleDepositChargeCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectPayment = () => {
        rejectPayment(paymentItem).then((response) => {
            issueResponseMessage(response);
            handlePaymentCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const validateWOApprovalForm = () => {
        if (
            isNullUndefined(workOrderItem.actionNotes) ||
            isEmptyString(workOrderItem.actionNotes)
        ) {
            issueWarnMessage(
                "You must give a reason for approving or rejecting the work order."
            );
            return false;
        }
        return true;
    };

    const handleApproveWorkOrder = () => {
        validateWOApprovalForm() &&
        approveWorkOrderData(workOrderItem).then((response) => {
            issueResponseMessage(response);
            handleWorkOrderCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectWorkOrder = () => {
        validateWOApprovalForm() &&
        rejectWorkOrderData(workOrderItem).then((response) => {
            issueResponseMessage(response);
            handleWorkOrderCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleApproveQuotation = () => {
        approveQuotationData(quotationItem).then((response) => {
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(
                            response.data.sort(sortJSONArray("date", false))
                        );
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
            issueResponseMessage(response);
            handleQuotationCancel();
        });
    };

    const handleRejectQuotation = () => {
        rejectQuotationData(quotationItem).then((response) => {
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(
                            response.data.sort(sortJSONArray("date", false))
                        );
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
            issueResponseMessage(response);
            handleQuotationCancel();
        });
    };

    const handleApproveWOChargeDetail = () => {
        approveWorkOrderCharge(workOrderDetailItem.id).then((response) => {
            issueResponseMessage(response);
            handleWOChargeDetailCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleRejectWOChargeDetail = () => {
        removeWorkOrderCharge(workOrderDetailItem.id).then((response) => {
            issueResponseMessage(response);
            handleWOChargeDetailCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleApproveDepositCharge = () => {
        approveDepositCharge(depositItem).then((response) => {
            issueResponseMessage(response);
            handleDepositChargeCancel();
            setLoading(true);
            getPendingApprovals()
                .then((response) => {
                    if (response && response.data) {
                        setPendingApprovals(response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleWONotesChanged = (event) => {
        let value = event.target.value;
        setNote(value);
        let tmp = workOrderItem;
        tmp.actionNotes = value;
        setWorkOrderItem(tmp);
    };

    const handleChargeCancel = () => {
        setChargeModal(false);
        setChargeItem(null);
    };

    const handleCreditCancel = () => {
        setCreditModal(false);
        setCreditItem(null);
    };

    const handlePaymentCancel = () => {
        setPaymentModal(false);
        setPaymentItem(null);
    };

    const handleWorkOrderCancel = () => {
        setWorkOrderModal(false);
        setWorkOrderItem(null);
        setNote("");
    };

    const handleQuotationCancel = () => {
        setQuotationModal(false);
        setQuotationItem(null);
    };

    const handleWOChargeDetailCancel = () => {
        setWorkOrderDetailModal(false);
        setWorkOrderDetailItem(null);
    };

    const handleDepositChargeCancel = () => {
        setDepositModal(false);
        setDepositItem(null);
    };

    const columns = [
        {
            headerName: "Type",
            field: "type",
            width: 150,
            renderCell: renderCellExpand,
        },
        {
            headerName: "Date",
            field: "date",
            minWidth: 150,
            flex: 1,
            valueFormatter: (row) => {
                return formatDate(row.value);
            },
        },
        {
            headerName: "Amount",
            field: "amount",
            minWidth: 150,
            flex: 1,
            valueFormatter: (row) => {
                return formatCurrency(row.value);
            },
        },
        {
            headerName: "Applied To",
            field: "appliedTo",
            minWidth: 330,
            flex: 2.2,
            valueFormatter: (row) => {
                return row.value.replace(/[()]/g, "");
            },
            renderCell: renderCellExpand,
        },
        {headerName: "Created By", field: "createdBy", minWidth: 150, flex: 1},
        {
            headerName: "Action",
            field: "",
            minWidth: 170,
            flex: 1.14,
            renderCell: (params) => {
                return (
                    <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            handleApproveAndReviewClick(params.row);
                        }}
                    >
                        {user.proxyRole === "BLDIR" ? "Review & Approve" : "Review"}
                    </Button>
                );
            },
        },
    ];

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "0",
                margin: "10px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                    padding: "20px 20px 0 20px",
                    textAlign: "start",
                }}
                variant="h5"
            >
                Pending Approvals
            </Typography>
            <ResponsiveRow
                sx={{width: "100%", boxShadow: 0, padding: "10px", margin: 0}}
            >
                <MuiDataGrid
                    alt={true}
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={pendingApprovals}
                    serverPagination={false}
                    loading={loading}
                    height="90%"
                    totalRows={pendingApprovals.length}
                />
            </ResponsiveRow>
            <DebitNoteView
                openChargeModal={chargeModal}
                closeChargeModal={handleChargeCancel}
                chargeItem={chargeItem}
                approveDebitNote={handleApproveDebitNote}
                rejectDebitNote={handleRejectDebitNote}
            />

            <CreditNoteView
                openCreditModal={creditModal}
                closeCreditModal={handleCreditCancel}
                creditItem={creditItem}
                approveDebitNote={handleApproveCreditNote}
                rejectDebitNote={handleRejectCreditNote}
            />

            <ViewPendingPaymentInfo
                openModal={paymentModal}
                handleClose={handlePaymentCancel}
                selectedPayment={paymentItem}
                handleApprove={handleApprovePayment}
                handleReject={handleRejectPayment}
            />

            <WorkOrderReview
                openWorkOrderReviewModal={workOrderModal}
                closeWorkOrderModal={handleWorkOrderCancel}
                workOrderItem={workOrderItem}
                handleWONotesChaged={handleWONotesChanged}
                woNote={note}
                approveWorkOrder={handleApproveWorkOrder}
                rejectWorkOrder={handleRejectWorkOrder}
            />

            <QuotationReview
                openQuotationReviewModal={quotationModal}
                closeQuotationModal={handleQuotationCancel}
                quotationItem={quotationItem}
                approveQuotation={handleApproveQuotation}
                rejectQuotation={handleRejectQuotation}
            />

            <WorkOrderDetailReview
                openWODetailReviewModal={workOrderDetailModal}
                closeWODetailReviewModal={handleWOChargeDetailCancel}
                woDetailItem={workOrderDetailItem}
                approveWorkOrderDetail={handleApproveWOChargeDetail}
                rejectWorkOrderDetail={handleRejectWOChargeDetail}
            />

            <LeaseDepositChargeReview
                openDepositChargeModal={depositModal}
                closeDepositChargeModal={handleDepositChargeCancel}
                depositItem={depositItem}
                approveDepositCharge={handleApproveDepositCharge}
                rejectDepositCharge={handleRejectDepositCharge}
            />
        </ResponsiveRow>
    );
}
