import {
  Alert,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Add, Edit } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { YearPickerInput } from "../inputs/datePickerInput";
import { FileUpload } from "../inputs/fileUpload";
import { bytesToMegabytes, isNullUndefined } from "../../util/util";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { printClientStatement } from "../../services/print.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  DetailsLabel,
  DetailsText,
  ModalAcceptButton,
} from "../../constants/component.constants";
import { issueWarnMessage } from "../../actions/message";
import dayjs from "dayjs";
import MuiDataGrid from "../modules/MuiDataGrid";

export default function PropertyInfoCard(props) {
  const [propertySubscriptions, setPropertySubscriptions] = useState([]);
  const [setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [statementYear, setStatementYear] = useState(dayjs(new Date()));
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress] = useState(0);

  useEffect(() => {
    let tmp = [];
    let subscriptionServices = [];
    props.selectedProperties &&
      props.selectedProperties.length > 0 &&
      props.selectedProperties.forEach(function (property, i) {
        property.subscriptionServices &&
          property.subscriptionServices.length > 0 &&
          property.subscriptionServices.forEach(function (service, j) {
            let myService = {
              service: service,
              propertyName: property.name,
              serviceName:
                service && service.serviceRate && service.serviceRate.name,
            };
            subscriptionServices.push(myService);
          }, this);
      }, this);

    subscriptionServices &&
      subscriptionServices.length > 0 &&
      subscriptionServices.forEach(function (service, i) {
        let obj = {
          id: i,
          property: service.propertyName,
          service: service.serviceName,
        };
        tmp.push(obj);
      }, this);
    setPropertySubscriptions(tmp);
  }, [props.selectedProperties]);

  const columns = useMemo(() => {
    let columnArray = [];
    columnArray.push({ field: "property", headerName: "Property", width: 330 });
    columnArray.push({
      field: "service",
      headerName: "Service",
      minWidth: 340,
      flex: 1.01,
    });
    return columnArray;
  }, []);

  function handleStatementYearChange(value) {
    setStatementYear(value);
  }

  function fileValueChanged(event) {
    setBulkFile(event.target.files[0]);
  }

  function handleUploadLogoClick() {
    let fileSizeMb = bytesToMegabytes(bulkFile.size);
    let filetype = bulkFile.type;
    if (!isNullUndefined(bulkFile)) {
      if (
        filetype === "image/png" ||
        filetype === "image/jpg" ||
        filetype === "image/jpeg"
      ) {
        if (fileSizeMb > 1) {
          issueWarnMessage("Image size too big. Allowed size 1MB and below");
        } else {
          props.handleUploadLogoClick(bulkFile);
          setBulkFile(null);
        }
      } else {
        issueWarnMessage(
          "Image type not allowed. Allowed types '.png, .jpg, .jpeg'"
        );
      }
    } else {
      issueWarnMessage("Upload image is required");
    }
  }

  function handlePrintClientStatement() {
    printClientStatement(
      props.selectedSubscription.id,
      dayjs(statementYear).year()
    );
  }

  return (
    <ResponsiveRow
      container
      spacing={1}
      sx={{
        padding: "20px",
        justifyContent: { xs: "center", lg: "space-between" },
        alignItems: { xs: "center", lg: "flex-start" },
        width: { xs: "100%", md: "100%" },
        backgroundColor: "#fff",
        borderRadius: "10px",
        margin: "10px auto",
      }}
    >
      <ResponsiveRow
        sx={{
          justifyContent: "flex-start",
          margin: "auto 20px",
          width: "100%",
          height: "fit-content",
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: "18px",
          }}
        >
          Subscriber Details
        </Typography>
      </ResponsiveRow>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignText: "start",
          padding: { xs: "0 5px", lg: "20px" },
          backgroundColor: "#fff",
          width: { xs: "100%", lg: "50%" },
          height: { xs: "100%", lg: "100%" },
          //   margin: "20px",
          borderRadius: "10px",
          //   boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
        }}
      >
        <ResponsiveRow
          sx={{
            justifyContent: { xs: "center", lg: "flex-start" },
            textAlign: "start",
            width: "100%",
            flexWrap: { xs: "wrap", lg: "nowrap" },
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.25em",
              fontWeight: 800,
              marginBottom: "10px",
              alignText: "start",
              width: "100%",
              color: "#037171",
            }}
          >
            {props && props.selectedSubscriber && props.selectedSubscriber.name}
          </Typography>

          {props &&
            props.selectedSubscriber &&
            props.selectedSubscriber.type === "Company" && (
              <ModalAcceptButton
                // type="submit"
                onClick={props.handleEditOrganizationClick}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                }}
              >
                Edit {"     "}
                <Edit />
              </ModalAcceptButton>
            )}

          {props &&
            props.selectedSubscription &&
            !props.selectedSubscription.hasAccounts && (
              <IconButton
                onClick={props.handleGenerateAccountClick}
                title={"Generate Chart of Accounts"}
                sx={{
                  backgroundColor: "#2f5c41",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#2f5c41",
                  },
                }}
              >
                <Add />
              </IconButton>
            )}
        </ResponsiveRow>

        {props &&
          props.selectedSubscriber &&
          props.selectedSubscriber.type === "Individual" && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Status
                </DetailsLabel>
                <DetailsText>
                  {props &&
                  props.selectedSubscriber &&
                  props.selectedSubscriber.statusCd === "ACT"
                    ? "Active"
                    : ""}
                  {props &&
                  props.selectedSubscriber &&
                  props.selectedSubscriber.statusCd === "SUS"
                    ? "Suspended"
                    : ""}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Email
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.email}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Mobile Phone
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.mobilePhone}
                </DetailsText>
              </ResponsiveRow>{" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  //   justifyContent: "flex-end",
                  padding: "5px 0",
                  width: "100%",
                }}
              >
                <FormControl
                  fullWidth
                  sx={{
                    margin: { xs: "10px 0", md: "5px auto", lg: "10px auto " },
                    width: { xs: "100%", lg: "100%" },
                  }}
                >
                  <YearPickerInput
                    labelText="Select Statement Year"
                    componentID="demoYear"
                    disableFuture={true}
                    disablePast={false}
                    onSelectDate={handleStatementYearChange}
                    value={dayjs(statementYear)}
                  />
                </FormControl>
                <ResponsiveRow
                  sx={{
                    justifyContent: { xs: "center", lg: "flex-end" },
                    width: "100%",
                  }}
                >
                  <ModalAcceptButton
                    onClick={handlePrintClientStatement}
                    sx={{
                      width: { xs: "100%", lg: "200px" },
                    }}
                  >
                    <LocalPrintshopIcon />
                    print Statement
                  </ModalAcceptButton>
                </ResponsiveRow>
              </Box>
            </Box>
          )}

        {props &&
          props.selectedSubscriber &&
          props.selectedSubscriber.type === "Company" && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  textAlign: "start",
                  padding: "10px",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Address
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.address &&
                    props.selectedSubscriber.address.fullAddress1}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  City
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.address &&
                    props.selectedSubscriber.address.city}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Status
                </DetailsLabel>
                <DetailsText>
                  {props &&
                  props.selectedSubscriber &&
                  props.selectedSubscriber.statusCd === "ACT"
                    ? "Active"
                    : ""}
                  {props &&
                  props.selectedSubscriber &&
                  props.selectedSubscriber.statusCd === "SUS"
                    ? "Suspended"
                    : ""}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Contact Person
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.contact &&
                    props.selectedSubscriber.contact.name}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Email
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.contact &&
                    props.selectedSubscriber.contact.email}
                </DetailsText>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "start",
                  padding: "5px 10px ",
                }}
              >
                <DetailsLabel sx={{ minWidth: { xs: "100%", lg: "30%" } }}>
                  Mobile Phone
                </DetailsLabel>
                <DetailsText>
                  {props &&
                    props.selectedSubscriber &&
                    props.selectedSubscriber.contact &&
                    props.selectedSubscriber.contact.mobilePhone}
                </DetailsText>
              </ResponsiveRow>{" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  //   justifyContent: "flex-end",
                  padding: "5px 0",
                  width: "100%",
                }}
              >
                <FormControl
                  fullWidth
                  sx={{
                    margin: { xs: "10px 0", md: "5px auto", lg: "10px auto " },
                    width: { xs: "100%", lg: "100%" },
                  }}
                >
                  <YearPickerInput
                    labelText="Select Statement Year"
                    componentID="demoYear"
                    disableFuture={true}
                    disablePast={false}
                    onSelectDate={handleStatementYearChange}
                    value={dayjs(statementYear)}
                  />
                </FormControl>
                <ResponsiveRow
                  sx={{
                    justifyContent: { xs: "center", lg: "flex-end" },
                    width: "100%",
                  }}
                >
                  <ModalAcceptButton
                    onClick={handlePrintClientStatement}
                    sx={{
                      width: { xs: "100%", lg: "200px" },
                    }}
                  >
                    <LocalPrintshopIcon sx={{ margin: "auto 5px" }} />
                    print Statement
                  </ModalAcceptButton>
                </ResponsiveRow>
              </Box>
              <Alert
                severity="info"
                sx={{ marginTop: "1%", marginBottom: "1%" }}
              >
                The organization logo can be changed by choosing an image of
                types '.png, .jpg, .jpeg' and a maximum size of 1 MB. For best
                results use an image of resolution 500 x 354.
              </Alert>
              <ResponsiveRow
                sx={{
                  justifyContent: "flex-start",
                  padding: "5px 0 ",
                  marginTop: "10px",
                }}
              >
                <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <Box
                    component="img"
                    sx={{
                      //   height: {250},
                      //   width: {300},
                      maxHeight: { xs: 250, md: 150 },
                      maxWidth: { xs: 300, md: 200 },
                    }}
                    alt={"Organization Logo"}
                    src={props.selectedSubscriber.logoUrl}
                  />
                </Grid>

                <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "100%" },
                      padding: "5px 7px",
                      margin: { xs: "10px auto", lg: "auto 10px" },
                    }}
                  >
                    <FileUpload
                      id={"bulkFileInput"}
                      currentFile={bulkFile}
                      progress={bulkFileProgress}
                      onChange={fileValueChanged}
                      accept={".png, .jpg, .jpeg"}
                    />
                  </FormControl>
                  <ModalAcceptButton
                    disabled={isNullUndefined(bulkFile)}
                    onClick={handleUploadLogoClick}
                    sx={{
                      width: { xs: "100%", lg: "100%" },
                    }}
                  >
                    Change logo
                  </ModalAcceptButton>
                </Grid>
              </ResponsiveRow>
            </Box>
          )}
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          alignText: "start",
          paddingTop: { xs: 0, lg: "20px" },
          paddingBottom: { xs: "20px", lg: 0 },
          backgroundColor: "#fff",
          width: { xs: "100%", lg: "50%" },
          height: { xs: "100%", lg: "100%" },
          //   margin: "20px",
          borderRadius: "10px",
          //   boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
        }}
      >
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            textAlign: "start",
            width: "100%",
            padding: "5px 10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.25em",
              fontWeight: 800,
              marginBottom: "10px",
              alignText: "start",
              width: "100%",
              color: "#070707",
            }}
          >
            Property Subscriptions
          </Typography>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            justifyContent: { xs: "center", lg: "space-between" },
            // margin: "0 20px",
            width: "100%",
            maxHeight: "567px",
          }}
        >
          <MuiDataGrid
            loading={false}
            alt={true}
            noBoxShadow={true}
            noSelection={true}
            serverPagination={true}
            handlePageLoad={setPaginationModel}
            dataGridColumns={columns}
            dataGridRows={propertySubscriptions}
            totalRows={propertySubscriptions.length}
            height="60vh"
          />
        </ResponsiveRow>
      </Grid>
    </ResponsiveRow>
  );
}
