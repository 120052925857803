import { useEffect, useState } from "react";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import {
  formatCurrency,
  formatDate,
  isEmptyString,
  isNullUndefined,
} from "../util/util";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { History, Visibility } from "@mui/icons-material";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import { listPettyCashEntries } from "../services/pettyCash.service";
import {
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { Typography } from "@mui/material";
import PettyCashTopup from "../components/modals/accounting/petty cash/pettyCashTopup";
import ViewPettyCash from "../components/modals/accounting/petty cash/viewPettyCash";
import ReverseTopupModal from "../components/modals/accounting/petty cash/reverseTopup";

const PettyCash = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [add, setAdd] = useState(false);
  const [view, setView] = useState(false);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    setLoading(true);
    listPettyCashEntries()
      .then((data) => {
        setTransactions(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!add || !reverse) {
      setLoading(true);
      listPettyCashEntries()
        .then((data) => {
          setTransactions(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [add, reverse]);

  const dataGridColumns = [
    { field: "description", headerName: "Description", minWidth: 400, flex: 5 },
    {
      field: "entryDate",
      headerName: "Entry Date",
      minWidth: 150,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    {
      field: "bankAccount",
      headerName: "Bank Account",
      minWidth: 200,
      flex: 3.5,
    },
    {
      field: "ledgerAccount",
      headerName: "Ledger Account",
      minWidth: 200,
      flex: 3.5,
    },
    { field: "appliedTo", headerName: "Applied To", minWidth: 200, flex: 3.5 },
    {
      field: "createdBy",
      headerName: "Created By",
      minWidth: 200,
      flex: 2.75,
      valueFormatter: (row) => {
        if (isNullUndefined(row.value) || isEmptyString(row.value)) {
          return "System Generated";
        } else {
          return row.value;
        }
      },
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      minWidth: 110,
      flex: 1.1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => handleViewClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<History />}
          label="Reverse Top-up"
          onClick={() => handleReverseClick(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const handleViewClick = (journalEntry) => {
    setSelectedTransaction(journalEntry);
    setView(true);
  };

  const handleReverseClick = (journalEntry) => {
    setSelectedTransaction(journalEntry);
    setReverse(true);
  };

  const handleAddClick = () => {
    setAdd(true);
  };

  const handlePrintReport = () => {};

  return (
    <Permit
      roles="BLDIR,ACC,BLDMG,CS"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",
          flexWrap: "nowrap",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: 0,
        }}
      >
        <TopBarContainer
          sx={{
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            width: { xs: "100%", lg: "80%" },
            marginBottom: "2vh",
            height: "10vh",
          }}
          container
        >
          <Typography
            variant="h5"
            sx={{
              width: { xs: "100%", lg: "25%" },
              height: "auto",
              textAlign: { xs: "center", lg: "start" },
            }}
          >
            Petty Cash Management
          </Typography>

          <ResponsiveRow
            item
            sx={{
              width: { xs: "100%", lg: "75%" },
              padding: 0,
              alignItems: "center",
              justifyContent: { xs: "center", lg: "flex-end" },
            }}
            container
          >
            <TopBarContainedBrandButton
              sx={{ width: { xs: "100%", lg: "200px" } }}
              onClick={handleAddClick}
            >
              Top-up Petty Cash
            </TopBarContainedBrandButton>
            <TopBarContainedBrandButton
              sx={{ width: { xs: "100%", lg: "200px" } }}
              onClick={handlePrintReport}
            >
              Petty Cash Book Report
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </TopBarContainer>
        <ReverseTopupModal
          open={reverse ?? false}
          close={setReverse}
          topUp={selectedTransaction}
        />
        <PettyCashTopup open={add ?? false} close={setAdd} />
        <ViewPettyCash
          open={view ?? false}
          close={() => {
            setView(false);
          }}
          pettyCash={selectedTransaction}
        />
        <MuiDataGrid
          dataGridColumns={dataGridColumns}
          noSelection={true}
          dataGridRows={transactions}
          loading={loading}
          height="60vh"
          totalRows={transactions.length}
        />
      </ResponsiveRow>
    </Permit>
  );
};

export default PettyCash;
