import { Box, Grid, Typography } from "@mui/material";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../constants/component.constants";
import { formatCurrency, formatDate, isNullUndefined } from "../../util/util";
import { DocumentStore } from "../documentStore";
import ModalContainerModule from "../modules/modalContainer";
import MuiDataGrid from "../modules/MuiDataGrid";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function ViewQuotation(props) {
  const columns = [
    {
      field: "memo",
      headerName: "Memo",
      minWidth: 400,
      flex: 2,
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 200,

      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 200,
      flex: 1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "subTotal",
      headerName: "Sub Total",
      minWidth: 200,
      flex: 1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "otherCharges",
      headerName: "Other Charges",
      minWidth: 200,
      flex: 1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "total",
      headerName: "Total",
      minWidth: 200,
      flex: 1,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "applyVAT",
      headerName: "Apply VAT",
      minWidth: 140,
      flex: 0.7,
      valueFormatter: (row) => {
        return row.value ? "Yes" : "No";
      },
    },
  ];

  return (
    <ModalContainerModule
      size="md"
      cancel={props.handleClose}
      openModal={props.openModal}
      modalTitle={`View Quotation ${
        props.selectedQuotation
          ? ": " + props.selectedQuotation.description
          : ""
      }`}
      cancelText="Close"
    >
      <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
        Quotation Details
      </Typography>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "center", lg: "center" },
          width: "100%",
        }}
      >
        <DetailsContainer item sx={{ width: { xs: "100%", lg: "30%" } }}>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "100%" },
            }}
          >
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Description:</DetailsLabel>
              <DetailsText>
                {props.selectedQuotation && props.selectedQuotation.description}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Vendor:</DetailsLabel>
              <DetailsText>
                {props.selectedQuotation && props.selectedQuotation.vendorName}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Amount</DetailsLabel>
              <DetailsText>
                {props.selectedQuotation &&
                  props.selectedQuotation.total &&
                  formatCurrency(props.selectedQuotation.total)}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Status</DetailsLabel>
              <DetailsText sx={{ fontSize: "1rem", width: "100%" }}>
                {props.selectedQuotation &&
                  props.selectedQuotation.status &&
                  props.selectedQuotation.status.name}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "100%" },
            }}
          >
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Work Order:</DetailsLabel>
              <DetailsText>
                {isNullUndefined(props.selectedQuotation) ||
                isNullUndefined(props.selectedQuotation.workOrder) ||
                isNullUndefined(props.selectedQuotation.workOrder.refNumber)
                  ? "N/A"
                  : props.selectedQuotation.workOrder.refNumber}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Added On:</DetailsLabel>
              <DetailsText>
                {props.selectedQuotation &&
                  props.selectedQuotation.addedOn &&
                  formatDate(props.selectedQuotation.addedOn)}
              </DetailsText>
            </DetailsRow>
            {props.selectedQuotation &&
              props.selectedQuotation.statusCd &&
              props.selectedQuotation.statusCd === "APP" && (
                <DetailsRow>
                  <DetailsLabel>Approved By:</DetailsLabel>
                  <DetailsText sx={{ fontSize: "1rem", width: "100%" }}>
                    {props.selectedQuotation &&
                      props.selectedQuotation.approvedBy &&
                      props.selectedQuotation.approvedBy.name}
                  </DetailsText>
                </DetailsRow>
              )}
            {props.selectedQuotation &&
              props.selectedQuotation.statusCd &&
              props.selectedQuotation.statusCd === "APP" && (
                <DetailsRow>
                  <DetailsLabel>Approved On:</DetailsLabel>
                  <DetailsText>
                    {props.selectedQuotation &&
                      props.selectedQuotation.approvedOn &&
                      formatDate(props.selectedQuotation.approvedOn)}
                  </DetailsText>
                </DetailsRow>
              )}
            {props.selectedQuotation &&
              props.selectedQuotation.statusCd &&
              props.selectedQuotation.statusCd === "REJ" && (
                <DetailsRow>
                  <DetailsLabel>Rejected By:</DetailsLabel>
                  <DetailsText sx={{ fontSize: "1rem", width: "100%" }}>
                    {props.selectedQuotation &&
                      props.selectedQuotation.rejectedBy &&
                      props.selectedQuotation.rejectedBy.name}
                  </DetailsText>
                </DetailsRow>
              )}
            {props.selectedQuotation &&
              props.selectedQuotation.statusCd &&
              props.selectedQuotation.statusCd === "REJ" && (
                <DetailsRow>
                  <DetailsLabel>Rejected On:</DetailsLabel>
                  <DetailsText>
                    {props.selectedQuotation &&
                      props.selectedQuotation.rejectedBy &&
                      formatDate(props.selectedQuotation.rejectedBy)}
                  </DetailsText>
                </DetailsRow>
              )}
          </DetailsColumn>
        </DetailsContainer>

        <ResponsiveRow
          item
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",

            justifyContent: { xs: "center", lg: "flex-start" },
            alignItems: "flex-start",

            height: "100%",
            width: { xs: "100%", lg: "70%" },

            padding: "10px",
          }}
        >
          {props &&
            !isNullUndefined(props.selectedQuotation) &&
            props.selectedQuotation && (
              <DocumentStore
                files={
                  props.selectedQuotation.imageFiles.length !== undefined &&
                  props.selectedQuotation.otherDocuments
                    ? [
                        ...props.selectedQuotation.imageFiles,
                        ...props.selectedQuotation.otherDocuments,
                      ]
                    : {
                        ...props.selectedQuotation.imageFiles,
                        ...props.selectedQuotation.otherDocuments,
                      }
                }
                item={props.selectedQuotation}
                type={"Quotation"}
                disableShadow
                height="300px"
                maxWidth="100%"
                verticleLayout={true}
              />
            )}
        </ResponsiveRow>
      </Grid>

      <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
        Quotation Items
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },

          width: "100%",
          height: "auto",

          justifyContent: { xs: "space-between", lg: "center" },
          padding: "10px",
        }}
      >
        <MuiDataGrid
          noSelection={true}
          alt={true}
          dataGridColumns={columns}
          dataGridRows={
            props.selectedQuotation && props.selectedQuotation.quotationItems
          }
          loading={false}
          height="30vh"
          totalRows={props.selectedQuotation.length}
        />
      </Box>
    </ModalContainerModule>
  );
}
