import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import kodi_theme from "./themes/kodiThemeProvider";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import Property from "./pages/property";
import Users from "./pages/users";
import SubscriptionServices from "./pages/subscriptionServices";
import MobileMoney from "./pages/mobileMoney/mobileMoneyReconciliation";
import Messages from "./pages/messages";
import Leases from "./pages/leases";
import Ownerships from "./pages/ownerships";
import TerminatedOwnerships from "./pages/terminatedOwnerships";
import Meters from "./pages/meters";
import BillsPayments from "./pages/billsPayments";
import Invoices from "./pages/invoices";
import Receipts from "./pages/receipts";
import Budget from "./pages/budget";
import CapitalFund from "./pages/capitalFund";
import Faq from "./pages/faq";
import JournalEntries from "./pages/journalEntries";
import Reconciliation from "./pages/reconciliation";
import Bank from "./pages/bank";
import Security from "./pages/security";
import Profile from "./pages/profile";
import Customers from "./pages/customers";
import Subscriptions from "./pages/subscriptions";
import Login from "./pages/login";
import ConfirmFacebookDelete from "./pages/confirmFacebookDelete";
import ForgotPassword from "./pages/forgotPassword";
import Password from "./pages/password";
import ChangePassword from "./pages/changePassword";
import Unauthorized from "./pages/unauthorized";
import BarComponents from "./components/barComponents";
import MeterBilling from "./pages/meterBilling";
import KodiLayout from "./components/layouts/kodiLayout";
import TerminatedMeterBilling from "./pages/terminatedMeterBilling";
import ArchivedMeters from "./pages/archivedMeters";
import InputComponents from "./components/inputsComponents";
import CardComponents from "./components/cardComponents";
import LeaseView from "./pages/leaseView";
import WorkOrderView from "./pages/workOrder/workOrderView";
import Complex from "./pages/complex";
import TerminatedLeases from "./pages/terminatedLeases";
import WorkOrders from "./pages/workOrder/workOrders";
import ModalsComponents from "./components/modalComponents";
import Assets from "./pages/assets";
import Vendors from "./pages/vendors";
import Tenants from "./pages/tenants";
import Owners from "./pages/owners";
import MobileMoneyTransfer from "./pages/mobileMoney/mobileMoneyTransfer";
import MobileMoneySummary from "./pages/mobileMoney/mobileMoneySummary";
import Accounts from "./pages/accounts";
import SignUp from "./pages/signUp/signUp";
import SignUpAnalysis from "./pages/signUp/signUpAnalysis";
import SelfSignOn from "./pages/selfSignOn";
import ListingLanding from "./pages/listings/listingLanding";
import ListingExplore from "./pages/listings/listingExplore";
import ListingView from "./pages/listings/listingView";
import ListingSignIn from "./pages/listings/auth/listingSignIn";
import ListingRegister from "./pages/listings/auth/listingRegister";
import ListingFavourites from "./pages/listings/dashboard/listingFavourites";
import ListingAppointments from "./pages/listings/dashboard/listingAppointments";
import ListingApplications from "./pages/listings/dashboard/listingApplications";
import ListingManagement from "./pages/listings/dashboard/listingManagement";
import { Alert, AlertTitle, ThemeProvider } from "@mui/material";
import PrivateRoute from "./components/privateRoute";
import PublicLayout from "./components/layouts/publicLayout";
import ListingsLayout from "./components/layouts/listingsLayout";
import PrivateListingRoute from "./components/privateListingRoute";
import { useSelector } from "react-redux";
import { titleCase } from "./util/util";
import { ResponsiveRow } from "./constants/layout.constants";
import ListingMyList from "./pages/listings/dashboard/listingMyList";
import ChartsComponents from "./components/ChartsComponents";
import CreditNotes from "./pages/creditNotes";
import DebitNotes from "./pages/debitNotes";
import Quotations from "./pages/quotations";
import Repairs from "./pages/repairs";
import Test from "./pages/test";
import PettyCash from "./pages/pettyCash";
import Documents from "./pages/documents";
import JournalV2 from "./pages/journal.v2";

//themes can be extracted later to their own files
// reference for fields to set https://mui.com/customization/default-theme/
//primary light theme that is scoped for the application

function App() {
  // const [light] = useState(true)//swap between themes

  //single message handler for the application

  const appMessage = useSelector((state) => state.message);

  return (
    <ThemeProvider theme={kodi_theme}>
      <div className="App">
        <Routes>
          {/*unsecure routes*/}
          <Route exact path="/components/bars" element={<BarComponents />} />
          <Route
            exact
            path="/components/charts"
            element={<ChartsComponents />}
          />
          <Route
            exact
            path="/components/inputs"
            element={<InputComponents />}
          />
          <Route exact path="/components/cards" element={<CardComponents />} />{" "}
          <Route
            exact
            path="/components/modals"
            element={<ModalsComponents />}
          />
          <Route
            exact
            path="/"
            element={
              <PublicLayout>
                {appMessage.display && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      position: "relative",
                      zIndex: 200,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "start",
                    }}
                  >
                    <Alert
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      severity={appMessage.type}
                    >
                      <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                      {appMessage.message}
                    </Alert>
                  </ResponsiveRow>
                )}
                <Login />
              </PublicLayout>
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <PublicLayout>
                {appMessage.display && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      height: "15%",
                      position: "relative",
                      zIndex: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      severity={appMessage.type}
                    >
                      <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                      {appMessage.message}
                    </Alert>
                  </ResponsiveRow>
                )}
                <ForgotPassword />
              </PublicLayout>
            }
          />
          <Route
            path="/changePassword"
            element={
              <PublicLayout>
                {appMessage.display && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      height: "15%",
                      position: "relative",
                      zIndex: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      severity={appMessage.type}
                    >
                      <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                      {appMessage.message}
                    </Alert>
                  </ResponsiveRow>
                )}
                <ChangePassword />
              </PublicLayout>
            }
          />
          <Route
            path="/confirmFacebookDelete"
            element={
              <PublicLayout>
                {appMessage.display && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      height: "15%",
                      position: "relative",
                      zIndex: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      severity={appMessage.type}
                    >
                      <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                      {appMessage.message}
                    </Alert>
                  </ResponsiveRow>
                )}
                <ConfirmFacebookDelete />
              </PublicLayout>
            }
          />
          <Route
            path="/password"
            element={
              <PublicLayout>
                {appMessage.display && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      height: "15%",
                      position: "relative",
                      zIndex: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      severity={appMessage.type}
                    >
                      <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                      {appMessage.message}
                    </Alert>
                  </ResponsiveRow>
                )}
                <Password />
              </PublicLayout>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <PublicLayout>
                <Unauthorized />
              </PublicLayout>
            }
          />
          <Route
            path="/signUp"
            element={
              <PublicLayout>
                <SignUp />
              </PublicLayout>
            }
          />
          <Route
            path="/signUpTemp"
            element={
              <PublicLayout>
                <SelfSignOn />
              </PublicLayout>
            }
          />
          {/*<Route path="/listings" element={<ListingExplore />} />*/}
          {/* listing page routes */}
          <Route
            path="/listings"
            element={
              <ListingsLayout>
                <ListingLanding />
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/signIn"
            element={
              <ListingsLayout>
                <ListingSignIn />
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/register"
            element={
              <ListingsLayout>
                <ListingRegister />
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/explore"
            element={
              <ListingsLayout>
                <ListingExplore />
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/view/:listingId"
            element={
              <ListingsLayout>
                <ListingView />
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/dashboard/favorites"
            element={
              <ListingsLayout>
                <PrivateListingRoute>
                  <ListingFavourites />
                </PrivateListingRoute>
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/dashboard/my-lists"
            element={
              <ListingsLayout>
                <PrivateListingRoute>
                  <ListingMyList />
                </PrivateListingRoute>
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/dashboard/appointments"
            element={
              <ListingsLayout>
                <PrivateListingRoute>
                  <ListingAppointments />
                </PrivateListingRoute>
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/dashboard/applications"
            element={
              <ListingsLayout>
                <PrivateListingRoute>
                  <ListingApplications />
                </PrivateListingRoute>
              </ListingsLayout>
            }
          />
          <Route
            path="/listings/dashboard/ManageListings"
            element={
              <ListingsLayout>
                <PrivateListingRoute>
                  <ListingManagement />
                </PrivateListingRoute>
              </ListingsLayout>
            }
          />
          {/*secure routes*/}
          <Route
            path="/dashboard"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/property"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Property />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/complex"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Complex />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/users"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/mobileMoneySummary"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <MobileMoneySummary />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/mobileMoneyReconciliation"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <MobileMoney />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/mobileMoneyTransfer"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <MobileMoneyTransfer />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/messages"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/leases"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Leases />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/signUpAnalysis"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <SignUpAnalysis />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/terminatedLeases"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <TerminatedLeases />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/leaseView"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <LeaseView />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/tenants"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Tenants />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/ownerships"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Ownerships />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/terminatedOwnerships"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <TerminatedOwnerships />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/owners"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Owners />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/meters"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Meters />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/archivedMeters"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <ArchivedMeters />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/meterBilling"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <MeterBilling />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          {/* <Route
            path="/meterBillingView"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <MeterBillingView />
                </PrivateRoute>
              </KodiLayout>
            }
          /> */}
          <Route
            path="/terminatedMeterBilling"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <TerminatedMeterBilling />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/billsPayments"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <BillsPayments />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/invoices"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Invoices />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/documents"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Documents />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/receipts"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Receipts />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/creditNotes"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <CreditNotes />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/debitNotes"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <DebitNotes />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/budget"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Budget />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/capitalFund"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <CapitalFund />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/journalEntries"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <JournalEntries />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/journalV2"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <JournalV2 />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/pettyCash"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <PettyCash />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/reconciliation"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Reconciliation />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/bank"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Bank />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/accounts"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Accounts />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/security"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Security />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/faq"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Faq />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/customers"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Customers />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/assets"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Assets />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Subscriptions />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/subscriptionServices"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <SubscriptionServices />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/workOrders"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <WorkOrders />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/workOrderView"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <WorkOrderView />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/test/testCreditTransfer"
            element={
              <PrivateRoute>
                <Test />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/testTransactionQuery"
            element={
              <PrivateRoute>
                <Test />
              </PrivateRoute>
            }
          />
          <Route
            path="/repair/rebuildLeaseHistory"
            element={
              <PrivateRoute>
                <Repairs />
              </PrivateRoute>
            }
          />
          <Route
            path="/repair/rebuildOwnershipHistory"
            element={
              <PrivateRoute>
                <Repairs />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendors"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Vendors />
                </PrivateRoute>
              </KodiLayout>
            }
          />
          <Route
            path="/quotations"
            element={
              <KodiLayout>
                <PrivateRoute>
                  <Quotations />
                </PrivateRoute>
              </KodiLayout>
            }
          />
        </Routes>
        <CssBaseline />
      </div>
    </ThemeProvider>
  );
}

export default App;
