import {Grid, Typography} from "@mui/material"
import {SmartKodiMap, createMarkers} from "../../components/map"
import {useCallback, useEffect, useState} from "react"
import ListingNavbar from "../../components/bars/listingNavbar"
import {ResponsiveRow} from "../../constants/layout.constants"
import Back from "@mui/icons-material/ChevronLeft"
import Straighten from "@mui/icons-material/Straighten"
import Bed from "@mui/icons-material/AirlineSeatFlat"
import Bath from "@mui/icons-material/Bathtub"
import {Box} from "@mui/system"
import {
    DetailsCardContainer,
    ModalAcceptButton,
    ModalCancelButton,
    StyledDataGrid
} from "../../constants/component.constants"
import Favorite from "@mui/icons-material/Favorite"
import NotFavorite from "@mui/icons-material/FavoriteBorder"
import ListingApplicationModal from "../../components/modals/listings/listingApplicationModal"
import ListingBookingModal from "../../components/modals/listings/listingBookingModal"
import {useDispatch, useSelector} from "react-redux"
import ListingDashboardNavbar from "../../components/bars/listingDashboardNavbar"
import {formatDate, formatDateTime, isEmptyArray, isNullUndefined} from "../../util/util"
import RegisterForm from "./auth/registerForm"
import {
    cancelTourBooking,
    getAppointmentForListing, listAppointmentsForListing,
    saveFavoriteListing,
} from "../../services/listingsServices/listing.service"
import {setMessage} from "../../actions/message"
import IconButton from "@mui/material/IconButton"
import ListingGalleryViewer from "../../components/modules/listings/listingGalleryViewer"
import ListingModalContainerModule from "../../components/modules/listingModalContainer"
import {GET_LISTING_IMAGE_URL} from "../../constants/url.constants";

export default function ListingView(props) {
    const user = useSelector((state) => state.auth.user);
    const [selectedImage, setSelectedImage] = useState()
    const [favorite, setFavorite] = useState(false)
    const {isLoggedIn} = useSelector((state) => state.auth)
    const [openBookingModal, setOpenBookingModal] = useState(false)
    const [openApplicationModal, setOpenApplicationModal] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)
    const [galleryModal, setGalleryModal] = useState(false)
    const [hasABooking, setHasABooking] = useState(false)
    const [myAppointment, setMyAppointment] = useState()
    const [appointments, setAppointments] = useState([])
    const [loading, setLoading] = useState(true)
    const [action, setAction] = useState("save")
    const [total, setTotal] = useState(0)
    const [selectionModel, setSelectionModel] = useState([])
    const [rowsPerPage] = useState(12)
    const [listing] = useState(props.listing)
    const [listings] = useState(props.listings || [])
    const [markers, setMarkers] = useState(null)
    const [center, setCenter] = useState(null)
    
    const dispatch = useDispatch()

    const columns = [
        {headerName: 'Appointment Date', field: 'appointmentDate', width: "300",
            valueFormatter: (row) => {
                const date = new Date(row.value)
                return formatDateTime(date)
            },
        },
        {headerName: 'Visitor', field: 'visitorName', width: '300'},
        {headerName: 'Email', field: 'visitorEmail', width: '300'},
        {headerName: 'Contact', field: 'visitorContact', width: '300'},
    ]

    useEffect(() => {
        setCenter({lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude)})
        createMarkers(setMarkers, listings, listing)
    }, [listing, listings])

    const fetchOwnTourBookingForListing = useCallback(() => {
        getAppointmentForListing(listing.id)
            .then((response) => {
                const target = response?.appointment
                const appointment = {}
                if(!isNullUndefined(target)) {
                    appointment.id = target.id
                    appointment.date = target.appointmentDate
                    appointment.facility = target.listing.propertyName
                    appointment.created = target.dateCreated
                    setHasABooking(true)
                } else {
                    setHasABooking(false)
                }
                setMyAppointment(appointment)
            })
        setAction("save")
    }, [listing])

    useEffect(() => {
        if (listing.favorite === true) {
            setFavorite(listing.favorite)
        }

        if(isNullUndefined(user)) {
            setLoading(false)
            return
        } else if(listing.addedBy === user?.userId) {
            // fetch all tour bookings for this listing
            listAppointmentsForListing(listing.id)
                .then((response) => {
                    parseAppointmentsData(response)
                })
        } else {
            // fetch my tour booking for this listing
            fetchOwnTourBookingForListing()
        }
        setLoading(false)
    }, [fetchOwnTourBookingForListing, listing, user])

    function parseAppointmentsData(data) {
        const newAppointmentsData = []
        data?.forEach(target => {
            const appointment = {
                id: target.id,
                appointmentDate: target.appointmentDate,
                visitorName: target.appointments.name,
                visitorEmail: target.appointments.email,
                visitorContact: target.appointments.phone,
            }
            newAppointmentsData.push(appointment)
        })
        setAppointments(newAppointmentsData)
        setTotal(data.length)
    }

    function handleApplicationClose() {
        setOpenApplicationModal(false)
    }

    function handleBookingClose() {
        setOpenBookingModal(false)
    }

    function handleApplyClicked() {
        isLoggedIn ? setOpenApplicationModal(true) : setRegisterModal(true)
    }

    function handleBookTourClicked() {
        isLoggedIn ? setOpenBookingModal(true) : setRegisterModal(true)
    }

    const handleFavClicked = () => {
        if (!isLoggedIn){
            setRegisterModal(true)
            return
        }

        let sendData = {
            listingId: props.listing.id
        }

        /**
         * Params to pass to backend:
         * -listingId
         * -isFavorite(true/false)
         * by: rcheruiyot
         */
        saveFavoriteListing(sendData)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                )
                setFavorite(!favorite)
            })
    }

    function editAppointmentClicked() {
        setAction("edit")
        setOpenBookingModal(true)
    }

    function cancelAppointmentClicked() {
        cancelTourBooking(myAppointment?.id)
            .then(response => {
                fetchOwnTourBookingForListing()
                setHasABooking(false)
                dispatch(
                    setMessage({
                        type: response?.type,
                        message: response?.message
                    })
                )

            })
    }

    return (
        <Grid sx={{backgroundColor: "#efefef"}} container>
            {isLoggedIn ? <ListingDashboardNavbar/> : <ListingNavbar/>}
            {registerModal && (
                <ListingModalContainerModule
                    size="specialListingCreateAccount"
                    cancel={() => setRegisterModal(false)}
                    openModal={registerModal}
                    modalTitle="Create an account to start the application"
                    modalSubTitle="Sign-up form"
                    hideCancel={true}
                >
                    <RegisterForm width="100%"/>
                </ListingModalContainerModule>
            )}
            {props.listing && (
                <>
                    <ResponsiveRow
                        sx={{
                            width: "auto",
                            marginTop: "120px",
                            alignItems: "center",
                            flexDirection: "row",
                        }}
                    >
                        <Back
                            sx={{fontSize: "2rem"}}
                            onClick={() => props.backClicked()}
                        />
                        {/* <Typography variant="h4"> Back</Typography> */}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            // flexDirection: { xs: "reverse-row", lg: "row" },
                        }}
                    >
                        <ResponsiveRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: {xs: "100%", lg: "50%"},
                                height: "100%",
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "80%",
                                    maxHeight: {xs: "40vh", lg: "60vh"},
                                    width: "100%",
                                    objectFit: "cover",
                                    margin: "auto",
                                    borderRadius: "20px",
                                }}
                                src={
                                    selectedImage
                                        ? selectedImage
                                        : props.listing &&
                                        props.listing.imageFiles &&
                                        !isEmptyArray(props.listing.imageFiles)
                                            ? GET_LISTING_IMAGE_URL + "?filename=" + (
                                            props.listing &&
                                            props.listing.imageFiles &&
                                            props.listing.imageFiles[0] &&
                                            props.listing.imageFiles[0].systemFileName
                                        )
                                            : ""
                                }
                                alt="image"
                                onClick={() => {
                                    setGalleryModal(true)
                                }}
                            />

                            <ResponsiveRow
                                sx={{
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    overflowX: "auto",
                                    width: "90%",
                                    margin: "auto",
                                }}
                            >
                                {props.listing &&
                                    props.listing.imageFiles.map((image, i) => {
                                        let img = GET_LISTING_IMAGE_URL + "?filename=" + (
                                            props.listing &&
                                            props.listing.imageFiles &&
                                            props.listing.imageFiles[i] &&
                                            props.listing.imageFiles[i].systemFileName
                                        )
                                        return (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: "150px",
                                                    width: "150px",
                                                    objectFit: "cover",
                                                    padding: "10px",
                                                    borderRadius: "20px",
                                                }}
                                                src={img}
                                                alt="image"
                                                onClick={() => setSelectedImage(img)}
                                                key={i}
                                            />
                                        )
                                    })}
                            </ResponsiveRow>
                        </ResponsiveRow>
                        <ResponsiveRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: {xs: "100%", lg: "50%"},
                                height: "100%",
                                textAlign: "start",
                            }}
                        >
                            <ResponsiveRow>
                                <Typography
                                    sx={{fontSize: "1.7rem", fontWeight: 900, paddingBottom: 0}}
                                >
                                    {props.listing.propertyName}
                                </Typography>
                            </ResponsiveRow>
                            <ResponsiveRow
                                sx={{
                                    width: "90%",
                                    justifyContent: {xs: "flex-start", lg: "space-between"},
                                    flexDirection: {xs: "column", lg: "row"},
                                }}
                            >
                                {props.listing.plusCode ? (
                                    <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        title={"View on Map"}
                                        href={
                                            "https://www.google.com.sa/maps/search/" +
                                            encodeURIComponent(props.listing.plusCode) +
                                            "?hl=en"
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {props.listing.plusCode}
                                        </div>
                                    </a>
                                ) : (
                                    "No Location Info"
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: 600,
                                        color: "#037171",
                                    }}
                                >
                                    Ksh {props.listing.price}
                                </Typography>
                            </ResponsiveRow>

                            <ResponsiveRow sx={{alignItems: "center"}}>
                                {favorite ? (
                                    <IconButton
                                        aria-label="Favorite"
                                        onClick={() => {
                                            handleFavClicked()
                                        }}
                                    >
                                        <Favorite
                                            sx={{
                                                margin: "auto 5px",
                                                color: "red",
                                                zIndex: 3,
                                                fontSize: "2rem",
                                                transition: "500ms ease-in-out",
                                            }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        aria-label="Favorite"
                                        onClick={() => {
                                            handleFavClicked()
                                        }}
                                    >
                                        <NotFavorite
                                            sx={{
                                                margin: "auto 5px",
                                                color: "#aeaeae",
                                                zIndex: 3,
                                                fontSize: "2rem",
                                                transition: "500ms ease-in-out",
                                            }}
                                        />
                                    </IconButton>
                                )}
                                <ResponsiveRow
                                    sx={{
                                        width: "auto",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Straighten sx={{margin: "auto 1px"}}/>{" "}
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: 700,
                                            margin: "auto 10px",
                                        }}
                                    >
                                        {props.listing.sqft}
                                    </Typography>
                                </ResponsiveRow>
                                <ResponsiveRow
                                    sx={{
                                        width: "auto",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Bed/>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: 700,
                                            margin: "auto 10px",
                                        }}
                                    >
                                        {props.listing.beds}
                                    </Typography>{" "}
                                </ResponsiveRow>
                                <ResponsiveRow
                                    sx={{
                                        width: "auto",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Bath sx={{margin: "auto 1px"}}/>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: 700,
                                            margin: "auto 10px",
                                        }}
                                    >
                                        {props.listing.baths}
                                    </Typography>
                                </ResponsiveRow>
                            </ResponsiveRow>
                            <ResponsiveRow sx={{width: "90%"}}>
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: 400,
                                    }}
                                >
                                    {props.listing.description}
                                </Typography>
                            </ResponsiveRow>
                            <ResponsiveRow sx={{justifyContent: "flex-end"}}>
                                <ModalAcceptButton
                                    onClick={handleApplyClicked}
                                    sx={{
                                        backgroundColor: "#009F93",
                                        width: {xs: "100%", lg: "200px"},
                                    }}
                                >
                                    Start Application
                                </ModalAcceptButton>
                                <ListingApplicationModal
                                    openModal={openApplicationModal}
                                    closeModal={handleApplicationClose}
                                    listing={props.listing}
                                />
                                {
                                    !isNullUndefined(user) && props.listing.addedBy !== user?.userId && !hasABooking &&
                                    <ModalAcceptButton
                                        onClick={handleBookTourClicked}
                                        sx={{width: {xs: "100%", lg: "200px"}}}
                                    >
                                        Book Tour
                                    </ModalAcceptButton>
                                }
                                {
                                    isNullUndefined(user) &&
                                    <ModalAcceptButton
                                        onClick={handleBookTourClicked}
                                        sx={{width: {xs: "100%", lg: "200px"}}}
                                    >
                                        Book Tour
                                    </ModalAcceptButton>
                                }
                                {
                                    openBookingModal &&
                                    <ListingBookingModal
                                        closeModal={handleBookingClose}
                                        listing={props.listing}
                                        action={action}
                                        refetchTourBooking={() => fetchOwnTourBookingForListing()}
                                    />
                                }
                            </ResponsiveRow>
                            { !isNullUndefined(user) && props.listing.addedBy !== user?.userId && hasABooking && myAppointment && (
                                <ResponsiveRow>
                                    <DetailsCardContainer sx={{width: "100%"}}>
                                        <Typography variant="h5">You booked a tour for this listing</Typography>
                                        <Box>Date of Tour: <Typography sx={{ fontWeight: 'bold' }}>{formatDateTime(new Date(myAppointment?.date))}</Typography></Box>
                                        <Box>Listing: <Typography variant="h6">{myAppointment?.facility}</Typography></Box>
                                        <Box>Booking made on: <Typography variant="h6">{formatDate(new Date(myAppointment?.created))}</Typography></Box>
                                        <ResponsiveRow
                                            sx={{
                                                width: "100%",
                                                justifyContent: {xs: "center", lg: "flex-end"},
                                                height: {xs: "100px", lg: "70px"}
                                            }}
                                        >
                                            <ModalAcceptButton
                                                onClick={() => editAppointmentClicked()}
                                                sx={{width: {xs: "100%", lg: "200px"}}}
                                            >
                                                Edit Booking
                                            </ModalAcceptButton>
                                            <ModalCancelButton
                                                onClick={() => cancelAppointmentClicked()}
                                                sx={{width: {xs: "100%", lg: "200px"}}}
                                            >
                                                Cancel Booking
                                            </ModalCancelButton>
                                        </ResponsiveRow>
                                    </DetailsCardContainer>

                                </ResponsiveRow>
                            )}
                            <ResponsiveRow
                                sx={{width: "100%", height: "auto", flexDirection: "column"}}
                            >
                                <Typography variant="h5">Properties near by:</Typography>
                                <SmartKodiMap
                                    view={""}
                                    containerStyle={{width: "100%", height: "35vh"}}
                                    center={center}
                                    children={markers}
                                />
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </ResponsiveRow>
                </>
            )}
            <ListingGalleryViewer
                open={galleryModal}
                closeModal={() => {
                    setGalleryModal(false)
                }}
                listingName={props.listing.propertyName}
                listingImages={props.listing.imageFiles}
                listingDescription={props.listing.description}
                listingPrice={props.listing.price}
            />
            {!isNullUndefined(user) && props.listing.addedBy === user?.userId &&
                <ResponsiveRow sx={{width: {xs: "100%", lg: "80%"}, margin: "auto"}}>
                    <Box sx={{width: "90vw", maxWidth:"1400px", margin: "auto"}}>
                        <Typography variant="h5" sx={{marginBlock: "20px"}}>Tour Bookings</Typography>
                        <StyledDataGrid
                            rows={appointments}
                            columns={columns}
                            disableSelectionOnClick
                            loading={loading}
                            autoHeight
                            pageSize={rowsPerPage}
                            rowCount={total}
                            rowsPerPageOptions={[rowsPerPage]}
                            pagination
                            paginationMode="server"
                            onSelectionModelChange={setSelectionModel}
                            selectionModel={selectionModel}
                        />

                    </Box>
                </ResponsiveRow>
            }
        </Grid>
    )
}
